<template>
    <Numerics class="deposit_withdraw" :resize="false">

        <div class="deposit_withdraw_buttons">
            <input type="button" value="DEPOSIT" :class="side == 'DEPOSIT' ? 'selected': ''" @click="side = 'DEPOSIT'">
            <div class="button_connector"></div>
            <input type="button" value="WITHDRAW" :class="side == 'WITHDRAW' ? 'selected': ''" @click="side = 'WITHDRAW'">
        </div>

        <div class="deposit_section" v-if="side == 'DEPOSIT'">
            <br/>

            <div class="info_span" v-if="depositData">
                <span class="name_span">Routing Number:</span>
                <span class="value_span">{{ depositData.routing_number }}</span>
            </div>
            <div class="info_span" v-if="depositData">
                <span class="name_span">Account Number:</span>
                <span class="value_span">{{ depositData.account_number }}</span>
            </div>
            <div class="info_span" v-if="depositData">
                <span class="name_span">Deposit Code:</span>
                <span class="value_span">{{ depositData._deposit_code }}</span>
            </div>
            <div class="info_span" v-if="depositData">
                <span class="name_span">Bank Code:</span>
                <span class="value_span">{{ depositData.bank_name }}</span>
            </div>
            
            <!-- <input class="execute" type="button" value="GENERATE DEPOSIT INFO" @click="displayDepositInfo"> -->
        </div>

        <div class="withdraw_section" v-if="side == 'WITHDRAW'">

            <div class="amount_section">
                <div class="fieldset amount">
                    <label class="label" for="amount">Amount</label>
                    <input class="input" type="number" name="amount" :placeholder="findAvailableAmount()" v-model="withdrawData.amount">
                </div>
                <div class="fieldset currency">
                    <label class="label" for="amount">Currency</label>
                    <select class="input" v-model="withdrawData.selectedAsset">
                        <option v-for="asset in fiatAssets" :key="asset.id" :value="asset">{{ asset.short_name }}</option>
                    </select>
                </div>
            </div>

            <div class="fieldset">
                <label class="label" for="amount">Memo ID (optional)</label>
                <input class="input" type="text" name="memo_id" v-model="withdrawData.memoID">
            </div>

            <br/>

            <input class="execute" type="button" value="REQUEST WITHDRAWAL" @click="openWithdrawPopup">
        </div>

        <!-- <Transition name="item_explorer">
            <div class="item_explorer" v-if="depositData">
                <div class="header">
                    <h3> Order </h3>
                </div>
                <div class="settings">
                    <div class="settings_content">
                        <div class="info_setting">
                            <span class="label">Routing Number:</span>
                            <span class="info">{{ depositData.routing_number }}</span>
                        </div>
                        <div class="info_setting">
                            <span class="label">Account Number:</span>
                            <span class="info">{{ depositData.account_number }}</span>
                        </div>
                        <div class="info_setting">
                            <span class="label">Deposit Code:</span>
                            <span class="info">{{ depositData._deposit_code }}</span>
                        </div>
                        <div class="info_setting">
                            <span class="label">Bank Name:</span>
                            <span class="info">{{ depositData.bank_name }}</span>
                        </div>
                    </div>
                </div>
                <div class="exit_options">
                    <input type="button" class="exit_option" value="Close" @click="closeExistingItemExplorer">
                </div>
            </div>
        </Transition> -->

        <ConfirmationPopup 
            v-if="confirmationPopup.active"
            :message="confirmationPopup.message"
            @onConfirm="confirmationPopup.onConfirm"
            @onCancel="confirmationPopup.active = false"
        />

    </Numerics>
</template>

<script>
    import Numerics from "@/components/slots/numerics-slot.vue";
    import ConfirmationPopup from "@/components/popups/confirmation-popup.vue";

    export default {
        props: {
            selectedAccount: {
                Type: Object
            },
            assets: {
                Type: Array
            },
            fiatAssets: {
                Type: Array
            },
            balances: {
                Type: Array
            }
        },

        data() {
            return {
                side: "DEPOSIT",

                depositData: null,

                withdrawData: {
                    amount: null,
                    memoID: "",
                    selectedAsset: null,
                    available: null
                },

                confirmationPopup: {
                    active: false,
                    message: "Are you sure?",
                    onConfirm: null
                }
            }
        },

        components: {
            Numerics,
            ConfirmationPopup
        },

        async mounted() {
            this.withdrawData.selectedAsset = this.assets[0];
            this.displayDepositInfo(this.selectedAccount);
        },

        watch: {
            async selectedAccount(newAccount) {
                this.displayDepositInfo(newAccount);
            }
        },
 
        methods: {
            async closeExistingItemExplorer() {
                this.depositData = null;
            },

            findAvailableAmount() {
                if (this.selectedAccount &&  this.balances.length > 0) {
                    let foundBalance = this.balances.find(
                        balance => {
                            return balance._asset_id == this.withdrawData.selectedAsset.id && balance._account_id == this.selectedAccount.id
                        }
                    );
                    return foundBalance._available;
                }
            },

            async allowOnlyDollar(assets) {
                assets = await assets.filter(
                    asset => {
                        if (asset.short_name == "USD") {
                            return asset;
                        }
                    }
                );
                return assets;
            },

            async displayDepositInfo(account) {
                try {
                    if (account) {
                        let response = await this.$store.dispatch("ordersFundingsDepositsDetialsFetch", account);

                        this.depositData = response.data[0];
                    }
                    else {
                        this.depositData = null;
                    }
                }
                catch (error) {
                    console.error(error);
                }
            },

            async openWithdrawPopup() {
                if (this.selectedAccount == null) {
                    await this.$store.commit("logsErrorAdd", "Please select an account.");
                    return;
                }

                if (!this.withdrawData.amount) {
                    await this.$store.commit("logsErrorAdd", "Please enter the withdraw amount.");
                    return;
                }

                let order = {
                    account_id: this.selectedAccount.id,
                    created_by: this.$store.state.user.user.id,
                    asset_id: this.withdrawData.selectedAsset.id,
                    base_amount: this.withdrawData.amount.toString(),
                    memo_id: this.withdrawData.memoID
                };

                this.confirmationPopup.active = true;
                this.confirmationPopup.message = "Withdraw " + this.withdrawData.amount + " " + this.withdrawData.selectedAsset.short_name + "?";
                this.confirmationPopup.onConfirm = async () => {
                    try {
                        this.$store.dispatch("ordersFundingsWithdrawCreate", order);
                        this.confirmationPopup.active = false;

                        this.withdrawData.amount = "";
                        this.withdrawData.memoID = "";

                        setTimeout(
                            async () => {
                                await this.$store.dispatch("accountsBalancesFetch");
                            },
                            3000
                        )
                    }
                    catch (error) {
                        console.error(error);
                    }
                };
            }
        }
    }
</script>


<style lang="scss" scoped>
    .deposit_withdraw {
        width: 350px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // max-height : 220px;

        .deposit_withdraw_buttons {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
            width: 100%;
            height : 30px;
            padding : 0px;

            input[type="button"] {
                border: 1px solid var(--selected);
                width: 48%;
                max-height : 30px;
                background: 0;
                padding : 0px;
                font-size : 10px;

                &.selected {
                    background-color: var(--selected);
                }

                &:hover {
                    background-color: var(--selected);
                }

                &:focus {
                    background-color: var(--selected);
                }
            }

            .button_connector {
                width: 4%;
                height: 2px;
                background-color: var(--selected);
            }
        }

        .deposit_section {
            .info_span {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin: 10px 0px;

                .name_span {
                    font-weight: bold;
                }

                .value_span {

                }
            }
        }

        .withdraw_section {

            .fieldset {

                label {
                    font-size: 12px;
                }

                input {
                    background-color: #333;
                }

                &.amount {
                    width: 68%;
                }

                &.currency {
                    width: 30%;
                }
            }

            .amount_section {
                display: flex;
                justify-content: space-between;
                margin-bottom: 4px;
                width: 100%;

            }
            
            .fieldset {

                label {
                    font-size: 12px;
                }

                input {
                    background-color: #333;
                    text-align: right;
                }

                &.amount {
                    width: 68%;
                }

                &.currency {
                    width: 30%;
                }
            }
        }

        .execute {
            border: 1px solid var(--positive);
            width: 100%;
            background: 0;
            height : 35px;
            padding : 0px;
            font-size : 10px;

            &:hover {
                background-color: var(--positive);
            }

            &:focus {
                background-color: var(--positive);
            }
        }
    }
</style>