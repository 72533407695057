import { createStore } from "vuex";
import auth from "./modules/auth";
import user from "./modules/user";
import market from "./modules/market";
import members from "./modules/members";
import customers from "./modules/customers";
import accounts from "./modules/accounts";
import fees from "./modules/fees";
import orders from "./modules/orders";
import executions from "./modules/executions";
// import notifications from "./modules/notifications";
import logs from "./controllers/logs";
import loadingOverlay from "./controllers/loading-overlay";
import bidAsk from "./controllers/bid-ask";

const store = createStore(
    {
        actions: {
            async fetchBasicData({ dispatch }) {
                await dispatch("authRefresh");
                await dispatch("userFetch");
                await dispatch("marketsFetch");
                await dispatch("customersFetch");
            }
        },

        modules: {
            auth: auth,
            user: user,
            market: market,
            members: members,
            customers: customers,
            accounts: accounts,
            fees: fees,
            orders: orders,
            executions: executions,
            // notifications: notifications,
            logs: logs,
            loadingOverlay: loadingOverlay,
            bidAsk: bidAsk
        }
    }
);

export default store;