import connector from "@/connectors/api";

export default {
    actions: {
        async feesPlatformFetch({ commit }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                let response = await connector.fee_config(params, payload, headers);
                return response.data[0].customer_fee;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to fetch platform fees.");
            }
        },

        async feesAccountFetch({ commit }, account) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                if (account) {
                    params = {
                        account_id: account.id
                    };
                }

                let response = await connector.fee_config(params, payload, headers);
                return response.data[0].account_fee;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to fetch account fees.");
            }
        },

        async feesPlatformSet({ commit }, fee) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                payload = {
                    fee: fee
                };

                let response = await connector.set_fee(params, payload, headers);
                await commit("logsSuccessAdd", "Changed platform fee.");
                return response.data;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to set fee.");
            }
        },

        async feesAccountSet({ commit }, { fee, account }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                params = {
                    account_id: account.id
                };
                payload = {
                    fee: fee
                };

                let response = await connector.set_fee(params, payload, headers);
                await commit("logsSuccessAdd", "Changed account fee.");
                return response.data;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to set fee.");
            }
        }
    }
}