<template>
    <div id="customers-subpage" class="subpage">

        <div class="subpage_header">
            <h2>Accounts</h2>
        </div>

        <div class="filter-row">
            <input class="item" type="button" value="Add New Account" @click="openAddAccountPopup">
            <input class="filter" type="search" placeholder="Search..." v-model="accountSearchPhrase" v-if="accounts.length > 0">
            <div class="filter dropdown-selector" v-if="accounts.length > 0">
                <div class="name-holder">
                    <span class="name">Sort by:</span>
                </div>
                <select class="select" v-model="accountsSortBy.column">
                    <option value="id">ID</option>
                    <option value="type">Type</option>
                    <option value="description">Description</option>
                    <option value="is_active">Status</option>
                    <option value="external_id">External ID</option>
                    <option value="created_at">Created At</option>
                </select>
                <select class="select" v-model="accountsSortBy.direction">
                    <option value="desc">DESC</option>
                    <option value="asc">ASC</option>
                </select>
            </div>
        </div>

        <div class="numerics_holder">
            
            <div class="custom_table_holder" v-if="accounts.length > 0">
                <table class="table" cellspacing="0">
                    <thead class="table_header">
                        <tr class="row">
                            <th class="person_th" @click="changeAccountsSortBy('id')">ID <span class="asc_desc_arrow" v-if="accountsSortBy.column == 'id'">{{ accountsSortBy.direction == 'asc' ? '&#8638;' : '&#8642;' }}</span></th>
                            <th class="person_th" @click="changeAccountsSortBy('type')">Type <span class="asc_desc_arrow" v-if="accountsSortBy.column == 'type'">{{ accountsSortBy.direction == 'asc' ? '&#8638;' : '&#8642;' }}</span></th>
                            <th class="person_th" @click="changeAccountsSortBy('description')">Description <span class="asc_desc_arrow" v-if="accountsSortBy.column == 'description'">{{ accountsSortBy.direction == 'asc' ? '&#8638;' : '&#8642;' }}</span></th>
                            <th class="person_th" @click="changeAccountsSortBy('is_active')">Status <span class="asc_desc_arrow" v-if="accountsSortBy.column == 'is_active'">{{ accountsSortBy.direction == 'asc' ? '&#8638;' : '&#8642;' }}</span></th>
                            <th class="person_th" @click="changeAccountsSortBy('external_id')">External Id <span class="asc_desc_arrow" v-if="accountsSortBy.column == 'external_id'">{{ accountsSortBy.direction == 'asc' ? '&#8638;' : '&#8642;' }}</span></th>
                            <th class="person_th" @click="changeAccountsSortBy('created_at')">Created At <span class="asc_desc_arrow" v-if="accountsSortBy.column == 'created_at'">{{ accountsSortBy.direction == 'asc' ? '&#8638;' : '&#8642;' }}</span></th>
                        </tr>
                    </thead>
                    <tbody class="table_body">
                        <tr class="row" :class="selectedAccount && (selectedAccount.id == account.id) ? 'selected' : ''" v-for="account in filterAccounts(accounts)" :key="account.id" @click="selectAccount(account)">
                            <td class="person_td">{{ account.id }}</td>
                            <td class="person_td">{{ account.account_type }}</td>
                            <td class="person_td">{{ account.description }}</td>
                            <td class="person_td">{{ account.is_active ? "active" : "not active" }}</td>
                            <td class="person_td">{{ account.external_id }}</td>
                            <td class="person_td">{{ account.created_at.slice(0,10) + " " + account.created_at.slice(11,19) }}</td>
                        </tr>
                    </tbody>
                </table>    
            </div>

            <Transition name="item_explorer">
                <div class="item_explorer" v-if="selectedAccount">
                    <div class="header">
                        <h3> {{ selectedAccount.id}} </h3>
                    </div>
                    <!-- <div class="tabs">
                        <input type="button" class="tab" value="Details" :class="tableControls.selectedTab == 'Details' ? 'selected' : ''" @click="tableControls.selectedTab = 'Details'">
                    </div> -->
                    <div class="settings">
                        <div class="settings_content" v-if="tableControls.selectedTab == 'Details'">
                            <div class="info_setting">
                                <span class="label">ID:</span>
                                <span class="info">{{ selectedAccount.id }}</span>
                            </div>
                            <div class="info_setting">
                                <span class="label">Status:</span>
                                <span class="info">{{ selectedAccount.is_active }}</span>
                            </div>
                            <div class="info_setting">
                                <span class="label">Created by:</span>
                                <span class="info">{{ selectedAccount.created_by }}</span>
                            </div>
                            <div class="info_setting">
                                <span class="label">Created at:</span>
                                <span class="info">{{ selectedAccount.created_at }}</span>
                            </div>
                            <div class="text_setting">
                                <span class="label">Description:</span>
                                <input class="input" type="text" v-model="selectedAccount.description">
                            </div>
                            <div class="text_setting">
                                <span class="label">External ID:</span>
                                <input class="input" type="text" v-model="selectedAccount.external_id">
                            </div>
                        </div>
                    </div>
                    <div class="exit_options">
                        <input type="button" class="exit_option" value="Close" @click="closeExistingItemExplorer">
                        <input type="button" class="exit_option" v-bind:value="selectedAccount.is_active ? 'Deactivate' : 'Activate'" @click="openDeleteAccountConfirmationPopup">
                        <input type="button" class="exit_option" value="Save" @click="saveAccount()">
                    </div>
                </div>
            </Transition>
        </div>
                
        <AddAccountPopup 
            v-if="addAccountPopup.active"
            :message="addAccountPopup.message"
            @onConfirm="addAccountPopup.onConfirm"
            @onCancel="addAccountPopup.active = false"
        />

        <DeleteAccountConfirmationPopup 
            v-if="deleteAccountConfirmationPopup.active"
            :message="deleteAccountConfirmationPopup.message"
            @onConfirm="deleteAccountConfirmationPopup.onConfirm"
            @onCancel="deleteAccountConfirmationPopup.active = false"
        />

    </div>
</template>

<script>
    import AddAccountPopup from "@/components/popups/confirmation-popup.vue";
    import DeleteAccountConfirmationPopup from "@/components/popups/confirmation-popup.vue";

    export default {
        components: {
            AddAccountPopup,
            DeleteAccountConfirmationPopup
        },

        data() {
            return {
                // selectedCustomer: null,
                accountSearchPhrase: "",
                accountsSortBy: {
                    column: "created_at",
                    direction: "desc"
                },
                addAccountPopup: {
                    active: false,
                    message: "Are you sure?",
                    onConfirm: null
                },
                tableControls: {
                    selectedTab: "Details"
                },
                selectedAccount: null,
                deleteAccountConfirmationPopup: {
                    active: false,
                    message: "",
                    onConfirm: null
                }
            }
        },

        async beforeMount() {
            try {
                await this.$store.dispatch("customersFetch");
                // this.selectedCustomer = this.customer;
                await this.$store.dispatch("accountsFetch");
            }
            catch (error) {
                console.error(error);
            }
        },

        computed: {
            customer() {
                return this.$store.state.customers.customer;
            },
            accounts() {
                return this.$store.state.accounts.accounts;
            }
        },

        methods: {
            closeExistingItemExplorer() {
                this.selectedAccount = null;
            },

            selectAccount(account) {
                this.closeExistingItemExplorer();
                this.selectedAccount = {
                    id: account.id,
                    created_at: account.created_at,
                    created_by: account.created_by,
                    description: account.description,
                    external_id: account.external_id,
                    account_type: account.account_type,
                    is_active: account.is_active
                };
            },

            openAddAccountPopup() {
                this.addAccountPopup.active = true;
                this.addAccountPopup.message = "Are you sure you want to create a new account?";
                this.addAccountPopup.onConfirm = async () => {
                    try {
                        await this.$store.dispatch(
                            "accountsCreate",
                            // this.selectedCustomer
                            this.customer
                        );
                        this.addAccountPopup.active = false;
                    }
                    catch (error) {
                        console.error(error);
                    }
                }
            },

             async saveAccount() {
                try {
                    await this.$store.dispatch(
                        "accountsEdit",
                        {
                            id: this.selectedAccount.id,
                            external_id: this.selectedAccount.external_id,
                            description : this.selectedAccount.description
                        }
                    );
                    this.selectedAccount = null;
                }
                catch (error) {
                    console.error(error);
                }
            },

            openDeleteAccountConfirmationPopup() {
                
                if (this.selectedAccount.account_type == "default" || this.selectedAccount.account_type == "fee" ) {
                    alert ("Cannot delete default or fee account");
                    return;
                }
                
                const account = {
                    id: this.selectedAccount.id
                }

                this.deleteAccountConfirmationPopup.active = true;
                this.deleteAccountConfirmationPopup.message = "Are you sure you want to toggle activity of " + this.selectedAccount.id + "?";
                this.deleteAccountConfirmationPopup.onConfirm = async () => {
                    try {
                        await this.$store.dispatch(
                            "accountsDelete",
                            account
                        );
                        
                        this.selectedAccount.is_active = !this.selectedAccount.is_active;
                        this.deleteAccountConfirmationPopup.active = false;

                    }
                    catch (error) {
                        console.error(error);
                    }
                }
            },

            changeAccountsSortBy(column) {
                if (this.accountsSortBy.column != column) this.accountsSortBy.column = column;
                else if (this.accountsSortBy.direction == "asc") this.accountsSortBy.direction = "desc";
                else if (this.accountsSortBy.direction == "desc") this.accountsSortBy.direction = "asc";
            },

            filterAccounts(accounts) {
                accounts = accounts.filter(
                    account => {
                        if (account.deleted == true) {
                            return false;
                        }
                        if (!this.accountSearchPhrase) {
                            return true;
                        }
                        // if (account.account_type == "default") {
                        //     return false;
                        // }
                        if (account.id && account.id.toLowerCase().includes(this.accountSearchPhrase)) {
                            return account;
                        }
                        else if (account.account_type && account.account_type.toLowerCase().includes(this.accountSearchPhrase)) {
                            return account;
                        }
                        else if (account.external_id && account.external_id.toLowerCase().includes(this.accountSearchPhrase)) {
                            return account;
                        }
                        else if (account.created_at && account.created_at.toLowerCase().includes(this.accountSearchPhrase)) {
                            return account;
                        }
                    }
                );

                if (this.accountsSortBy.column == "type") {
                    accounts = accounts.sort(
                        (a, b) => {
                            a = (a.account_type || "").toUpperCase();
                            b = (b.account_type || "").toUpperCase();
                            if (a < b) return -1;
                            if (a > b) return 1;
                            return 0;
                        }
                    );
                }

                if (this.accountsSortBy.column == "is_active") {
                    accounts = accounts.sort(
                        (a, b) => {
                            a = (a.account_type || "").toString().toUpperCase();
                            b = (b.account_type || "").toString().toUpperCase();
                            if (a < b) return -1;
                            if (a > b) return 1;
                            return 0;
                        }
                    );
                }
                else if (this.accountsSortBy.column == "created_at") {
                    accounts = accounts.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
                }

                if (this.accountsSortBy.direction == "desc") return accounts;
                if (this.accountsSortBy.direction == "asc") return accounts.reverse();
            }
        }
    }
</script>