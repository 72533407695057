<template>
    <chartSlot class="market_chart" id="chartContainer">
        <!-- <div class="lw-chart" id="chartContainer"></div> -->
    </chartSlot>
</template>

<script>
    import chartSlot from "@/components/slots/chart-slot.vue";
    import { ref } from 'vue';
    import { createChart } from 'lightweight-charts';

    export default {
        props: {
            selectedMarket: {
                Type: Object
            }
        },

        components: {
            chartSlot
        },

        data() {
            return {
                chart: null,
                interval: null
            }
        },

        async mounted() {
            this.createNewChart(this.selectedMarket);
        },

        async unmounted() {
            await this.deleteChart();
        },

        computed: {
            candles() {
                return this.$store.state.market.candles;
            }
        },

        watch: {
            async selectedMarket(market) {
                await this.deleteChart();
                setTimeout(
                    async () => {
                        await this.createNewChart(market);
                    },
                    500
                );
            }
        },

        methods: {
            async deleteChart() {
                if (this.chart) {
                    clearInterval(this.interval);
                    await this.chart.remove();
                    this.chart = null;
                }
            },

            async createNewChart(market) {
                try {
                    if (market) {
                        this.$store.commit("openLoadingOverlay");
                        const chartContainer = ref("chartContainer");
        
                        const chartOptions = { 
                            layout: { 
                                textColor: 'white', 
                                background: { 
                                    type: 'solid', 
                                    color: 'black' 
                                },
                                fontFamily : "monospace"
                            },
                            grid: {
                                vertLines: {
                                    color: 'rgba(105,105,105, 0.1)' // Color of vertical grid lines
                                },
                                horzLines: {
                                    color: 'rgba(105,105,105, 0.1)' // Color of horizontal grid lines
                                }
                            },
                            title: 'My Awesome Chart', // Title of the chart
                            timeScale: {
                                rightOffset: 12,
                                timeVisible: true,
                                secondsVisible: false,
                                
                            },
                            watermark: {
                                color: 'rgba(106,74,236,0.1)', // Color of the watermark (set to transparent)
                                text: 'Adria Digital Exchange',
                                visible : "true",
                                fontFamily :"monospace"
                            },
                            crosshair: {
                                horzLine: {
                                    visible: false // Disable horizontal crosshair line
                                }
                            }
                        };
        
                        this.chart = createChart(chartContainer.value, chartOptions);
        
                        const candlestickSeries = this.chart.addCandlestickSeries(
                            { 
                                upColor: '#26a69a', 
                                downColor: '#ef5350', 
                                borderVisible: false, 
                                wickUpColor: '#26a69a', 
                                wickDownColor: '#ef5350' 
                            }
                        );
        
                        // var t = async () => {
                            var candelsRes = await this.$store.dispatch("marketsCandlesFetch", market);
                            candlestickSeries.setData(candelsRes.data);
                        // }
                        
                        // t();
                        // this.interval = setInterval(t, 900);
    
                        this.interval = setInterval(
                            () => {
                                candlestickSeries.setData(this.candles);
    
                                candlestickSeries.applyOptions(
                                    {
                                        title: this.candles[this.candles.length - 1].close.toFixed(2) + ' ' + this.selectedMarket.fiat_name
                                    }
                                );
                            }, 
                            1000
                        );
    
                        this.chart.timeScale().fitContent();
                        this.$store.commit("closeLoadingOverlay");

                    }
                }
                catch (error) {
                    this.$store.commit("closeLoadingOverlay");
                    console.error(error);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .market_chart {
        position: relative;
        right: 0px;
        width: calc(100% - 10px);
        height: 340px;
        // flex-grow: 1;
    }
</style>
