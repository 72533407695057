<template>
    <Numerics :header="'Reallocate'" class="reallocator" :resize="false">
        <!-- <div class="accounts_section"> -->
            <!-- <div class="fieldset">
                <label class="label" for="amount">To account</label>
                <select class="input" v-model="toAccount">
                    <option v-for="account in availableAccounts" :key="account.id" :value="account">{{ account.id }}</option>
                </select>
            </div> -->
            <div class="dropdown-selector">
                <div class="name-holder">
                    <span class="name">Receiver Account:</span>
                </div>
                <select class="select" v-model="toAccount">
                    <option v-for="account in availableAccounts" :key="account.id" :value="account">{{ account.description ? account.description + " (" + account.id + ")" : account.id }}</option>
                </select>
            </div>
        <!-- </div> -->
        <div class="amount_section">
            <div class="fieldset amount">
                <label class="label" for="amount">Amount</label>
                <input class="input" type="number" name="amount" v-model="amount" :placeholder="findAvailableAmount()">
            </div>
            <div class="fieldset currency">
                <label class="label" for="amount">Currency</label>
                <select class="input" v-model="selectedAsset">
                    <option v-for="asset in assets" :key="asset.id" :value="asset">{{ asset.short_name }}</option>
                </select>
            </div>
        </div>
        <br/>
        <input class="execute" type="button" value="REALLOCATE" @click="openConfirmationPopup">

        <ConfirmationPopup 
            v-if="confirmationPopup.active"
            :message="confirmationPopup.message"
            @onConfirm="confirmationPopup.onConfirm"
            @onCancel="confirmationPopup.active = false"
        />
    </Numerics>
</template>

<script>
    import Numerics from "@/components/slots/numerics-slot.vue";
    import ConfirmationPopup from "@/components/popups/confirmation-popup.vue";

    export default {
        props: {
            fromAccount: {
                Type: Object
            },
            assets: {
                Type: Object
            },
            accounts: {
                Type: Array
            },
            balances: {
                Type: Array
            }
        },

        components: {
            Numerics,
            ConfirmationPopup
        },

        data() {
            return {
                confirmationPopup: {
                    active: false,
                    message: "Are you sure?",
                    onConfirm: null
                },
                amount: null,
                selectedAsset: null,
                toAccount: null,
                availableAccounts: []
            }
        },

        watch: {
            fromAccount(newAccount) {
                this.availableAccounts = this.accounts.filter(
                    account => {
                        if (newAccount.id != account.id) {
                            return true;
                        }
                        else {
                            this.toAccount = null;
                            return false;
                        }
                    }
                );
                if (this.toAccount == null && this.availableAccounts.length > 0) {
                    this.toAccount = this.availableAccounts[0];
                }
            }
        },

        mounted() {
            try {
                this.selectedAsset = this.assets[0];
                this.availableAccounts = this.accounts.filter(
                    account => {
                        if (this.fromAccount.id != account.id) {
                            return true;
                        }
                        else {
                            this.toAccount = null;
                            return false;
                        }
                    }
                );
                if (this.availableAccounts.length > 0) {
                    this.toAccount = this.availableAccounts[0];
                }
            }
            catch (error) {
                console.error(error);
            }
        },

        methods: {
            findAvailableAmount() {
                if (this.fromAccount && this.balances && this.selectedAsset) {
                    let foundBalance = this.balances.find(
                        balance => {
                            return balance._asset_id == this.selectedAsset.id && balance._account_id == this.fromAccount.id
                        }
                    );
                    return foundBalance._available;
                }
            },

            openConfirmationPopup() {
                const order = {
                    account_id_from: this.fromAccount.id,
                    account_id_to: this.toAccount.id,
                    asset_id: this.selectedAsset.id,
                    base_amount: this.amount.toString()
                }

                this.confirmationPopup.active = true;
                this.confirmationPopup.message = "Are you sure you want to reallocate " + this.amount + " " + this.selectedAsset.short_name + "?";
                this.confirmationPopup.onConfirm = async () => {
                    try {
                        this.$store.dispatch("ordersReallocationsCreate", order);
                        this.confirmationPopup.active = false;
                        this.amount = null;
                    }
                    catch (error) {
                        console.error(error);
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .reallocator {
        width: 350px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // max-height : 220px;

        .accounts_section {
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;
            width: 100%;

            .fieldset {
                width: 48%;

                label {
                    font-size: 10px;
                }

                input {
                    background-color: #333;
                }
            }
        }

        .dropdown-selector {
            width: 100%;

            select {
                width: 100%;
            }
        }

        .amount_section {
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;
            width: 100%;

            .fieldset {

                label {
                    font-size: 10px;
                }

                input {
                    background-color: #333;
                    text-align: right;
                }

                &.amount {
                    width: 68%;
                }

                &.currency {
                    width: 30%;
                }
            }
        }

        .execute {
            border: 1px solid var(--positive);
            width: 100%;
            height : 35px;
            background: 0;
            padding : 0px;
            font-size : 10px;

            &:hover {
                background-color: var(--positive);
            }

            &:focus {
                background-color: var(--positive);
            }
        }
    }
</style>