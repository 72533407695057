import connector from "@/connectors/api";

export default {
    state: () => (
        {
            markets: [],
            assets: [],
            fiatAssets: [],
            candles: [],

            trades: [],
            orders: {
                bids: [],
                asks: []
            },
            tickers: [],
            
            wsConnections: {
                trades: null,
                orders: null,
                tickers: null,
                candles: null
            },

            assetsPreSetOrder: [
                "BTC", 
                "ETH", 
                "LTC", 
                "BCH", 
                "PAXG"
            ],

            marketsPreSetOrder: [
                "BTCUSD", 
                "ETHUSD", 
                "LTCUSD", 
                "BCHUSD", 
                "PAXGUSD"
            ]
        }
    ),

    actions: {
        async marketsFetch({ dispatch }) {
            await dispatch("marketsMarketsFetch");
            await dispatch("marketsAssetsFetch");
            await dispatch("marketsTickersConnect");
        },

        async marketsMarketsFetch({ commit }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                let response = await connector.market_data_market(params, payload, headers);
                commit("marketsMarketsSet", response.data);
                return response;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to fetch markets.");
            }
        },

        async marketsAssetsFetch({ commit }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                let response = await connector.market_data_asset(params, payload, headers);
                commit("marketsAssetsSet", response.data);
                return response;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to fetch market assets.");
            }
        },

        async marketsCandlesFetch({ commit, dispatch }, market) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                params = {
                    market : market.short_name, 
                    time_frame : "minute"
                };

                let response = await connector.market_data_candle(params, payload, headers);
                await commit("marketsCandlesSet", response.data);
                await dispatch("marketsCandlesConnect", market);
                return response;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to fetch chart data.");
            }
        },
        
        async marketsTickersConnect({ commit }) {
            if (this.state.market.wsConnections.tickers) {
                this.state.market.wsConnections.tickers.close();
            }

            this.state.market.wsConnections.tickers = new WebSocket(process.env.VUE_APP_PUBLIC_DATA_WS + "ticker");
            
            this.state.market.wsConnections.tickers.addEventListener(
                "open", 
                (event) => {
                    if (process.env.NODE_ENV == "development") console.log("WebSocket connection opened:", event.currentTarget.url);
                }
            );

            this.state.market.wsConnections.tickers.addEventListener(
                "message", 
                (event) => {
                    commit("marketsTickersSet", JSON.parse(event.data));
                }
            );

            this.state.market.wsConnections.tickers.addEventListener(
                "close", 
                (event) => {
                    if (process.env.NODE_ENV == "development") console.log("WebSocket connection closed:", event);
                }
            );

            this.state.market.wsConnections.tickers.addEventListener(
                "error", 
                (error) => {
                    if (process.env.NODE_ENV == "development") console.error("Message from server ", error);
                }
            );
        },

        async marketsTradesConnect({ commit }, market) {
            if (this.state.market.wsConnections.trades) {
                this.state.market.wsConnections.trades.close();
            }

            await commit("marketsTradesClear");

            this.state.market.wsConnections.trades = new WebSocket(process.env.VUE_APP_PUBLIC_DATA_WS + "executiondata/" + market.short_name);

            this.state.market.wsConnections.trades.addEventListener(
                "open", 
                (event) => {
                    if (process.env.NODE_ENV == "development") console.log("WebSocket connection opened:", event.currentTarget.url);
                }
            );

            this.state.market.wsConnections.trades.addEventListener(
                "message", 
                (event) => {
                    commit("marketsTradesSet", JSON.parse(event.data));
                }
            );

            this.state.market.wsConnections.trades.addEventListener(
                "close", 
                (event) => {
                    if (process.env.NODE_ENV == "development") console.log("WebSocket connection closed:", event);
                    // if (event.code != 1000) {
                    //     dispatch("marketsTradesConnect");
                    // }
                }
            );

            this.state.market.wsConnections.trades.addEventListener(
                "error", 
                (error) => {
                    if (process.env.NODE_ENV == "development") console.error("Message from server ", error);
                }
            );
        },

        async marketsOrdersConnect({ commit }, market) {
            if (this.state.market.wsConnections.orders) {
                this.state.market.wsConnections.orders.close();
            }

            await commit("marketOrdersClear");

            this.state.market.wsConnections.orders = new WebSocket(process.env.VUE_APP_PUBLIC_DATA_WS + "marketdata/" + market.short_name);
            
            this.state.market.wsConnections.orders.addEventListener(
                "open", 
                (event) => {
                    if (process.env.NODE_ENV == "development") console.log("WebSocket connection opened:", event.currentTarget.url);
                }
            );

            this.state.market.wsConnections.orders.addEventListener(
                "message", 
                (event) => {
                    let order = JSON.parse(event.data);
                    if (order.type == "SNAPSHOT") {
                        commit("marketsOrdersSet", order);
                        // commit("findHighestBid");
                        // commit("findLowestAsk");
                    }
                    else {
                        if (order.side == "BUY") {
                            commit("marketsBidsUpdate", order);
                            // commit("findHighestBid");
                        }
                        else if (order.side == "SELL") {
                            commit("marketsAsksUpdate", order);
                            // commit("findLowestAsk");
                        }
                    }
                }
            );

            this.state.market.wsConnections.orders.addEventListener(
                "close", 
                (event) => {
                    if (process.env.NODE_ENV == "development") console.log("WebSocket connection closed:", event);
                    // if (event.code != 1000) {
                    //     dispatch("marketsOrdersConnect");
                    // }
                }
            );

            this.state.market.wsConnections.orders.addEventListener(
                "error", 
                (error) => {
                    if (process.env.NODE_ENV == "development") console.error("Message from server ", error);
                }
            );
        },

        async marketsCandlesConnect({ commit }, market) {
            if (this.state.market.wsConnections.candles) {
                this.state.market.wsConnections.candles.close();
            }

            this.state.market.wsConnections.candles = new WebSocket(process.env.VUE_APP_PUBLIC_DATA_WS + "candles/minute/" + market.short_name);

            this.state.market.wsConnections.candles.addEventListener(
                "open", 
                (event) => {
                    if (process.env.NODE_ENV == "development") console.log("WebSocket connection opened:", event.currentTarget.url);
                }
            );

            this.state.market.wsConnections.candles.addEventListener(
                "message", 
                (event) => {
                    commit("marketsCandlesStreamSet", JSON.parse(event.data));
                }
            );

            this.state.market.wsConnections.candles.addEventListener(
                "close", 
                (event) => {
                    if (process.env.NODE_ENV == "development") console.log("WebSocket connection closed:", event);
                    // if (event.code != 1000) {
                    //     dispatch("marketsOrdersConnect");
                    // }
                }
            );

            this.state.market.wsConnections.candles.addEventListener(
                "error", 
                (error) => {
                    if (process.env.NODE_ENV == "development") console.error("Message from server ", error);
                }
            );
        }
    },

    mutations: {
        marketsCandlesSet(state, candles) {
            state.candles = candles;
        },

        marketsCandlesStreamSet(state, candle) {
            candle.time = candle.timestamp;
            candle.volume = candle.amount;
            candle.low = parseFloat(candle.low);
            candle.high = parseFloat(candle.high);
            candle.open = parseFloat(candle.open);
            candle.close = parseFloat(candle.close);

            if (state.candles[state.candles.length - 1].time == candle.time) {
                state.candles[state.candles.length - 1] = candle;
            }
            else {
                state.candles.push(candle)
            }

        },

        marketsMarketsSet(state, markets) {
            let orderedMakrets = [];
            state.marketsPreSetOrder.forEach(
                preMarketName => {
                    markets.forEach(
                        market => {
                            if (market.short_name == preMarketName) {
                                orderedMakrets.push(market);
                            }
                        }
                    );
                }
            );

            orderedMakrets.map(
                market => {
                    if (market.short_name.length == 6) {
                        market.crypto_name = market.short_name.slice(0,3);
                        market.fiat_name = market.short_name.slice(3,6);
                    }
                    else if (market.short_name.length == 7) {
                        market.crypto_name = market.short_name.slice(0,4);
                        market.fiat_name = market.short_name.slice(4,7);
                    }
                }
            );

            state.markets = orderedMakrets;
        },

        marketsAssetsSet(state, assets) {
            let fiatAssets = [];

            assets.forEach(
                asset => {
                    if (asset.short_name == "USD") {
                        fiatAssets.push(asset);
                    }
                }
            )

            state.assets = assets;
            state.fiatAssets = fiatAssets;
        },

        marketsTickersSet(state, ticker) {
            let alreadyExists = false;
            state.tickers.map( // if the ticker is already exists then update it
                entry => {
                    if (entry.market == ticker.market) {
                        alreadyExists = true;
                        entry.price = ticker.price;
                        entry.delta = ticker.delta;
                        entry.delta_decimal = ticker.delta_decimal;
                    }
                }
            );

            state.marketsPreSetOrder.forEach(
                market => {
                    if (alreadyExists == false && market == ticker.market) {
                        state.tickers.push(ticker);
                    }
                }
            );

            let orderedTickers = [];
            state.marketsPreSetOrder.forEach(
                market => {
                    state.tickers.forEach(
                        ticker => {
                            if (ticker.market == market) {
                                orderedTickers.push(ticker);
                            }
                        }
                    )
                }
            );

            orderedTickers.map(
                ticker => {
                    if (ticker.market.length == 6) {
                        ticker.crypto_name = ticker.market.slice(0,3);
                        ticker.fiat_name = ticker.market.slice(3,6);
                    }
                    else if (ticker.market.length == 7) {
                        ticker.crypto_name = ticker.market.slice(0,4);
                        ticker.fiat_name = ticker.market.slice(4,7);
                    }
                }
            );

            state.tickers = orderedTickers;
        },

        marketsTradesClear(state) {
            state.trades = [];
        },

        marketsTradesSet(state, trade) {
            if (state.trades.length == 0) {
                trade.growth = "positive";
            }
            else {
                let lastTrade = state.trades[state.trades.length - 1];

                if (trade.price > lastTrade.price) {
                    trade.growth = "positive";
                }
                if (trade.price == lastTrade.price) {
                    trade.growth = lastTrade.growth;
                }
                if (trade.price < lastTrade.price) {
                    trade.growth = "negative";
                }
            }

            state.trades.push(trade);
            if (state.trades.length > 40) {
                state.trades.shift();
            }
        },

        marketOrdersClear(state) {
            state.orders.bids = [];
            state.orders.asks = [];
        },

        marketsOrdersSet(state, orders) {
            let sortedBids = {};
            orders.bids.forEach(
                item => {
                    sortedBids[item.price] = item;
                }
            );
            state.orders.bids = sortedBids;

            let sortedAsks = {};
            orders.asks.forEach(
                item => {
                    sortedAsks[item.price] = item;
                }
            );
            state.orders.asks = sortedAsks;
        },

        marketsBidsUpdate(state, bid) {
            if (bid.amount > 0) {
                state.orders.bids[bid.price] = {
                    price: bid.price,
                    amount: bid.amount
                }
            } 
            else {
                delete state.orders.bids[bid.price];
            }
        },

        marketsAsksUpdate(state, ask) {
            if (ask.amount > 0) {
                state.orders.asks[ask.price] = {
                    price: ask.price,
                    amount: ask.amount
                }
            } 
            else {
                delete state.orders.asks[ask.price];
            }
        }
    }
}