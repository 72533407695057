<template>
    <div id="loading-overlay"></div>
</template>

<script>
import nprogress from 'nprogress';

nprogress.configure(
    {
        showSpinner: false,
        minimum: 0.2,
        trickleSpeed: 100
    }
);

export default {

    // render: () => null,

    mounted() {
        nprogress.start();
        // document.body.appendChild(this.$el);
    },

    beforeUnmount() {
        nprogress.done();
    }
};
</script>

<style lang="scss" scoped>
    @import url('../../../node_modules/nprogress/nprogress.css');

    #loading-overlay {
        z-index: 1000000;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        cursor: progress;
        // background-color: rgba(0,0,0,0.4);
    }
</style>