<template>
    <div id="trading-station-subpage" class="subpage">

        <div class="subpage_header">
            <h2>Trading Station</h2>
        </div>

        <div class="filter-row">

            <div class="filter dropdown-selector">
                <div class="name-holder">
                    <span class="name">Market:</span>
                </div>
                <select class="select" v-model="selectedMarket">
                    <option v-for="market in market.markets" :key="market.id" :value="market">{{ market.short_name }}</option>
                </select>
            </div>

            <div class="item balance-strip" v-if="cryptoBalance">
                <div class="name-holder">
                    <span class="name">{{ cryptoBalance.short_name }}</span>
                </div>
                <div class="available-reserved">
                    <span class="type">Available</span>
                    <span class="amount">{{ parseFloat(cryptoBalance._available) }}</span>
                </div>
                <div class="available-reserved">
                    <span class="type" :class="cryptoBalance._reserved > 0 ? 'pending' : ''">Reserved</span>
                    <span class="amount" :class="cryptoBalance._reserved > 0 ? 'pending' : ''">{{ parseFloat(cryptoBalance._reserved) }}</span>
                </div>
            </div>

            <div class="item balance-strip" v-if="fiatBalance">
                <div class="name-holder">
                    <span class="name">{{ fiatBalance.short_name }}</span>
                </div>
                <div class="available-reserved">
                    <span class="type">Available</span>
                    <span class="amount">{{ parseFloat(fiatBalance._available).toFixed(2) }}</span>
                </div>
                <div class="available-reserved">
                    <span class="type" :class="fiatBalance._reserved > 0 ? 'pending' : ''">Reserved</span>
                    <span class="amount" :class="fiatBalance._reserved > 0 ? 'pending' : ''">{{ parseFloat(fiatBalance._reserved).toFixed(2) }}</span>
                </div>
            </div>

        </div>
        
        <div class="numerics_holder">

            <div class="numerics_row">
                <div class="numerics_stack chart-stack">
                    <MarketChart 
                        v-if="selectedMarket != {}"
                        :selectedMarket="selectedMarket" 
                    />
                    <OpenOrders 
                        v-if="selectedAccount && selectedMarket"
                        :selectedAccount="selectedAccount"
                        :orders="trades"
                        :market="selectedMarket"
                        :resize="false" 
                        :HPadding="false"
                    />
                    <div class="numerics_row">
                        <MarketBids 
                            :resize="false" 
                            :HPadding="false"
                        />
                        <MarketAsks 
                            :resize="false" 
                            :HPadding="false"
                        />
                        <MarketTrades 
                            :resize="false" 
                            :HPadding="false"
                        />
                    </div>
                </div>
                <div class="numerics_stack controls-stack">
                     <div class="dropdown-selector SPEC_account_selector" v-if="accounts.length > 0">
                        <div class="name-holder">
                            <span class="name">Account:</span>
                        </div>
                        <select class="select" v-model="selectedAccount">
                            <option v-for="account in accounts" :key="account.id" :value="account">{{ account.description ? account.description + " (" + account.id + ")" : account.id }}</option>
                        </select>
                    </div>
                    <Trader 
                        v-if="$store.getters.userRolesGet('orders') && selectedMarket != {} && selectedAccount && balances.length > 0" 
                        :bids="bids"
                        :asks="asks"
                        :market="selectedMarket"
                        :account="selectedAccount"
                        :balances="balances"
                        :header="'Trade'"
                    />
                    <Reallocator 
                        v-if="accounts.length > 1 && $store.getters.userRolesGet('reallocations') && selectedMarket != {} && selectedAccount" 
                        :fromAccount="selectedAccount" 
                        :accounts="accounts"
                        :balances="balances"
                        :assets="market.assets"
                    />
                    <DepositWithdraw 
                        v-if="$store.getters.userRolesGet('fundings') && selectedAccount"
                        :selectedAccount="selectedAccount"
                        :assets="market.assets"
                        :fiatAssets="market.fiatAssets"
                        :balances="balances"
                        :header="'Deposit and Withdraw'"
                    />
                </div>
            </div>
            <!-- <div class="numerics_row">
                <OpenOrders 
                    v-if="selectedAccount && selectedMarket"
                    :orders="selectedAccount && selectedMarket ? selectedAccount.orders : orders.trades"
                    :market="selectedMarket"
                    :resize="false" 
                    :HPadding="false"
                />
            </div> -->
            <!-- <div class="numerics_row">
                <OrderBookBids 
                    :resize="false" 
                    :HPadding="false"
                />
                <OrderBookAsks 
                    :resize="false" 
                    :HPadding="false"
                />
                <LastTrades 
                    :resize="false" 
                    :HPadding="false"
                />
            </div> -->
        </div>

    </div>
</template>

<script>

    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }

    import MarketChart from "@/components/blocks/market-chart-block.vue"; 
    import Trader from "@/components/blocks/trader-block.vue";
    import Reallocator from "@/components/blocks/reallocator-block.vue";
    import MarketBids from "@/components/blocks/market-bids-block.vue";
    import MarketAsks from "@/components/blocks/market-asks-block.vue";
    import MarketTrades from "@/components/blocks/market-trades-block.vue";
    import OpenOrders from "@/components/blocks/open-orders-block.vue";
    import DepositWithdraw from "@/components/blocks/deposit-withdraw-block.vue";
    
    export default {
        props: {
            preSelectedMarket: {
                Type: String
            }
        },

        components: {
            MarketChart,
            Trader,
            Reallocator,
            MarketBids,
            MarketAsks,
            MarketTrades,
            OpenOrders,
            DepositWithdraw
        },

        data() {
            return {
                selectedMarket: null,
                selectedAccount: null,
                cryptoBalance: null,
                fiatBalance: null,
                pageNumber: 1,
                pageLimit: 1000
            }
        },

        async beforeMount() {
            await this.$store.dispatch("marketsFetch");
            await this.$store.dispatch("accountsFetch");
            await this.$store.dispatch("accountsBalancesFetch");
            
            if (this.preSelectedMarket) {
                this.selectedMarket = this.market.markets.find(x => this.preSelectedMarket == x.short_name);
            }
            else {
                this.selectedMarket = this.market.markets[0];
            }
            if (this.accounts.length > 0) {
                this.selectedAccount = this.accounts[0];
            }
            await this.refreshDisplayedBalance();
        },

        async unmounted() {
            await this.$store.commit("ordersTradesWSClose");
            await this.$store.commit("ordersFundingsWSClose");
            await this.$store.commit("accountsBalancesWSClose");
        },

        computed: {
            market() {
                return this.$store.state.market;
            },
            bids() {
                return this.$store.state.market.orders.bids;
            },
            asks() {
                return this.$store.state.market.orders.asks;
            },
            accounts() {
                return this.$store.state.accounts.availableAccounts;
            },
            balances() {
                return this.$store.state.accounts.balances;
            },
            trades() {
                return this.$store.state.orders.trades;
            },
            tradesPageCount() {
                return this.$store.state.orders.tradesPageCount;
            }
        },

        watch: {
            async preSelectedMarket() {
                this.selectedMarket = this.market.markets.find(x => this.preSelectedMarket == x.short_name);
            },
            async selectedMarket(market) {
                await this.$store.dispatch("marketsTradesConnect", market);
                await this.$store.dispatch("marketsOrdersConnect", market);
            },
            async selectedAccount(selectedAccount) {
                await this.$store.dispatch(
                    "ordersTradesFetch", 
                    {
                        account: selectedAccount,
                        pageNumber: this.pageNumber,
                        pageLimit: this.pageLimit
                    }
                );
                await this.$store.dispatch("accountsBalancesFetch");
                await this.$store.dispatch("ordersTradesConnect", selectedAccount);
                await this.$store.dispatch("ordersFundingsConnect", selectedAccount);
                await this.$store.dispatch("accountsBalancesConnect", selectedAccount);
                await sleep(200);
                await this.$store.dispatch("ordersFundingsConnect", selectedAccount);
                await sleep(200);
                await this.$store.dispatch("ordersTradesConnect", selectedAccount);

                await this.refreshDisplayedBalance();
            },
            async balances() {
                await this.refreshDisplayedBalance();
            }
        },

        methods: {
            async refreshDisplayedBalance() {
                if (this.selectedAccount && this.balances.length > 0) {
                    this.balances.forEach(
                        balance => {
                            if (balance._account_id == this.selectedAccount.id) {
                                if (balance.short_name == this.selectedMarket.crypto_name) {
                                    this.cryptoBalance = balance;
                                }
                                else if (balance.short_name == this.selectedMarket.fiat_name) {
                                    this.fiatBalance = balance;
                                }
                            }
                        }
                    );
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    #trading-station-subpage {

        .SPEC_account_selector {
            margin : 4px 0px;
            width: 100%;

            .name-holder {
                margin: 0px 5px;
                width: calc(100% - 10px);
            }

            select {
                margin: 0px 5px;
                width: calc(100% - 10px);
            }
        }

        .select_and_info {
            margin-top: 4px;
        }

        .header {
            width: 100%;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 4px;
        }

        .chart-stack {
            flex-grow: 1;
        }

        .controls-stack {
            width: 360px;
        }
    }
</style>