<template>
    <div id="notifications-subpage" class="subpage">

        <div class="subpage_header">
            <h2>Notifications</h2>
        </div>

        <div class="filter-row">
            <input class="filter" type="search" placeholder="Search..." v-model="searchPhrase">
        </div>

        <div class="numerics_holder">
            <div class="custom_table_holder">
    
                <table class="table" cellspacing="0" v-if="notifications">
                    <thead class="table_header">
                        <tr class="row">
                            <th class="person_th">ID</th>
                        </tr>
                    </thead>
                    <tbody class="table_body">
                        <tr class="row" :class="selectedNotification && (selectedNotification.id == notification.id) ? 'selected' : ''" v-for="notification in filteredNotifications(notifications)" :key="notification.id" @click="selectNotification(notification)">
                            <td class="person_td">{{ notification.id }}</td>
                        </tr>
                    </tbody>
                </table>
    
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                searchPhrase: "",
                selectedNotification: null
            }
        },

        methods: {
            filteredNotifications(notifications) {
                return notifications.filter(
                    notification => {
                        if (notification.id && notification.id.toLowerCase().includes(this.searchPhrase)) {
                            return notification;
                        }
                    }
                );
            },

            selectNotification(notification) {
                this.selectNotification = notification;
            }
        }
    }
</script>