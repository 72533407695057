<template>
    <div id="members-subpage" class="subpage">

        <div class="subpage_header">
            <h2>Employees</h2>
        </div>

        <div class="filter-row">
            <!-- <select>
                <option value="none">Choose Employee</option>
            </select> -->
            <input class="item" type="button" value="Add New Employee" @click="openNewMember">
            <input class="filter" type="search" placeholder="Search..." v-model="searchPhrase" v-if="members.length > 0">
            <div class="filter dropdown-selector" v-if="members.length > 0">
                <div class="name-holder">
                    <span class="name">Sort by:</span>
                </div>
                <select class="select" v-model="membersSortBy.column">
                    <option value="firstName">First Name</option>
                    <option value="lastName">Last Name</option>
                    <option value="email">Email</option>
                    <option value="is_active">Status</option>
                    <option value="created_at">Created At</option>
                    <!-- <option value="type">Type</option> -->
                </select>
                <select class="select" v-model="membersSortBy.direction">
                    <option value="desc">DESC</option>
                    <option value="asc">ASC</option>
                </select>
            </div>
        </div>

        <div class="numerics_holder">
            
            <div class="custom_table_holder" v-if="members.length > 0">
                <table class="table" cellspacing="0">
                    <thead class="table_header">
                        <tr class="row">
                            <th class="person_th" @click="changeMembersSortBy('firstName')">First Name <span class="asc_desc_arrow" v-if="membersSortBy.column == 'firstName'">{{ membersSortBy.direction == 'asc' ? '&#8638;' : '&#8642;' }}</span></th>
                            <th class="person_th" @click="changeMembersSortBy('lastName')">Last Name <span class="asc_desc_arrow" v-if="membersSortBy.column == 'lastName'">{{ membersSortBy.direction == 'asc' ? '&#8638;' : '&#8642;' }}</span></th>
                            <th class="person_th" @click="changeMembersSortBy('email')">Email <span class="asc_desc_arrow" v-if="membersSortBy.column == 'email'">{{ membersSortBy.direction == 'asc' ? '&#8638;' : '&#8642;' }}</span></th>
                            <th class="person_th" @click="changeMembersSortBy('is_active')">Status <span class="asc_desc_arrow" v-if="membersSortBy.column == 'is_active'">{{ membersSortBy.direction == 'asc' ? '&#8638;' : '&#8642;' }}</span></th>
                            <th class="person_th" @click="changeMembersSortBy('created_at')">Created At <span class="asc_desc_arrow" v-if="membersSortBy.column == 'created_at'">{{ membersSortBy.direction == 'asc' ? '&#8638;' : '&#8642;' }}</span></th>
                            <!-- <th class="person_th">Created By</th> -->
                        </tr>
                    </thead>
                    <tbody class="table_body">
                        <tr class="row" :class="selectedMember && (selectedMember.id == member.id) ? 'selected' : ''" v-for="member in filterMembers(members)" :key="member.id" @click="selectMember(member)">
                            <td class="person_td">{{ member.member_name }}</td>
                            <td class="person_td">{{ member.member_last_name }}</td>
                            <td class="person_td">{{ member.member_email }}</td>
                            <td class="person_td">{{ member.is_active ? "active" : "not active" }}</td>
                            <td class="person_td">{{ member.created_at.slice(0,10) + " " + member.created_at.slice(11,19) }}</td>
                            <!-- <td class="person_td">{{ member.created_by }}</td> -->
                        </tr>
                    </tbody>
                </table>
            </div>

            <Transition name="item_explorer">
                <div class="item_explorer" v-if="newMember">
                    <div class="header">
                        <h3> Adding New Employee </h3>
                    </div>
                    <div class="settings">
                        <div class="settings_content">

                            <h3 class="settings_subgroup_header">Info</h3>                                
                            <p class="settings_description">Add general information for the new employee.</p>
                            <div class="text_setting">
                                <span class="label">First name:</span>
                                <input class="input" type="text" v-model="newMember.member_name">
                            </div>
                            <span class="hint_indicator" v-if="!newMember.member_name">Enter first name</span>
                            <span class="invalid_indicator" v-if="newMember.member_name && !newMember.member_name.match(/^[a-zA-Z0-9]+$/)">Invalid</span>
                            <div class="text_setting">
                                <span class="label">Last name:</span>
                                <input class="input" type="text" v-model="newMember.member_last_name">
                            </div>
                            <span class="hint_indicator" v-if="!newMember.member_last_name">Enter last name</span>
                            <span class="invalid_indicator" v-if="newMember.member_last_name && !newMember.member_last_name.match(/^[a-zA-Z0-9]+$/)">Invalid</span>
                            <div class="text_setting">
                                <span class="label">Email:</span>
                                <input class="input" type="text" v-model="newMember.member_email">
                            </div>
                            <span class="hint_indicator" v-if="!newMember.member_email">Enter email</span>
                            <span class="invalid_indicator" v-if="newMember.member_email && !newMember.member_email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)">Invalid</span>
                            <div class="text_setting">
                                <span class="label">Job title:</span>
                                <input class="input" type="text" v-model="newMember.member_job_title">
                            </div>
                            <span class="hint_indicator" v-if="!newMember.member_job_title">Enter job title</span>
                            <span class="invalid_indicator" v-if="newMember.member_job_title && !newMember.member_job_title.match(/^[a-zA-Z0-9]+$/)">Invalid</span>
                            <br><br>

                            <h3 class="settings_subgroup_header">Roles</h3>
                            <p class="settings_description">Assign roles to the new employee. (Please note that enabling a role will allow the employee to see and make changes with the associated topic. Employees must have at least one assigned role.)</p>
                            <div class="checkbox_pill_field">
                                <label for="overview" class="label">Overview</label>
                                <div class="toggle_checkbox">
                                    <input type="checkbox" class="check" id="overview" :value="'overview'" v-model="newMember.acl_roles"/>
                                    <label for="overview" class="toggle"><span></span></label>
                                </div>
                            </div>
                            <div class="checkbox_pill_field">
                                <label for="employees" class="label">Employees</label>
                                <div class="toggle_checkbox">
                                    <input type="checkbox" class="check" id="employees" :value="'employees'" v-model="newMember.acl_roles"/>
                                    <label for="employees" class="toggle"><span></span></label>
                                </div>
                            </div>
                            <div class="checkbox_pill_field">
                                <label for="clients" class="label">Clients</label>
                                <div class="toggle_checkbox">
                                    <input type="checkbox" class="check" id="clients" :value="'clients'" v-model="newMember.acl_roles"/>
                                    <label for="clients" class="toggle"><span></span></label>
                                </div>
                            </div>
                            <div class="checkbox_pill_field">
                                <label for="trading station" class="label">Trading Station</label>
                                <div class="toggle_checkbox">
                                    <input type="checkbox" class="check" id="trading station" :value="'trading station'" v-model="newMember.acl_roles"/>
                                    <label for="trading station" class="toggle"><span></span></label>
                                </div>
                            </div>
                            <div class="checkbox_pill_field">
                                <label for="fees" class="label">Fees</label>
                                <div class="toggle_checkbox">
                                    <input type="checkbox" class="check" id="fees" :value="'fees'" v-model="newMember.acl_roles"/>
                                    <label for="fees" class="toggle"><span></span></label>
                                </div>
                            </div>
                            <div class="checkbox_pill_field">
                                <label for="orders" class="label">Orders</label>
                                <div class="toggle_checkbox">
                                    <input type="checkbox" class="check" id="orders" :value="'orders'" v-model="newMember.acl_roles"/>
                                    <label for="orders" class="toggle"><span></span></label>
                                </div>
                            </div>
                            <div class="checkbox_pill_field">
                                <label for="trades" class="label">Trades</label>
                                <div class="toggle_checkbox">
                                    <input type="checkbox" class="check" id="trades" :value="'trades'" v-model="newMember.acl_roles"/>
                                    <label for="trades" class="toggle"><span></span></label>
                                </div>
                            </div>
                            <div class="checkbox_pill_field">
                                <label for="fundings" class="label">Fundings</label>
                                <div class="toggle_checkbox">
                                    <input type="checkbox" class="check" id="fundings" :value="'fundings'" v-model="newMember.acl_roles"/>
                                    <label for="fundings" class="toggle"><span></span></label>
                                </div>
                            </div>
                            <div class="checkbox_pill_field">
                                <label for="reallocations" class="label">Reallocations</label>
                                <div class="toggle_checkbox">
                                    <input type="checkbox" class="check" id="reallocations" :value="'reallocations'" v-model="newMember.acl_roles"/>
                                    <label for="reallocations" class="toggle"><span></span></label>
                                </div>
                            </div>
                            <br><br>

                            <h3 class="settings_subgroup_header">Assigned Account</h3>
                            <p class="settings_description">Assign an available account to the new employee. (Optional)</p>
                            <div class="checkbox_pill_field" v-for="account in accounts" :key="account.id">
                                <span class="label">{{ account.description ? account.description + ' (' + account.id + ')' : account.id }}</span>
                                <div class="toggle_checkbox">
                                    <input type="radio" class="check" :id="account.id" :value="account.id" v-model="newMember.allowed_accounts"/>
                                    <label :for="account.id" class="toggle"><span></span></label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="exit_options">
                        <input type="button" class="exit_option" value="Close" @click="closeExistingItemExplorer">
                        <input type="button" class="exit_option" value="Create" @click="createMember()">
                    </div>
                </div>
            </Transition>

            <Transition name="item_explorer">
                <div class="item_explorer" v-if="selectedMember">
                    <div class="header">
                        <h3> {{ selectedMember.member_name + " " + selectedMember.member_last_name }} </h3>
                    </div>
                    <div class="tabs">
                        <input type="button" class="tab" value="Details" :class="tableControls.selectedTab == 'Details' ? 'selected' : ''" @click="tableControls.selectedTab = 'Details'">
                        <input type="button" class="tab" value="Roles" :class="tableControls.selectedTab == 'Roles' ? 'selected' : ''" @click="tableControls.selectedTab = 'Roles'" v-if="selectedMember.acl_roles && user.id != selectedMember.id && $store.getters.userRolesGet('customer')">
                        <!-- <input type="button" class="tab" value="Members" :class="tableControls.selectedTab == 'Members' ? 'selected' : ''" @click="tableControls.selectedTab = 'Members'" v-if="selectedMember.allowed_members && user.id != selectMember.id && $store.getters.userRolesGet('customer')"> -->
                        <input type="button" class="tab" value="Assigned Account" :class="tableControls.selectedTab == 'Accounts' ? 'selected' : ''" @click="tableControls.selectedTab = 'Accounts'" v-if="user.id != selectMember.id && $store.getters.userRolesGet('customer') && accounts">
                    </div>
                    <div class="settings">
                        <div class="settings_content" v-if="tableControls.selectedTab == 'Details'">
                            <p class="settings_description">Edit general information for the employee.</p>
                            <div class="info_setting">
                                <span class="label">ID:</span>
                                <span class="info">{{ selectedMember.id }}</span>
                            </div>
                            <div class="info_setting">
                                <span class="label">Created at:</span>
                                <span class="info">{{ selectedMember.created_at.slice(0,10) + " " + selectedMember.created_at.slice(11,19) }}</span>
                            </div>
                            <div class="info_setting">
                                <span class="label">Type:</span>
                                <span class="info">{{ selectedMember.member_type }}</span>
                            </div>
                            <div class="info_setting">
                                <span class="label">Is Active:</span>
                                <span class="info">{{ selectedMember.is_active }}</span>
                            </div>
                            <div class="text_setting">
                                <span class="label">First Name:</span>
                                <input class="input" type="text" required v-model="selectedMember.member_name">
                            </div>
                            <span class="hint_indicator" v-if="!selectedMember.member_name">Enter first name</span>
                            <span class="changed_indicator" v-if="selectedMember.member_name != selectedMember.member_name_initial">Changed</span>
                            <span class="invalid_indicator" v-if="selectedMember.member_name && !selectedMember.member_name.match(/^[a-zA-Z0-9]+$/)">Invalid</span>
                            <div class="text_setting">
                                <span class="label">Last Name:</span>
                                <input class="input" type="text" required v-model="selectedMember.member_last_name">
                            </div>
                            <span class="hint_indicator" v-if="!selectedMember.member_last_name">Enter last name</span>
                            <span class="changed_indicator" v-if="selectedMember.member_last_name != selectedMember.member_last_name_initial">Changed</span>
                            <span class="invalid_indicator" v-if="selectedMember.member_last_name && !selectedMember.member_last_name.match(/^[a-zA-Z0-9]+$/)">Invalid</span>
                            <div class="text_setting">
                                <span class="label">Email:</span>
                                <input class="input" type="text" required v-model="selectedMember.member_email">
                            </div>
                            <span class="hint_indicator" v-if="!selectedMember.member_email">Enter email</span>
                            <span class="changed_indicator" v-if="selectedMember.member_email != selectedMember.member_email_initial">Changed</span>
                            <span class="invalid_indicator" v-if="selectedMember.member_email && !selectedMember.member_email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)">Invalid</span>
                            <div class="text_setting">
                                <span class="label">Job Title:</span>
                                <input class="input" type="text" v-model="selectedMember.member_job_title">
                            </div>
                            <span class="hint_indicator" v-if="!selectedMember.member_job_title">Enter job title</span>
                            <span class="changed_indicator" v-if="selectedMember.member_job_title != selectedMember.member_job_title_initial">Changed</span>
                            <span class="invalid_indicator" v-if="selectedMember.member_job_title && !selectedMember.member_job_title.match(/^[a-zA-Z0-9]+$/)">Invalid</span>
                        </div>
                        <div class="settings_content" v-if="tableControls.selectedTab == 'Roles'">
                            <p class="settings_description">Add or remove roles for the employee.</p>
                            <div class="checkbox_pill_field">
                                <label for="overview" class="label">Overview</label>
                                <div class="toggle_checkbox">
                                    <input type="checkbox" class="check" id="overview" :value="'overview'" v-model="selectedMember.acl_roles"/>
                                    <label for="overview" class="toggle"><span></span></label>
                                </div>
                            </div>
                            <div class="checkbox_pill_field">
                                <label for="employees" class="label">Employees</label>
                                <div class="toggle_checkbox">
                                    <input type="checkbox" class="check" id="employees" :value="'employees'" v-model="selectedMember.acl_roles"/>
                                    <label for="employees" class="toggle"><span></span></label>
                                </div>
                            </div>
                            <div class="checkbox_pill_field">
                                <label for="clients" class="label">Clients</label>
                                <div class="toggle_checkbox">
                                    <input type="checkbox" class="check" id="clients" :value="'clients'" v-model="selectedMember.acl_roles"/>
                                    <label for="clients" class="toggle"><span></span></label>
                                </div>
                            </div>
                            <div class="checkbox_pill_field">
                                <label for="trading station" class="label">Trading Station</label>
                                <div class="toggle_checkbox">
                                    <input type="checkbox" class="check" id="trading station" :value="'trading station'" v-model="selectedMember.acl_roles"/>
                                    <label for="trading station" class="toggle"><span></span></label>
                                </div>
                            </div>
                            <div class="checkbox_pill_field">
                                <label for="fees" class="label">Fees</label>
                                <div class="toggle_checkbox">
                                    <input type="checkbox" class="check" id="fees" :value="'fees'" v-model="selectedMember.acl_roles"/>
                                    <label for="fees" class="toggle"><span></span></label>
                                </div>
                            </div>
                            <div class="checkbox_pill_field">
                                <label for="orders" class="label">Orders</label>
                                <div class="toggle_checkbox">
                                    <input type="checkbox" class="check" id="orders" :value="'orders'" v-model="selectedMember.acl_roles"/>
                                    <label for="orders" class="toggle"><span></span></label>
                                </div>
                            </div>
                            <div class="checkbox_pill_field">
                                <label for="trades" class="label">Trades</label>
                                <div class="toggle_checkbox">
                                    <input type="checkbox" class="check" id="trades" :value="'trades'" v-model="selectedMember.acl_roles"/>
                                    <label for="trades" class="toggle"><span></span></label>
                                </div>
                            </div>
                            <div class="checkbox_pill_field">
                                <label for="fundings" class="label">Fundings</label>
                                <div class="toggle_checkbox">
                                    <input type="checkbox" class="check" id="fundings" :value="'fundings'" v-model="selectedMember.acl_roles"/>
                                    <label for="fundings" class="toggle"><span></span></label>
                                </div>
                            </div>
                            <div class="checkbox_pill_field">
                                <label for="reallocations" class="label">Reallocations</label>
                                <div class="toggle_checkbox">
                                    <input type="checkbox" class="check" id="reallocations" :value="'reallocations'" v-model="selectedMember.acl_roles"/>
                                    <label for="reallocations" class="toggle"><span></span></label>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="settings_content" v-if="tableControls.selectedTab == 'Members'">
                            <div class="checkbox_pill_field" v-for="member in members" :key="member.id">
                                <span class="label">{{ member.member_name + " " + member.member_last_name }}</span>
                                <input type="checkbox" :value="member.id" v-model="selectedMember.allowed_members">
                            </div>
                        </div> -->
                        <div class="settings_content" v-if="tableControls.selectedTab == 'Accounts'">
                            <p class="settings_description">Add assigned account for the employee.</p>
                            <div class="checkbox_pill_field" v-for="account in accounts" :key="account.id">
                                <span class="label">{{ account.description ? account.description + ' (' + account.id + ')' : account.id }}</span>
                                <div class="toggle_checkbox">
                                    <input type="radio" class="check" :id="account.id" :value="account.id" v-model="selectedMember.allowed_accounts"/>
                                    <label :for="account.id" class="toggle"><span></span></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="exit_options">
                        <input type="button" class="exit_option" value="Close" @click="closeExistingItemExplorer">
                        <input type="button" class="exit_option" value="Delete" @click="openDeleteCustomerMemberPopup">
                        <input type="button" class="exit_option" value="Save" @click="saveMember()">
                    </div>
                </div>
            </Transition>
        </div>
        
    </div>

    <DeleteCustomerMemberPopup 
        v-if="deleteCustomerMemberPopup.active"
        :message="deleteCustomerMemberPopup.message"
        @onConfirm="deleteCustomerMemberPopup.onConfirm"
        @onCancel="deleteCustomerMemberPopup.active = false"
    />

</template>

<script>
    import DeleteCustomerMemberPopup from "@/components/popups/confirmation-popup.vue";

    export default {
        components: {
            DeleteCustomerMemberPopup
        },

        data() {
            return {
                searchPhrase: "",
                membersSortBy: {
                    column: "created_at",
                    direction: "desc"
                },
                selectedMember: null,

                newMember: null,

                tableControls: {
                    selectedTab: "Details"
                },

                deleteCustomerMemberPopup: {
                    active: false,
                    message: "",
                    onConfirm: null
                }
            }
        },

        async beforeMount() {
            try {
                await this.$store.dispatch("membersFetch");
                if (this.$store.getters.userRolesGet("customers")) await this.$store.dispatch("accountsFetch");
            }
            catch (error) {
                console.error(error);
            }
        },

        computed: {
            members() {
                return this.$store.state.members.members;
            },
            accounts() {
                return this.$store.state.accounts.availableAccounts;
            },
            user() {
                return this.$store.state.user.user;
            }
        },

        methods: {
            closeExistingItemExplorer() {
                this.newMember = null;
                this.selectedMember = null;
            },

            async selectMember(member) {
                this.closeExistingItemExplorer();
                this.selectedMember = await this.$store.dispatch("membersFetch", member.id);

                this.selectedMember.member_name_initial = this.selectedMember.member_name;
                this.selectedMember.member_last_name_initial = this.selectedMember.member_last_name;
                this.selectedMember.member_email_initial = this.selectedMember.member_email;
                this.selectedMember.member_job_title_initial = this.selectedMember.member_job_title;
                if (this.selectedMember.acl_roles) this.selectedMember.acl_roles_initial = this.selectedMember.acl_roles;

                if (this.selectedMember.allowed_members) this.selectedMember.allowed_members_initial = this.selectedMember.allowed_members;

                if (this.selectedMember.allowed_accounts) {
                    this.selectedMember.allowed_accounts = this.selectedMember.allowed_accounts[0];
                    this.selectedMember.allowed_accounts_initial = this.selectedMember.allowed_accounts;
                } 

                this.tableControls.selectedTab = "Details";
            },

            async openNewMember() {
                this.closeExistingItemExplorer();
                this.newMember = {
                    member_name: "",
                    member_last_name: "",
                    member_email: "",
                    acl_roles: [
                        "overview"
                    ],
                    allowed_accounts: []
                }
            },
            
            async createMember() {
                try {
                    this.$store.commit("openLoadingOverlay");

                    if (this.newMember.acl_roles.length < 1) {
                        this.$store.commit("closeLoadingOverlay");
                        this.$store.commit("logsErrorAdd", "The employee must have at least one assigned role.");
                        return;
                    }

                    let member = this.newMember;

                    let response = await this.$store.dispatch(
                        "membersCreate",
                        member
                    );

                    member.id = response.data[0].id

                    await this.$store.dispatch(
                        "membersEdit",
                        member
                    );

                    await this.$store.dispatch(
                        "membersRolesAdd",
                        {
                            member: member,
                            addedRoles: member.acl_roles
                        }
                    );

                    if (member.allowed_accounts.length > 0) {
                        await this.$store.dispatch(
                            "membersAllowedAccountsAdd",
                            {
                                member: member,
                                accountID: member.allowed_accounts
                            }
                        );
                    }

                    this.newMember = null;

                    this.$store.commit("closeLoadingOverlay");
                }
                catch (error) {
                    console.error(error);
                    this.$store.commit("closeLoadingOverlay");
                }
            },

            async saveMember() {
                try {
                    if (this.tableControls.selectedTab == "Details") {
                        await this.$store.dispatch(
                            "membersEdit",
                            this.selectedMember
                        );
                    }
                    else if (this.tableControls.selectedTab == "Roles") {
                        if (this.selectedMember.acl_roles.length <= 2) {
                            this.$store.commit("logsErrorAdd", "The employee must have at least one assigned role.");
                            return;
                        }
                        if (this.selectedMember.acl_roles_initial != this.selectedMember.acl_roles) {
                            let addedRoles = await this.selectedMember.acl_roles.filter(x => !this.selectedMember.acl_roles_initial.includes(x));
                            if (addedRoles.length > 0) {
                                await this.$store.dispatch(
                                    "membersRolesAdd",
                                    {
                                        member: this.selectedMember,
                                        addedRoles: addedRoles
                                    }
                                );
                                this.selectedMember.acl_roles_initial = this.selectedMember.acl_roles_initial.concat(addedRoles);
                            }
                            let removedRoles = await this.selectedMember.acl_roles_initial.filter(x => !this.selectedMember.acl_roles.includes(x));
                            if (removedRoles.length > 0) {
                                await this.$store.dispatch(
                                    "membersRolesRemove",
                                    {
                                        member: this.selectedMember,
                                        removedRoles: removedRoles
                                    }
                                );
                                removedRoles.forEach(
                                    removedRole => {
                                        this.selectedMember.acl_roles_initial = this.selectedMember.acl_roles_initial.filter(
                                            initialRole => {
                                                return initialRole != removedRole;
                                            }
                                        )
                                    }
                                );
                            }
                        }
                    }
                    else if (this.tableControls.selectedTab == "Members") {
                        if (this.selectedMember.allowed_members_initial != this.selectedMember.allowed_members) {
                            let addedAllowedMembers = await this.selectedMember.allowed_members.filter(x => !this.selectedMember.allowed_members_initial.includes(x));
                            if (addedAllowedMembers.length > 0) {
                                await this.$store.dispatch(
                                    "membersAllowedMembersAdd",
                                    {
                                        member: this.selectedMember,
                                        addedAllowedMembers: addedAllowedMembers
                                    }
                                );
                                this.selectedMember.allowed_members_initial = this.selectedMember.allowed_members_initial.concat(addedAllowedMembers);
                            }
                            let removedAllowedMembers = await this.selectedMember.allowed_members_initial.filter(x => !this.selectedMember.allowed_members.includes(x));
                            if (removedAllowedMembers.length > 0) {
                                await this.$store.dispatch(
                                    "membersAllowedMembersRemove",
                                    {
                                        member: this.selectedMember,
                                        removedAllowedMembers: removedAllowedMembers
                                    }
                                );
                                removedAllowedMembers.forEach(
                                    removedAllowedMember => {
                                        this.selectedMember.allowed_members_initial = this.selectedMember.allowed_members_initial.filter(
                                            initalAllowedMember => {
                                                return initalAllowedMember != removedAllowedMember;
                                            }
                                        )
                                    }
                                );
                            }
                        }
                    }
                    else if (this.tableControls.selectedTab == "Accounts") {
                        if (this.selectedMember.allowed_accounts_initial != this.selectedMember.allowed_accounts) {

                            // let addedAllowedAccounts = await this.selectedMember.allowed_accounts.filter(x => !this.selectedMember.allowed_accounts_initial.includes(x));
                            // if (addedAllowedAccounts.length > 0) {
                            //     await this.$store.dispatch(
                            //         "membersAllowedAccountsAdd",
                            //         {
                            //             member: this.selectedMember,
                            //             addedAllowedAccounts: addedAllowedAccounts
                            //         }
                            //     );
                            //     this.selectedMember.allowed_accounts_initial = this.selectedMember.allowed_accounts_initial.concat(addedAllowedAccounts);
                            // }

                            await this.$store.dispatch(
                                "membersAllowedAccountsAdd",
                                {
                                    member: this.selectedMember,
                                    accountID: this.selectedMember.allowed_accounts
                                }
                            );
                            this.selectedMember.allowed_accounts_initial = this.selectedMember.allowed_accounts;


                            // let removedAllowedAccounts = await this.selectedMember.allowed_accounts_initial.filter(x => !this.selectedMember.allowed_accounts.includes(x));
                            // if (removedAllowedAccounts.length > 0) {
                            //     await this.$store.dispatch(
                            //         "membersAllowedAccountsRemove",
                            //         {
                            //             member: this.selectedMember,
                            //             removedAllowedAccounts: removedAllowedAccounts
                            //         }
                            //     );
                            //     removedAllowedAccounts.forEach(
                            //         removedAllowedAccount => {
                            //             this.selectedMember.allowed_accounts_initial = this.selectedMember.allowed_accounts_initial.filter(
                            //                 initalAllowedAccount => {
                            //                     return initalAllowedAccount != removedAllowedAccount;
                            //                 }
                            //             )
                            //         }
                            //     );
                            // }
                        }
                    }
                    this.selectedMember = null;
                }
                catch (error) {
                    console.error(error);
                }
            },

            openDeleteCustomerMemberPopup() {
                /*
                if (this.selectedMember.member_type == "default" ) {
                    alert ("Cannot delete default employee.");
                    return
                }
                */
                const member = {
                    id: this.selectedMember.id,
                    member_name: this.selectedMember.member_name,
                    member_last_name: this.selectedMember.member_last_name,
                    member_job_title: this.selectedMember.member_job_title
                }

                this.deleteCustomerMemberPopup.active = true;
                this.deleteCustomerMemberPopup.message = "Are you sure you want to delete " + this.selectedMember.member_name + "?";
                this.deleteCustomerMemberPopup.onConfirm = async () => {
                    try {
                        await this.$store.dispatch(
                            "membersDelete",
                            {
                                member: member
                            }
                        );
                        this.selectedMember = null;
                        this.deleteCustomerMemberPopup.active = false;
                    }
                    catch (error) {
                        console.error(error);
                    }
                }
            },

            changeMembersSortBy(column) {
                if (this.membersSortBy.column != column) this.membersSortBy.column = column;
                else if (this.membersSortBy.direction == "asc") this.membersSortBy.direction = "desc";
                else if (this.membersSortBy.direction == "desc") this.membersSortBy.direction = "asc";
            },

            filterMembers(members) {
                members = members.filter(
                    member => {
                        if(member.is_active == true) {
                            return member
                        }
                        if (member.member_name && member.member_name.toLowerCase().includes(this.searchPhrase)) {
                            return member;
                        }
                        if (member.member_last_name && member.member_last_name.toLowerCase().includes(this.searchPhrase)) {
                            return member;
                        }
                        if (member.member_email && member.member_email.toLowerCase().includes(this.searchPhrase)) {
                            return member;
                        }
                        if (member.created_at && member.created_at.toLowerCase().includes(this.searchPhrase)) {
                            return member;
                        }
                    }
                );

                if (this.membersSortBy.column == "firstName") {
                    members = members.sort(
                        (a, b) => {
                            a = (a.member_name || "").toUpperCase();
                            b = (b.member_name || "").toUpperCase();
                            if (a < b) return -1;
                            if (a > b) return 1;
                            return 0;
                        }
                    );
                }
                else if (this.membersSortBy.column == "lastName") {
                    members = members.sort(
                        (a, b) => {
                            a = (a.member_last_name || "").toUpperCase();
                            b = (b.member_last_name || "").toUpperCase();
                            if (a < b) return -1;
                            if (a > b) return 1;
                            return 0;
                        }
                    );
                }
                else if (this.membersSortBy.column == "email") {
                    members = members.sort(
                        (a, b) => {
                            a = (a.member_email || "").toUpperCase();
                            b = (b.member_email || "").toUpperCase();
                            if (a < b) return -1;
                            if (a > b) return 1;
                            return 0;
                        }
                    );
                }
                else if (this.membersSortBy.column == "is_active") {
                    members = members.sort(
                        (a, b) => {
                            a = (a.is_active || "").toString().toUpperCase();
                            b = (b.is_active || "").toString().toUpperCase();
                            if (a < b) return -1;
                            if (a > b) return 1;
                            return 0;
                        }
                    );
                }
                else if (this.membersSortBy.column == "created_at") {
                    members = members.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
                }
                else if (this.membersSortBy.column == "type") {
                    members = members.sort(
                        (a, b) => {
                            a = (a.member_type || "").toUpperCase();
                            b = (b.member_type || "").toUpperCase();
                            if (a < b) return -1;
                            if (a > b) return 1;
                            return 0;
                        }
                    );
                }

                if (this.membersSortBy.direction == "desc") return members;
                if (this.membersSortBy.direction == "asc") return members.reverse();
            },
        }
    }
</script>