<template>
    <div id="reallocations-subpage" class="subpage">

        <div class="subpage_header">
            <h2>Reallocations</h2>
        </div>
        
        <div class="filter-row">
            <input class="filter" type="search" placeholder="Search..." v-model="searchPhrase">
            <!-- <div class="filter time-span">
                <div class="name-holder">
                    <span class="name">Date Span:</span>
                </div>
                <input type="date" class="from-select" v-model="dates.from">
                <span class="to-span"> - </span>
                <input type="date" class="to-select" v-model="dates.to">
            </div> -->
            <!--
            <div class="filter dropdown-selector">
                <div class="name-holder">
                    <span class="name">Sort by:</span>
                </div>
                <select class="select" v-model="reallocationsSortBy.column">
                    <option value="base_amount">Base Amount</option>
                    <option value="executed_at">Executed At</option>
                    <option value="asset">Asset</option>
                </select>
                <select class="select" v-model="reallocationsSortBy.direction">
                    <option value="desc">DESC</option>
                    <option value="asc">ASC</option>
                </select>
            </div>
            -->
            <div class="filter dropdown-selector" v-if="accounts.length > 0">
                <div class="name-holder">
                    <span class="name">Account:</span>
                </div>
                <select class="select" v-model="selectedAccount">
                    <option :value="null" v-if="accounts.length > 1">All</option>
                    <option v-for="account in accounts" :key="account.id" :value="account">{{ account.description ? account.description + " (" + account.id + ")" : account.id }}</option>
                </select>
            </div>
        </div>
        
        <div class="numerics_holder"> 
            <ExecutionsReallocations 
                :orders="filteredReallocations(reallocations)"
                :assets="assets"
                :sortFilters="reallocationsSortBy"
                @changeSortFilters="changeReallocationsSortBy"
                :pageNumber="pageNumber"
                :pageLimit="pageLimit"
                :pageCount="reallocationsPageCount"
                @decreasePageNumber="pageNumber--"
                @increasePageNumber="pageNumber++"
                @toFirstPageNumber="pageNumber = 1"
                @toLastPageNumber="pageNumber = reallocationsPageCount"
                :resize="false"
                :HPadding="false"
                :VPadding="false"
                v-if="reallocations.length > 0 && assets.length > 0"
            />
            <div class="no_data_available" v-else>
                <span class="no_data_available_p" v-if="selectedAccount">The selected account has no executed reallocations.</span>
                <span class="no_data_available_p" v-else>You have no executed reallocations.</span>
            </div>
        </div>

    </div>
</template>

<script>
    import ExecutionsReallocations from "@/components/blocks/executions-reallocations-block.vue";

    export default {
        components: {
            ExecutionsReallocations
        },

        data() {
            return {
                selectedAccount: null,
                searchPhrase: "",
                reallocationsSortBy: {
                    column: "executed_at",
                    direction: "desc"
                },
                pageNumber: 1,
                pageLimit: 30,
                dates: {
                    from: null,
                    to: null
                }
            }
        },

        async beforeMount() {
            try {
                await this.$store.dispatch("marketsFetch");
                await this.$store.dispatch("accountsFetch");
                if (this.accounts.length == 1) {
                    this.selectedAccount = this.accounts[0];
                }
                else {
                    await this.$store.dispatch(
                        "executionsReallocationsFetch",
                        {
                            pageNumber: this.pageNumber,
                            pageLimit: this.pageLimit
                        }
                    );
                }
                await this.$store.dispatch("accountsBalancesFetch");
            }
            catch (error) {
                console.error(error);
            }
        },

        computed: {
            reallocations() {
                return this.$store.state.executions.reallocations;
            },
            reallocationsPageCount() {
                return this.$store.state.executions.reallocationsPageCount;
            },
            assets() {
                return this.$store.state.market.assets;
            },
            accounts() {
                return this.$store.state.accounts.availableAccounts;
            }
        },
        
        watch: {
            async selectedAccount() {
                if (this.pageNumber != 1) this.pageNumber = 1;
                else this.refreshReallocations();
            },
            async pageNumber() {
                await this.refreshReallocations();
            }
        },

        methods: {
            changeReallocationsSortBy(column) {
                if (this.reallocationsSortBy.column != column) this.reallocationsSortBy.column = column;
                else if (this.reallocationsSortBy.direction == "asc") this.reallocationsSortBy.direction = "desc";
                else if (this.reallocationsSortBy.direction == "desc") this.reallocationsSortBy.direction = "asc";
            },

            async refreshReallocations() {
                try {
                    await this.$store.dispatch(
                        "executionsReallocationsFetch", 
                        {
                            account: this.selectedAccount,
                            pageNumber: this.pageNumber,
                            pageLimit: this.pageLimit
                        }
                    );
                }
                catch (error) {
                    console.error(error);
                }
            },

            filteredReallocations(reallocations) {
                if (this.searchPhrase) {
                    reallocations = reallocations.filter(
                        reallocation => {
                            if (
                                (reallocation.id && reallocation.id.toLowerCase().includes(this.searchPhrase)) ||
                                (reallocation.order_id && reallocation.order_id.toLowerCase().includes(this.searchPhrase)) ||
                                (reallocation.executed_at && reallocation.executed_at.toLowerCase().includes(this.searchPhrase)) ||
                                (reallocation.account_id_from && reallocation.account_id_from.toLowerCase().includes(this.searchPhrase)) ||
                                (reallocation.account_id_to && reallocation.account_id_to.toLowerCase().includes(this.searchPhrase)) ||
                                (reallocation.base_amount && reallocation.base_amount.toLowerCase().includes(this.searchPhrase))
                            ) {
                                return reallocation;
                            }
                        }
                    );
                }
                if (this.dates.from) {
                    reallocations = reallocations.filter(
                        reallocation => {
                            if (new Date(reallocation.executed_at).toISOString().slice(0,10) >= this.dates.from) {
                                return reallocation;
                            }
                        }
                    );
                }
                if (this.dates.to) {
                    reallocations = reallocations.filter(
                        reallocation => {
                            if (new Date(reallocation.executed_at).toISOString().slice(0,10) <= this.dates.to) {
                                return reallocation;
                            }
                        }
                    );
                }

                if (this.reallocationsSortBy.column == "base_amount") {
                    reallocations = reallocations.sort(
                        (a, b) => {
                            a = parseFloat(a.base_amount);
                            b = parseFloat(b.base_amount);
                            if (a < b) return -1;
                            if (a > b) return 1;
                            return 0;
                        }
                    );
                }
                else if (this.reallocationsSortBy.column == "asset") {
                    reallocations = reallocations.sort(
                        (a, b) => {
                            a = (a.asset_id || "").toUpperCase();
                            b = (b.asset_id || "").toUpperCase();
                            if (a < b) return -1;
                            if (a > b) return 1;
                            return 0;
                        }
                    );
                }
                else if (this.reallocationsSortBy.column == "executed_at") {
                    reallocations = reallocations.sort((a, b) => new Date(a.executed_at) - new Date(b.executed_at));
                }

                if (this.reallocationsSortBy.direction == "desc") return reallocations;
                if (this.reallocationsSortBy.direction == "asc") return reallocations.reverse();
            }
        }
    }
</script>
