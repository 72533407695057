<template>
    <div id="login-page" class="page">
        <div class="login-content">
            <img src="@/assets/images/logos/adria-logo.svg" alt="">
            <form>
                <div class="fieldset">
                    <label class="label" for="username">User ID</label>
                    <input class="input" type="email" name="username" placeholder="Enter your user ID" v-model="userID" @keyup.enter="login">
                </div>
                <div class="fieldset">
                    <label class="label" for="password">Password</label>
                    <input class="input" type="password" name="password" placeholder="Enter your password" v-model="password" @keyup.enter="login">
                </div>
                <input type="button" class="loginbutton" :class="isDisabled ? 'disabled' : ''" value="LOGIN" @click="login">
            </form>
            <p class="version_span">{{ "ver." + getVersion() }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                userID: "",
                password: ""
            }
        },

        computed: {
            isDisabled() {
                return !(this.userID && this.password);
            }
        },

        methods: {
            getVersion() {
                return process.env.VUE_APP_VERSION;
            },
 
            async login() {
                try {
                    if (this.userID && this.password) {
                        this.$store.commit("logsPendingAdd", "Logging in...");
                        await this.$store.dispatch(
                            "authLogin",
                            { 
                                userID: this.userID, 
                                password: this.password
                            }
                        );
                        this.$router.push(
                            {
                                name: "dashboard"
                            }
                        );
                    }
                }
                catch (error) {
                    console.error(error);
                    if (error.response.status == 401) this.$store.commit("logsErrorAdd", "Incorrect user ID or password.");
                    else this.$store.commit("logsErrorAdd", "Error while logging in.");
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    #login-page {
        
        .login-content {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            width: 442px;
            max-width: 80%;
            height: 1200px;
            max-height: 100%;

            // img {
            //     width: 50%;
            // }
            
            form {
                // justify-content: space-between;
                height: 220px;

                .fieldset {
                    margin-bottom: 20px;
                }

                .loginbutton {
                    margin-top: 20px;
                    border-radius: 5px;
                    padding: 8px 24px;
                    background-color: var(--selected) !important;
                    font-weight: bold;
                    cursor: pointer;
                }
            }

            .version_span {
                position: absolute;
                bottom: 24px;
                // left: 24px;
                left: 50%;
                transform: translateX(-50%);
                font-weight: 500;
                color: #696969;
            }
        }
    }
</style>
