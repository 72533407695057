// import serviceMembers from "@/services/members.js";
import connector from "@/connectors/api";

export default {
    state: () => (
        {
            members: []
        }
    ),

    actions: {
        async membersFetch({ commit }, id) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                if (id) {
                    params = {
                        member_id: id
                    };
                }
                else if (this.getters.userRolesGet('customer')) {
                    {
                        params = {
                            all: true
                        };
                    }
                }

                let response = await connector.customer_member(params, payload, headers);
                
                if (id) {
                    return response.data[0];
                }
                else {
                    await commit("setMembers", response.data);
                    return response.data;
                }
            }
            catch (error) {
                console.error(error);
                if (id) {
                    await commit("logsErrorAdd", "Failed to open the employee's data.");
                }
                else {
                    await commit("logsErrorAdd", "Failed to fetch employees.");
                }
            }
        },

        async membersCreate({ dispatch, commit }, member) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                //member.member_email.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")
                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                const alphanumericRegex = /^[a-zA-Z0-9]+$/;

                if (member.member_email && !member.member_email.match(emailRegex)) {
                    // alert("Please check the email")
                    await commit("logsErrorAdd", "Please check the email address.");
                    return;
                } 
                if (member.member_name && !member.member_name.match(alphanumericRegex)) {
                    // alert("Please check the name")
                    await commit("logsErrorAdd", "Please check the first name.");
                    return;
                } 
                if (member.member_last_name && !member.member_last_name.match(alphanumericRegex)) {
                    // alert("Please check the last name")
                    await commit("logsErrorAdd", "Please check the last name.");
                    return;
                }
                if (member.member_job_title && !member.member_job_title.match(alphanumericRegex)) {
                    // alert("Please check the job title")
                    await commit("logsErrorAdd", "Please check the job title.");
                    return;
                } 

                let response = await connector.customer_member_create(params, payload, headers);
                alert("Username: "+ response.data[0].generated_credentials.username + " Password : " + response.data[0].generated_credentials.password );
                // await commit("logsSuccessAdd", "Member " + member.member_name + " " + member.member_last_name + " was created.");

                // member.id = response.data[0].id;

                // await dispatch(
                //     "membersEdit",
                //     member
                // );

                // await dispatch(
                //     "membersRolesAdd",
                //     {
                //         member: member,
                //         addedRoles: member.acl_roles
                //     }
                // );

                // if (member.allowed_accounts.length > 0) {
                //     await dispatch(
                //         "membersAllowedAccountsAdd",
                //         {
                //             member: member,
                //             accountID: member.allowed_accounts[0]
                //         }
                //     );
                // }

                // await commit("logsSuccessAdd", "Member " + member.member_name + " " + member.member_last_name + " was edited.");
                await dispatch("userFetch"); //Refresh allowed members in user so that the new member can be seen
                await dispatch("membersFetch");
                return response;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to create employee.");
            }
        },

        async membersEdit({ dispatch, commit }, member) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                // params = member.id;
                params = {
                    member_id: member.id
                }

                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                const alphanumericRegex = /^[a-zA-Z0-9]+$/;
                
                if(!member.member_email) {
                    // alert("Please check the email address")
                    await commit("logsErrorAdd", "Please check the email address.");
                    return;
                }
                if(!member.member_name) {
                    // alert("Please check the name")
                    await commit("logsErrorAdd", "Please check the first name.");
                    return;
                }
                if(!member.member_last_name) {
                    // alert("Please check the last name")
                    await commit("logsErrorAdd", "Please check the last name.");
                    return;
                }

                if (member.member_email && !member.member_email.match(emailRegex)) {
                    // alert("Please check the email address")
                    await commit("logsErrorAdd", "Please check the email address.");
                    return;
                } 
                if (member.member_name && !member.member_name.match(alphanumericRegex)) {
                    // alert("Please check the name")
                    await commit("logsErrorAdd", "Please check the first name.");
                    return;
                } 
                if (member.member_last_name && !member.member_last_name.match(alphanumericRegex)) {
                    // alert("Please check the last name")
                    await commit("logsErrorAdd", "Please check the last name.");
                    return;
                }
                if (member.member_job_title && !member.member_job_title.match(alphanumericRegex)) {
                    // alert("Please check the job title")
                    await commit("logsErrorAdd", "Please check the job title.");
                    return;
                } 

                payload = {
                    member_name: member.member_name,
                    member_last_name: member.member_last_name,
                    member_email: member.member_email,
                    member_job_title: member.member_job_title,
                    is_active: true
                };

                let response = await connector.customer_member_patch(params, payload, headers);

                await commit("logsSuccessAdd", "Employee " + member.member_name + " " + member.member_last_name + " was edited.");
                await dispatch("membersFetch");
                return response;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to edit employee.");
            }
        },

        async membersDelete({ dispatch, commit }, { member }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                // params = member.id;
                params = {
                    member_id: member.id
                }

                let response = await connector.customer_member_delete(params, payload, headers);
                await commit("logsSuccessAdd", "Member " + member.member_name + " " + member.member_last_name + " was deleted.");
                await dispatch("membersFetch");
                return response;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to delete employee.");
            }
        },

        async membersRolesAdd({ commit }, { member, addedRoles }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                params = {
                    member_id: member.id
                };
                // payload = {
                //     roles: addedRoles
                // };
                payload = {
                    roles: addedRoles
                };

                let response = await connector.customer_member_access_add(params, payload, headers);
                addedRoles.forEach(
                    async (addedRole) => {
                        await commit("logsSuccessAdd", "Added \"" + addedRole + "\" role to " + member.member_name + " " + member.member_last_name + ".");
                    }
                );
                return response;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to add roles to employee.");
            }
        },

        async membersRolesRemove({ commit }, { member, removedRoles }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                params = {
                    member_id: member.id,
                    roles: removedRoles.toString()
                };
                
                let response = await connector.customer_member_role_remove(params, payload, headers);
                removedRoles.forEach(
                    async (removedRole) => {
                        await commit("logsSuccessAdd", "Removed \"" + removedRole + "\" role from " + member.member_name + " " + member.member_last_name + ".");
                    }
                );
                return response;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to remove roles from employee.");
            }
        },

        async membersAllowedMembersAdd({ commit }, { member, addedAllowedMembers }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                params = {
                    member_id: member.id
                };
                payload = {
                    addedAllowedMembers: addedAllowedMembers
                };
                
                let response = await connector.customer_member_account_patch(params, payload, headers);
                addedAllowedMembers.forEach(
                    async (addedAllowedMember) => {
                        await commit("logsSuccessAdd", "Added access to employee \"" + addedAllowedMember.member_name + " " + addedAllowedMember.member_last_name + "\" for " + member.member_name + " " + member.member_last_name + ".");
                    }
                );
                return response;
            }
            catch (error) {
                console.error(error);
            }
        },

        async membersAllowedMembersRemove({ commit }, { member, removedAllowedMembers }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                params = {
                    member_id: member.id
                };
                payload = {
                    removedAllowedMembers: removedAllowedMembers
                };
                
                let response = await connector.customer_member_account_delete(params, payload, headers);
                removedAllowedMembers.forEach(
                    async (removedAllowedMember) => {
                        await commit("logsSuccessAdd", "Removed access to employee \"" + removedAllowedMember.member_name + " " + removedAllowedMember.member_last_name + "\" for " + member.member_name + " " + member.member_last_name + ".");
                    }
                );
                return response;
            }
            catch (error) {
                console.error(error);
            }
        },

        async membersAllowedAccountsAdd({ commit }, { member, accountID }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                params = {
                    member_id: member.id,
                    account_id: accountID
                };
                
                let response = await connector.customer_member_account_patch(params, payload, headers);
                await commit("logsSuccessAdd", "Switched assigned account to \"" + accountID + "\" for " + member.member_name + " " + member.member_last_name + ".");
                return response;
            }
            catch (error) {
                console.error(error);
            }
        },

        async membersAllowedAccountsRemove({ commit }, { member, account }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                params = {
                    member_id: member.id,
                    account_id: account.id
                };
                
                let response = await connector.customer_member_account_delete(params, payload, headers);
                await commit("logsSuccessAdd", "Removed access to account \"" + account.id + "\" for " + member.member_name + " " + member.member_last_name + ".");
                return response;
            }
            catch (error) {
                console.error(error);
            }
        }
    },

    mutations: {
        setMembers(state, members) {
            let allowedMembers = [];

            this.state.user.user.allowed_members.forEach(
                allowedMemberID => {
                    members.forEach(
                        member => {
                            if (allowedMemberID == member.id) {
                                allowedMembers.push(member);
                            }
                        }
                    )
                }
            );

            allowedMembers = allowedMembers.filter(
                member => {
                    if (member.member_type != "default") {
                        return member;
                    }
                }
            );

            state.members = allowedMembers;
        }
    }
}