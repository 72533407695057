import OpenAPIClientAxios from 'openapi-client-axios';
import ade_external from "./ade_external.json";
import store from "@/store/index.js";

const api = new OpenAPIClientAxios(
    { 
        definition: ade_external
    }
);

api.withServer(process.env.VUE_APP_API);
await api.init();
const connector = await api.getClient();

var sameRequestCount = 0;

connector.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("access_token");

        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }

        return config;
    },
    (error) => {
        console.error(error);
        throw error;
    }
);

connector.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        store.commit("closeLoadingOverlay");
        if (sameRequestCount == 0 && error.response && (error.response.status === 401 || error.response.status === 403)) {
            setTimeout(
                async () => {
                    sameRequestCount++;
                    store.dispatch("authRefresh");
                    error.response.config.headers.Authorization = 'Bearer ' + localStorage.getItem("access_token");
                    return await connector.request(error.config);
                },
                3000
            );
        }
        else if (sameRequestCount > 0) {
            sameRequestCount = 0;
            store.dispatch("authLogout");
        }
        else {
            console.error(error);
            throw error;
        }
    }
);

export default connector;