<template>
    <Numerics :header="'Orders'" class="open_orders">

        <table cellspacing="0" v-if="orders && sortOrders(orders).length > 0">
            <thead>
                <tr>
                    <!-- <th>Instrument</th> -->
                    <th>Date</th>
                    <th>Time</th>
                    <th>Market</th>
                    <th>Type</th>
                    <th>Side</th>
                    <th>Status</th>
                    <th class="number">Base Amount</th>
                    <th class="number">Base Amount Filled</th>
                    <th class="number">Quote Amount</th>
                    <th class="number">Quote Amount Filled</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(order, index) in sortOrders(orders)" :key="index" class="row" :class="isOdd(index) ? 'even': ''" @click="selectedOrder = order">
                    <td>{{ order.created_at.slice(0,10) }}</td>
                    <td>{{ order.created_at.slice(11,19) }}</td>
                    <td>{{ order.market_short_name }}</td>
                    <td>{{ order.order_type }}</td>
                    <td :class="setSideColor(order.order_side)">{{ order.order_side }}</td>
                    <td :class="setStatusColor(order.order_status)">{{ setStatusText(order.order_status) }}</td>
                    <td class="number">{{ order.base_amount }}</td>
                    <td class="number">{{ order.base_amount_filled }}</td>
                    <td class="number">{{ order.quote_amount }}</td>
                    <td class="number">{{ order.quote_amount_filled }}</td>
                    <td><input type="button" value="CANCEL" v-if="order.order_status == 'OPEN'" @click="openCancelOrderPopup(order)"></td>
                </tr>
            </tbody>
        </table>

        <p class="no_open_orders_message" v-else-if="selectedAccount">The selected account has no open orders.</p>
        <p class="no_open_orders_message" v-else-if="!selectedAccount">You have no open orders.</p>

        <Transition name="item_explorer">
            <div class="item_explorer" v-if="selectedOrder">
                <div class="header">
                    <h3> Order </h3>
                </div>
                <div class="settings">
                    <div class="settings_content">
                        <div class="info_setting">
                            <span class="label">Date:</span>
                            <span class="info">{{ selectedOrder.created_at.slice(0,10) }}</span>
                        </div>
                        <div class="info_setting">
                            <span class="label">Time:</span>
                            <span class="info">{{ selectedOrder.created_at.slice(11,19) }}</span>
                        </div>
                        <div class="info_setting">
                            <span class="label">Market:</span>
                            <span class="info">{{ selectedOrder.market_short_name }}</span>
                        </div>
                        <div class="info_setting">
                            <span class="label">Type:</span>
                            <span class="info">{{ selectedOrder.order_type }}</span>
                        </div>
                        <div class="info_setting">
                            <span class="label">Side:</span>
                            <span class="info">{{ selectedOrder.order_side }}</span>
                        </div>
                        <div class="info_setting">
                            <span class="label">Status:</span>
                            <span class="info">{{ setStatusText(selectedOrder.order_status) }}</span>
                        </div>
                        <div class="info_setting">
                            <span class="label">Base Amount:</span>
                            <span class="info">{{ selectedOrder.base_amount }}</span>
                        </div>
                        <div class="info_setting">
                            <span class="label">Base Amount Filled:</span>
                            <span class="info">{{ selectedOrder.base_amount_filled }}</span>
                        </div>
                        <div class="info_setting">
                            <span class="label">Quote Amount:</span>
                            <span class="info">{{ selectedOrder.quote_amount }}</span>
                        </div>
                        <div class="info_setting">
                            <span class="label">Quote Amount Filled:</span>
                            <span class="info">{{ selectedOrder.quote_amount_filled }}</span>
                        </div>
                    </div>
                </div>
                <div class="exit_options">
                    <input type="button" class="exit_option" value="Close" @click="closeExistingItemExplorer">
                    <input type="button" class="exit_option" value="Cancel Order" @click="openCancelOrderPopup(selectedOrder)" v-if="selectedOrder.order_status == 'OPEN'">
                </div>
            </div>
        </Transition>

        <CancelOrderPopup 
            v-if="cancelOrderPopup.active"
            :message="cancelOrderPopup.message"
            @onConfirm="cancelOrderPopup.onConfirm"
            @onCancel="cancelOrderPopup.active = false"
        />
        
    </Numerics>
</template>

<script>
    import Numerics from "@/components/slots/numerics-slot.vue";
    import CancelOrderPopup from "@/components/popups/confirmation-popup.vue";

    export default {
        props: {
            orders: {
                Type: Array
            },
            market: {
                Type: Object
            },
            selectedAccount: {
                Type: Object
            }
        },

        components: {
            Numerics,
            CancelOrderPopup
        },

        data() {
            return {
                selectedOrder: null,
                cancelOrderPopup: {
                    active: false,
                    message: "",
                    onConfirm: null
                }
            }
        },

        methods: {
            closeExistingItemExplorer() {
                this.selectedOrder = null;
            },

            sortOrders(orders) {
                if (orders) {
                    orders = orders.filter(
                        order => {
                            if (order.order_status == "FILLED" || order.order_status == "CANCELLED" || order.order_status == "REJECTED") {
                                return false;
                            }
                            if (this.market) {
                                if (this.market.id == order.market_id) {
                                    return true;
                                }
                                else {
                                    return false;
                                }
                            }
                            else {
                                return true;
                            }
                        }
                    );
                    orders = orders.sort(
                        (a, b) => {
                            return new Date(b.created_at) - new Date(a.created_at);
                        }
                    );
                }
                return orders;
            },

            setSideColor(side) {
                if (side == "BUY") {
                    return "positive";
                }
                else if (side == "SELL") {
                    return "negative";
                }
            },

            setStatusText(status) {
                if (status == "FILLED") return "EXECUTED";
                else return status;
            },

            setStatusColor(status) {
                // if (status == "Open") {
                //     return "positive";
                // }
                if (status == "FILLED") {
                    return "positive";
                }
                // else if (status == "Partially Filled") {
                //     return "pending";
                // }
                else if (status == "PENDING_SUBMISSION") {
                    return "pending";
                }
                else if (status == "REJECTED") {
                    return "negative";
                }
                else if (status == "CANCELLED") {
                    return "incomplete";
                }
            },

            isOdd(num) {
                return num % 2 !== 0;
            },

            openCancelOrderPopup(order) {
                const payload = order;
                this.cancelOrderPopup.active = true;
                this.cancelOrderPopup.message = "Are you sure to want to cancel the order?";
                this.cancelOrderPopup.onConfirm = async () => {
                    try {
                        await this.$store.dispatch("ordersTradesCancel", payload);
                        this.cancelOrderPopup.active = false;
                        this.selectedOrder = null;
                    }
                    catch (error) {
                        console.error(error);
                    }
                };
            }
        }
    }
</script>

<style lang="scss" scoped>
    .open_orders {
        width: 100%;
        max-height: 300px !important;

        table {
            width: 100%;

            thead {
                width: 100%;

                tr {
                    width: 100%;
                    // border-radius: 5px;

                    th {
                        // width: 14.285714%;
                        height: 25px;
                        font-size: 12px;
                        font-weight: 100;
                        text-align: left;

                        &.number {
                            text-align: right;
                        }
                    }
    
                    :first-child {
                        padding-left: 10px;
                    }

                    :last-child {
                        padding-right: 10px;
                    }
                }
            }

            tbody {
                width: 100%;

                tr {
                    width: 100%;
                    // border-radius: 5px;
    
                    &.row {
                        height: 40px;
                    }
    
                    td {
                        height: 25px;
                        font-size: 12px;
                        font-weight: 500;
                        text-align: left;

                        &.number {
                            text-align: right;
                        }
    
                        input {
                            height: 30px;
                            padding: 4px 8px;
                            text-align: center;
                            font-size: 15px;
                        }
                    }
    
                    :first-child {
                        padding-left: 10px;
                    }

                    :last-child {
                        padding-right: 10px;
                    }
    
                    &.even {
                        background-color: #2E2E2E;
                    }
    
                    &:hover {
                        background-color: #444;
                        cursor: pointer;
                    }
                }
            }
        }

        .no_open_orders_message {
            width: 100%;
            text-align: center;
            padding: 10px;
            color: var(--regular);
        }
    }
</style>