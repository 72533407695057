<template>
    <Numerics class="trader" :resize="false">
        <div class="bid_ask_buttons">
            <input type="button" value="BID" :class="side == 'BUY' ? 'selected': ''" @click="changeToBUY()">
            <div class="button_connector"></div>
            <input type="button" value="ASK" :class="side == 'SELL' ? 'selected': ''" @click="changeToSELL()">
        </div>

        <div class="bid_ask_buttons">
            <input type="button" value="MARKET" :class="type == 'MARKET' ? 'selected' : ''" @click="changeToMARKET()">
            <div class="button_connector"></div>
            <input type="button" value="LIMIT" :class="type == 'LIMIT' ? 'selected' : ''" @click="changeToLIMIT()">
        </div>
        
        <div class="price-amount">

            <div class="fieldset">
                <label class="label" for="amount">{{ amountLabel() }}</label>
                <input class="input" type="number" name="amount" v-model="amount" :placeholder="amountPlaceHolder(highestBid, lowestAsk)">
            </div>

            <div class="fieldset" v-if="type == 'LIMIT'">
                <label class="label" for="price">Price in {{ market.fiat_name + " (rate " }} <span :class="isDivisibleByDecimal(price, market.tick_rate) ? 'positive' : 'negative'">{{ market.tick_rate + ")" }}</span> </label>
                <input class="input" :class="isDivisibleByDecimal(price, market.tick_rate) ? 'positive' : 'negative'" type="number" name="price" v-model="price" :placeholder="pricePlaceHolder(highestBid, lowestAsk)">
            </div>

        </div>
        
        <div class="price-amount" v-if="side == 'SELL'">

            <div class="fieldset">
                <label class="label" for="price">Stop price in {{ market.fiat_name + " (rate " }} <span :class="isDivisibleByDecimal(stopPrice, market.tick_rate) ? 'positive' : 'negative'">{{ market.tick_rate + ")" }}</span> </label>
                <input class="input" :class="isDivisibleByDecimal(stopPrice, market.tick_rate) ? 'positive' : 'negative'"  type="number" name="price" pattern="^\d+(?:\.\d{1,2})?$" v-model="stopPrice">
            </div>

        </div>

        <input class="execute" type="button" value="EXECUTE" @click="openConfirmationPopup">

        <ConfirmationPopup 
            v-if="confirmationPopup.active"
            :message="confirmationPopup.message"
            @onConfirm="confirmationPopup.onConfirm"
            @onCancel="confirmationPopup.active = false"
        />
    </Numerics>
</template>

<script>
    import Numerics from "@/components/slots/numerics-slot.vue";
    import ConfirmationPopup from "@/components/popups/confirmation-popup.vue";

    export default {
        props: {
            account: {
                Type: Array
            },
            balances: {
                Type: Array
            },
            market: {
                Type: Object
            },
            bids: {
                Type: Object
            },
            asks: {
                Type: Object
            }
        },

        components: {
            Numerics,
            ConfirmationPopup
        },

        data() {
            return {
                confirmationPopup: {
                    active: false,
                    message: "Are you sure?",
                    onConfirm: null
                },
                payload: null,
                side: "BUY",
                price: "",
                amount: "",
                type: "MARKET",
                stopPrice: ""
            }
        },

        computed: {
            bidFromControls() {
                return this.$store.state.bidAsk.bidOrder;
            },

            askFromControls() {
                return this.$store.state.bidAsk.askOrder;
            },

            highestBid() {
                let price = Object.keys(this.bids).sort(
                    (a, b) => {
                        return parseFloat(b) - parseFloat(a)
                    }
                )[0];
                return this.bids[price];
            },

            lowestAsk() {
                let price = Object.keys(this.asks).sort(
                    (a, b) => {
                        return parseFloat(a) - parseFloat(b)
                    }
                )[0];
                return this.asks[price];
            }
        },

        watch: {
            bidFromControls(bid) {
                // this.side = "SELL";

                if (this.type == "MARKET") {
                    this.amount = bid.amount;
                }
                else if (this.type == "LIMIT") {
                    this.amount = bid.amount;
                    this.price = bid.price;
                }
            },

            askFromControls(ask) {
                // this.side = "BUY";

                if (this.type == "MARKET") {
                    this.amount = ask.price;
                }
                else if (this.type == "LIMIT") {
                    this.amount = ask.amount;
                    this.price = ask.price;
                }
            }
        },

        methods: {
            isDivisibleByDecimal(number, divisor) {
                const scaledNumber = number * 100;
                const scaledDivisor = divisor * 100;
                
                return scaledNumber % scaledDivisor === 0;
            },

            amountPlaceHolder(highestBid, lowestAsk) {
                if (this.side == "BUY") {
                    if (this.type == "MARKET") {
                        if (lowestAsk) return parseFloat(lowestAsk.price).toFixed(2);
                    }
                    else if (this.type == "LIMIT") {
                        if (lowestAsk) return lowestAsk.amount;
                    }
                }
                else if (this.side == "SELL") {
                    if (this.type == "MARKET") {
                        if (highestBid) return highestBid.amount;
                    }
                    else if (this.type == "LIMIT") {
                        if (highestBid) return highestBid.amount;
                    }
                }
            },

            pricePlaceHolder(highestBid, lowestAsk) {
                if (this.side == "BUY") {
                    if (this.type == "MARKET") {
                        if (lowestAsk) return "";
                    }
                    else if (this.type == "LIMIT") {
                        if (lowestAsk) return parseFloat(lowestAsk.price).toFixed(2);
                    }
                }
                else if (this.side == "SELL") {
                    if (this.type == "MARKET") {
                        if (highestBid) return "";
                    }
                    else if (this.type == "LIMIT") {
                        if (highestBid) return parseFloat(highestBid.price).toFixed(2);
                    }
                }
            },

            changeToBUY() {
                this.price = "";
                this.amount = "";
                this.stopPrice = "";
                this.side = "BUY";
            },

            changeToSELL() {
                this.price = "";
                this.amount = "";
                this.stopPrice = "";
                this.side = "SELL";
            },

            changeToMARKET() {
                this.price = "";
                this.amount = "";
                this.stopPrice = "";
                this.type = "MARKET";
            },

            changeToLIMIT() {
                this.price = "";
                this.amount = "";
                this.stopPrice = "";
                this.type = "LIMIT";
            },

            amountLabel() {
                let label;
                if (this.type == "MARKET") {
                    if (this.side == "BUY") {
                        label = "Amount in " + this.market.fiat_name;
                    }
                    else {
                        label = "Amount in " + this.market.crypto_name;
                    }
                }
                else {
                    if (this.side == "BUY") {
                        label = "Amount in " + this.market.crypto_name;                    
                    }
                    else {
                        label = "Amount in " + this.market.crypto_name;                    
                    }
                }
                return label;
            },

            async openConfirmationPopup() {
                if (this.amount <= 0) {
                    this.$store.commit("logsErrorAdd", "Please enter a valid amount.");
                    return;
                }
                if (this.price <= 0 && this.type == "LIMIT") {
                    this.$store.commit("logsErrorAdd", "Please enter a valid price.");
                    return;
                }
                
                if (this.isDivisibleByDecimal(this.price, this.market.tick_rate) == false) {
                    this.$store.commit("logsErrorAdd", "The price does not match the rate. (" + this.market.tick_rate + ")");
                    return;
                }

                let sufficientFunds = true;
               
                this.balances.forEach(
                    balance => {
                        if (balance.short_name == this.market.fiat_name && this.account.id == balance._account_id) {
                            if (balance._available < this.amount) {
                                sufficientFunds = false;
                            }
                        }
                    }
                );

                if (sufficientFunds == false) {
                    this.$store.commit("logsErrorAdd", "You don't have enough " + this.market.fiat_name + " to place the order.");
                    return;
                }

                let order = {
                    account_id: this.account.id,
                    market_id: this.market.id,
                    order_side: this.side,
                    order_type: this.type,
                    amount: this.amount.toString(),
                    price: this.price.toString(),
                    market: this.market.short_name,
                    stop_price: this.stopPrice.toString()
                };

                let message;
                if (this.type == "MARKET") {
                    if (this.side == "BUY") {
                        message = "Are you sure you want to buy " + this.market.crypto_name + " for " + this.amount + " " + this.market.fiat_name + "?";
                    }
                    else {
                        message = "Are you sure you want to sell " + this.amount + " " + this.market.crypto_name + "?";
                    }
                }
                else {
                    if (this.side == "BUY") {
                        message = "Are you sure you want to buy " + this.amount + " " + this.market.crypto_name + " for " + this.price + " " + this.market.fiat_name + "?"
                    }
                    else {
                        message = "Are you sure you want to sell " + this.amount + " " + this.market.crypto_name + " for " + this.price + " " + this.market.fiat_name + "?"
                    }
                }

                if (this.stopPrice) {
                    if (this.isDivisibleByDecimal(this.stopPrice, this.market.tick_rate) == false) {
                        this.$store.commit("logsErrorAdd", "The stop price does not match the rate. (" + this.market.tick_rate + ")");
                        return;
                    }

                    if (order.order_type == "MARKET" && order.order_side == "SELL") {
                        order.order_type = "STOP_MARKET";
                    }
                    else if (order.order_type == "LIMIT" && order.order_side == "SELL") {
                        order.order_type = "STOP_LIMIT";
                    }
                }

                delete order.market;
                if (!this.stopPrice) {
                    delete order.stop_price;
                }
                if (!this.price) {
                    delete order.price;
                }

                this.confirmationPopup.active = true;
                this.confirmationPopup.message = message;
                this.confirmationPopup.onConfirm = async () => {
                    try {
                        await this.$store.dispatch("ordersTradesCreate", order);
                        this.confirmationPopup.active = false;

                        this.price = "";
                        this.amount = "";
                        this.stopPrice = "";
                    }
                    catch (error) {
                        console.error(error);
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .trader {
        width: 350px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // max-height : 220px;

        .bid_ask_buttons {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
            width: 100%;
            height : 30px;
            padding : 0px;

            input[type="button"] {
                border: 1px solid var(--selected);
                width: 48%;
                max-height : 30px;
                background: 0;
                padding : 0px;
                font-size : 10px;

                &.selected {
                    background-color: var(--selected);
                }

                &:hover {
                    background-color: var(--selected);
                }

                &:focus {
                    background-color: var(--selected);
                }
            }

            .button_connector {
                width: 4%;
                height: 2px;
                background-color: var(--selected);
            }
        }

        .price-amount {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            width: 100%;

            .fieldset {
                width: 48%;

                label {
                    font-size: 10px;
                }

                span {
                    font-size: 10px;
                }

                input {
                    background-color: #333;
                    text-align: right;
                    // border-bottom: 1px solid var(--regular);
                    // border-radius: 0px;
                }
            }
        }

        .execute {
            border: 1px solid var(--positive);
            width: 100%;
            background: 0;
            height : 35px;
            padding : 0px;
            font-size : 10px;

            &:hover {
                background-color: var(--positive);
            }

            &:focus {
                background-color: var(--positive);
            }
        }
    }
</style>