<template>
    <div id="confirmation-popup">
        <div class="popup">
            <p>{{ message }}</p>
            <div class="buttons">
                <input type="button" value="Cancel" @click="$emit('onCancel')">
                <input type="button" value="Confirm" @click="$emit('onConfirm')">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            message: {
                Type: String
            }
        }
    }
</script>

<style lang="scss" scoped>
    #confirmation-popup {
        z-index: 100000;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,0.5);
        // -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);

        .popup {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 5px;
            width: 500px;
            max-width: 100%;
            background-color: #202020;

            p {
                width: 100%;
                padding: 30px;
                text-align: center;
                white-space: wrap;
            }

            .buttons {
                display: flex;
                width: 100%;

                input[type="button"] {
                    border-radius: 0px;
                    width: 50%;
                }
            }
        }
    }
</style>