<template>
    <div class="numerics-slot">
        <div class="numerics_header" v-if="header" @click="expanded = !expanded">
            <span class="numerics_slot_name">
                {{ header }}
            </span>
            <!-- <span class="expand_button" @click="expanded = !expanded">
                {{ expanded ? "-" : "+" }}
            </span> -->
        </div>
        <Transition name="expanding-data">
            <div class="numerics_data" :style="resize ? 'resize: both;': ''" :class="setPaddings" v-if="expanded">
                <slot></slot>
            </div>
        </Transition>
        <div class="pager" v-if="pageCount > 1">
            <input class="pager_turn_button" type="button" value="<<" @click="$emit('toFirstPageNumber')" :class="pageNumber != 1 ? '' : 'disabled'">
            <input class="pager_turn_button" type="button" value="<" @click="$emit('decreasePageNumber')" :class="pageNumber != 1 ? '' : 'disabled'">
            <span class="pager_indicator">{{ pageNumber + " of " +  pageCount }}</span>
            <input class="pager_turn_button" type="button" value=">" @click="$emit('increasePageNumber')" :class="pageNumber != pageCount ? '' : 'disabled'">
            <input class="pager_turn_button" type="button" value=">>" @click="$emit('toLastPageNumber')" :class="pageNumber != pageCount ? '' : 'disabled'">
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            header: {
                Type: String
            },

            resize: {
                Type: Boolean,
                default: () => {
                    return true;
                }
            }, 

            VPadding: {
                Type: Boolean,
                default: () => {
                    return true;
                }
            },

            HPadding: {
                Type: Boolean,
                default: () => {
                    return true;
                }
            },

            pageNumber: {
                Type: Number
            },

            pageLimit: {
                Type: Number
            },

            pageCount: {
                Type: Number
            }
        },

        data() {
            return {
                expanded: true
            }
        },

        computed: {
            setPaddings() {
                var c = "";
                if (this.VPadding) {
                    c += "v-padded ";
                }
                if (this.HPadding) {
                    c += "h-padded";
                }
                return c;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .numerics-slot {
        margin: 5px;
        border-radius: 5px;
        // min-width: 300px;
        flex-grow: 1;
        max-width: calc(100% - 12px);
        //max-height: 300px;
        background-color: #242424;
        float: left;

        .numerics_header {
            display: flex;
            position: sticky;
            top: 0;
            border-radius: 1px;
            width: 100%;
            // padding: 8px 10px;
            background-color: #333;
            font-size: 12px;
            font-weight: 600;

            &:hover {
                cursor: pointer;
                background-color: #424242;
            }

            .numerics_slot_name {
                padding: 8px 10px;
            }

            .expand_button {
                margin-left: auto;
                padding: 8px 10px;

                &:hover {
                    cursor: pointer;
                    color: var(--selected);
                }
            }
        }

        .numerics_data {
            min-width: 100%;
            max-width: 100%;
            overflow: auto;

            &.v-padded {
                padding-top: 10px;
                padding-bottom: 10px;
            }
            &.h-padded {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .expanding-data-enter-active {
            animation: expanding-data-animation 0.15s forwards ease-out;
        }

        .expanding-data-leave-active {
            animation: expanding-data-animation 0.15s forwards ease-out reverse;
        }

        @keyframes expanding-data-animation {
            0% {
                height: 0px;
                max-height: 0px;
            }
            100% {
                height: fit-content;
                max-height: fit-content; 
            }
        }

        .pager {
            display: flex;
            justify-content: center;
            align-items: center;
            position: sticky;
            bottom: 0;
            width: 100%;
            height: 50px;
            background-color: #242424;

            .pager_turn_button {
                margin: 0px 5px;
            }

            .pager_indicator {
                margin-left: 10px;
                margin-right: 10px;
                text-align: center;
                font-weight: 700;
            }
        }
    }
</style>