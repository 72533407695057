<template>
    <Numerics :header="'BID'" class="market_bids">
        <table cellspacing="0">
            <thead>
                <tr>
                    <th>Price</th>
                    <th>Amount</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in Object.keys(bids).sort((a, b) => {return parseFloat(b) - parseFloat(a)}).splice(0, 40)" :key="item" :class="isOdd(index) ? 'even': ''" @click="$store.commit('prepareBidOrder', bids[item])">
                    <td class="positive price">{{ parseFloat(bids[item].price).toFixed(2) }}</td>
                    <td class="important amount">{{ parseFloat(bids[item].amount).toFixed(8) }}</td>
                    <td class="value">{{ (bids[item].price * bids[item].amount).toFixed(2) }}</td>
                </tr>
            </tbody>
        </table>
    </Numerics>
</template>

<script>
    import Numerics from "@/components/slots/numerics-slot.vue";

    export default {
        components: {
            Numerics
        },

        computed: {
            bids() {
                return this.$store.state.market.orders.bids;
            }
        },

        methods: {
            isOdd(num) {
                return num % 2 !== 0;
            },

            amountStyle() {
                return "changingColor";
            }
        }
    }
</script>

<style lang="scss" scoped>
    .market_bids {
        width: 340px;

        table {
            width: 100%;

            thead {
                width: 100%;
                
                th {
                    text-align: right;
                    font-size : 10px;
                }
            }

            tbody {
                width: 100%;

                tr {
    
                    &:hover {
                        background-color: #555555 !important;
                    }
    
                    &.even {
                        background-color: #2E2E2E;
                    }
    
                    td {
                        width: 33.33%;
                        text-align: right;
                        cursor: pointer;
                        font-size : 10px;
                    }
                }
            }
        }
    }
</style>