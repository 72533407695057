import connector from "@/connectors/keycloak";
import qs from 'qs';

export default {
    state: () => (
        {
            auth: null
        }
    ),

    actions: {
        async authLogin({ commit }, credentials) {
            try {
                await commit("openLoadingOverlay");

                let response = await connector.post(
                    "token",
                    qs.stringify(
                        {
                            grant_type: "password",
                            client_id: "admin-cli",
                            username: credentials.userID,
                            password: credentials.password
                        }
                    )
                );
    
                await commit(
                    "authSet", 
                    {
                        accessToken: response.data.access_token,
                        refreshToken: response.data.refresh_token,
                        userID: credentials.userID
                    }
                );

                await commit("closeLoadingOverlay");
                
                return response;
            }
            catch (error) {
                await commit("closeLoadingOverlay");
                console.error(error);
                if (error.response.status == 401) commit("logsErrorAdd", "Incorrect user ID or password.");
                else commit("logsErrorAdd", "Error while logging in.");
            }
        },

        async authRefresh({ commit, dispatch }) {
            let response;
            try {
                response = await connector.post(
                    "token", 
                    qs.stringify(
                        {
                            grant_type: "refresh_token",
                            client_id: "admin-cli",
                            refresh_token: localStorage.getItem("refresh_token")
                        }
                    )
                );

                await commit(
                    "authSet", 
                    {
                        accessToken: response.data.access_token,
                        refreshToken: response.data.refresh_token
                    }
                );
            }
            catch (error) {
                console.error(error);
                await dispatch("authLogout");
            }
            
            return response[0];
        },

        async authLogout({ commit }) {
            let response;
            try {
                response = await connector.post(
                    "logout",
                    qs.stringify(
                        {
                            grant_type: "refresh_token",
                            client_id: "admin-cli",
                            refresh_token: localStorage.getItem("refresh_token")
                        }
                    )
                );
            }
            catch (error) {
                console.error(error);
            }

            await commit("authClear");

            return response;
        }
    },

    mutations: {
        authSet(state, auth) {
            state.auth = auth;
            
            if (auth.accessToken) localStorage.setItem("access_token", auth.accessToken);
            if (auth.refreshToken) localStorage.setItem("refresh_token", auth.refreshToken);
            if (auth.userID) localStorage.setItem("user_ID", auth.userID);
        },

        authClear() {
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("user_ID");

            window.location.reload(true);
        }
    }
}