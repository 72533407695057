<template>
  <Bar class="barchartt" :data="chartData" :options="options" />
</template>

<script>
  import { Bar } from "vue-chartjs";
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
  } from "chart.js";

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
  );
  ChartJS.defaults.font.size = 12;
  ChartJS.defaults.color = "white";

  export default {
    name: "BarChart",

    components: { 
      Bar 
    },

    props: {
      chartData: {
        type: Object,
        required: true
      },
      options: {
        type: Object,
        default: () => {}
      }
    }
  };
</script>

<style lang="scss" scoped>
  .barchart_div {
    max-width: 400px;
  }
  .barchartt {
    max-height: 300px;
    max-width: 500px;
    border-style: dashed;
    border-width: 1px;
    border-color: rgb(61, 61, 61);
    padding: 3px;
  }
</style>