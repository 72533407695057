export default {
    state: () => (
        {
            bidOrder: {
                price: 0,
                amount: 0
            },
            askOrder: {
                price: 0,
                amount: 0
            }
        }
    ),

    mutations: {
        prepareBidOrder(state, order) {
            state.bidOrder = {
                price: 0,
                amount: 0
            };
            state.bidOrder.price = parseFloat(order.price).toFixed(2);
            state.bidOrder.amount = parseFloat(order.amount).toFixed(8);
        },

        prepareAskOrder(state, order) {
            state.askOrder = {
                price: 0,
                amount: 0
            };
            state.askOrder.price = parseFloat(order.price).toFixed(2);
            state.askOrder.amount = parseFloat(order.amount).toFixed(8);
        }
    }
}