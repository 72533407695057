<template>
    <!-- <TransitionGroup name="fade"> -->
        <div class="info-item" v-for="(log, index) in logs" :key="index" @click="$store.commit('logsRemove', index)" :style="'bottom: ' + (logs.length - (index + 1)) * 45 + 'px'">
            <div class="type-indicator" :class="log.type"></div>
            <span>{{ log.message }}</span>
        </div>
    <!-- </TransitionGroup> -->
</template>

<script>
    export default {
        computed: {
            logs() {
                return this.$store.state.logs.logs;
            }
        },

        // mounted() {
        //     this.$store.commit("logsRegularAdd", "111");
        //     this.$store.commit("logsRegularAdd", "222");
        //     this.$store.commit("logsRegularAdd", "333");
        //     this.$store.commit("logsRegularAdd", "444");
        //     this.$store.commit("logsRegularAdd", "555");
        // }
    }
</script>

<style lang="scss" scoped>
    // #ade-logger {
    //     z-index: 100001;
    //     // display: flex;
    //     // flex-direction: column-reverse;
    //     position: absolute;
    //     right: 0px;
    //     bottom: 0px;
    //     width: 400px;
    //     // height: 400px;
    //     // height: calc(100% - 50px);
    //     padding: 5px;
    //     overflow: visible;
    //     list-style-type: none;

    //     .info-item {
    //         display: flex;
    //         // position: absolute;
    //         align-items: center;
    //         margin: 5px;
    //         margin-left: auto;
    //         border-radius: 5px;
    //         width: fit-content;
    //         max-width: calc(100% - 10px);
    //         height: 36px;
    //         background-color: #353535;
    //         box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    //         overflow: hidden;

    //         .type-indicator {
    //             width: 5px;
    //             height: 100%;
                
    //             &.normal {
    //                 background-color: var(--selected);
    //             }

    //             &.success {
    //                 background-color: var(--positive);
    //             }

    //             &.error {
    //                 background-color: #c22b2b;
    //             }
    //         }

    //         span {
    //             padding: 10px;
    //         }
    //     }
    // }

    // /* 1. declare transition */
    // .fade-move,
    // .fade-enter-active,
    // .fade-leave-active {
    //     transition: all 0.5s ease;
    // }

    // /* 2. declare enter from and leave to state */
    // .fade-enter-from,
    // .fade-leave-to {
    //     opacity: 0;
    //     // transform: translateX(30px);
    //     right: 30px;
    // }

    // /* 3. ensure leaving items are taken out of layout flow so that moving animations can be calculated correctly. */
    // .fade-leave-active {
    //     position: absolute;
    // }

    .info-item {
        z-index: 100001;
        display: flex;
        opacity: 0;
        position: absolute;
        right: 0px;
        align-items: center;
        margin: 10px;
        // margin-left: auto;
        border-radius: 5px;
        width: fit-content;
        max-width: calc(100% - 10px);
        height: 36px;
        background-color: #353535;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        overflow: hidden;
        animation: logline 5s forwards;

        .type-indicator {
            width: 5px;
            height: 100%;
            
            &.normal {
                background-color: var(--special);
            }

            &.success {
                background-color: var(--positive);
            }

            &.pending {
                background-color: var(--pending);
            }

            &.error {
                background-color: #c22b2b;
            }
        }

        span {
            padding: 10px;
        }
    }

    // /* 1. declare transition */
    // .fade-move,
    // .fade-enter-active,
    // .fade-leave-active {
    //     transition: all 0.5s ease;
    // }

    // /* 2. declare enter from and leave to state */
    // .fade-enter-from,
    // .fade-leave-to {
    //     opacity: 0;
    //     // transform: translateX(30px);
    //     right: -60px;
    // }

    // /* 3. ensure leaving items are taken out of layout flow so that moving animations can be calculated correctly. */
    // .fade-leave-active {
    //     position: absolute;
    // }

    // .fade-enter-active {
    //     animation: logline 5s;
    // }

    // .fade-leave-active {
    //     animation: logline 5s reverse;
    // }

    @keyframes logline {
        0% {
            display: none;
            opacity: 0;
            right: -60px;
            width: 0px;
        }
        10% {
            display: flex;
            opacity: 1;
            right: 0px;
            width: fit-content;
        }
        90% {
            display: flex;
            opacity: 1;
            right: 0px;
            width: fit-content;
        }
        100% {
            display: none;
            opacity: 0;
            right: -60px;
            width: 0px;
        }
    }
</style>