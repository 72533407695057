<template>
    <div id="fundings-subpage" class="subpage">

        <div class="subpage_header">
            <h2>Fundings</h2>
        </div>

        <div class="filter-row">
            <input class="filter" type="search" placeholder="Search..." v-model="searchPhrase">
            <!-- <div class="filter time-span">
                <div class="name-holder">
                    <span class="name">Date Span:</span>
                </div>
                <input type="date" class="from-select" v-model="dates.from">
                <span class="to-span"> - </span>
                <input type="date" class="to-select" v-model="dates.to">
            </div> -->
          <!--
            <div class="filter dropdown-selector">
                <div class="name-holder">
                    <span class="name">Sort by:</span>
                </div>
                <select class="select" v-model="fundingsSortBy.column">
                    <option value="base_amount">Base Amount</option>
                    <option value="created_at">Created At</option>
                    <option value="executed_at">Executed At</option>
                </select>
                <select class="select" v-model="fundingsSortBy.direction">
                    <option value="desc">DESC</option>
                    <option value="asc">ASC</option>
                </select>
            </div>
       -->
            <div class="filter dropdown-selector" v-if="accounts.length > 0">
                <div class="name-holder">
                    <span class="name">Account:</span>
                </div>
                <select class="select" v-model="selectedAccount">
                    <option :value="null" v-if="accounts.length > 1">All</option>
                    <option v-for="account in accounts" :key="account.id" :value="account">{{ account.description ? account.description + " (" + account.id + ")" : account.id }}</option>
                </select>
            </div>
        </div>

        <div class="numerics_holder">
            <DepositWithdraw 
                v-if="selectedAccount"
                :selectedAccount="selectedAccount"
                :balances="balances"
                :assets="assets"
                :fiatAssets="fiatAssets"
            />
            <!-- <ExecutionsFundings 
                :orders="filteredFundings(fundings)"
                :sortFilters="fundingsSortBy"
                :resize="false" 
                :HPadding="false"
                :VPadding="false"
                @changeSortFilters="changeFundingsSortBy"
                v-if="fundings.length > 0"
            /> -->

            <OrdersFundings 
                :orders="filteredFundings(fundings)"
                :sortFilters="fundingsSortBy"
                @changeSortFilters="changeFundingsSortBy"
                :pageNumber="pageNumber"
                :pageLimit="pageLimit"
                :pageCount="fundingsPageCount"
                @decreasePageNumber="pageNumber--"
                @increasePageNumber="pageNumber++"
                @toFirstPageNumber="pageNumber = 1"
                @toLastPageNumber="pageNumber = fundingsPageCount"
                :resize="false" 
                :HPadding="false"
                :VPadding="false"
                v-if="fundings.length > 0"
            />
            <div class="no_data_available" v-else>
                <span class="no_data_available_p" v-if="selectedAccount">The selected account has no funding orders.</span>
                <span class="no_data_available_p" v-else>You have no funding orders.</span>
            </div>

        </div>

    </div>
</template>

<script>
    import DepositWithdraw from "@/components/blocks/deposit-withdraw-block.vue";
    // import ExecutionsFundings from "@/components/blocks/executions-fundings-block.vue";
    import OrdersFundings from "@/components/blocks/orders-fundings-block.vue";

    export default {
        components: {
            DepositWithdraw,
            // ExecutionsFundings
            OrdersFundings
        },

        data() {
            return {
                selectedAccount: null,
                searchPhrase: "",
                fundingsSortBy: {
                    column: "executed_at",
                    direction: "desc"
                },
                pageNumber: 1,
                pageLimit: 30,
                dates: {
                    from: null,
                    to: null
                }
            }
        },

        async beforeMount() {
            try {
                await this.$store.dispatch("marketsFetch");
                await this.$store.dispatch("accountsFetch");
                await this.$store.dispatch("accountsBalancesFetch");
                if (this.accounts.length == 1) {
                    this.selectedAccount = this.accounts[0];
                }
                else {
                    // await this.$store.dispatch("executionsFundingsFetch");
                    await this.$store.dispatch(
                        "ordersFundingsFetch",
                        {
                            pageNumber: this.pageNumber,
                            pageLimit: this.pageLimit
                        }
                    );
                }
            }
            catch (error) {
                console.error(error);
            }
        },

        async unmounted() {
            await this.$store.commit("ordersFundingsWSClose");
        },

        computed: {
            assets() {
                return this.$store.state.market.assets;
            },
            fiatAssets() {
                return this.$store.state.market.fiatAssets;
            },
            accounts() {
                return this.$store.state.accounts.availableAccounts;
            },
            // fundings() {
            //     return this.$store.state.executions.fundings;
            // },
            balances() {
                return this.$store.state.accounts.balances;
            },
            fundings() {
                return this.$store.state.orders.fundings;
            },
            fundingsPageCount() {
                return this.$store.state.orders.fundingsPageCount;
            },
            depositDetails() {
                return this.$store.state.orders.depositDetails;
            }
        },

        watch: {
            async selectedAccount(selectedAccount) {
                if (this.pageNumber != 1) this.pageNumber = 1;
                else await this.refreshFundings();

                if (selectedAccount) await this.$store.dispatch("ordersFundingsConnect", selectedAccount);
                else await this.$store.commit("ordersFundingsWSClose");
            },
            async pageNumber() {
                await this.refreshFundings();
            }
        },

        methods: {
            async refreshFundings() {
                try {
                    await this.$store.dispatch(
                        "ordersFundingsFetch",
                        {
                            account: this.selectedAccount,
                            pageNumber: this.pageNumber,
                            pageLimit: this.pageLimit
                        }
                    );
                }
                catch (error) {
                    console.error(error);
                }
            },

            changeFundingsSortBy(column) {
                if (this.fundingsSortBy.column != column) this.fundingsSortBy.column = column;
                else if (this.fundingsSortBy.direction == "asc") this.fundingsSortBy.direction = "desc";
                else if (this.fundingsSortBy.direction == "desc") this.fundingsSortBy.direction = "asc";
            },

            filteredFundings(fundings) {
                if (this.searchPhrase) {
                    fundings = fundings.filter(
                        funding => {
                            if (
                                (funding.id && funding.id.toLowerCase().includes(this.searchPhrase)) ||
                                (funding.account_id && funding.account_id.toLowerCase().includes(this.searchPhrase)) ||
                                (funding.external_memo_id && funding.external_memo_id.toLowerCase().includes(this.searchPhrase)) ||
                                (funding.order_status && funding.order_status.toLowerCase().includes(this.searchPhrase)) ||
                                (funding.order_funding_type && funding.order_funding_type.toLowerCase().includes(this.searchPhrase)) ||
                                (funding.created_at && funding.created_at.toLowerCase().includes(this.searchPhrase)) ||
                                //(funding.executed_at && funding.executed_at.toLowerCase().includes(this.searchPhrase)) ||
                                (funding.base_amount && funding.base_amount.toLowerCase().includes(this.searchPhrase)) ||
                                (funding.asset_short_name && funding.asset_short_name.toLowerCase().includes(this.searchPhrase))

                                // (funding.id && funding.id.toLowerCase().includes(this.searchPhrase)) ||
                                // (funding.order_id && funding.order_id.toLowerCase().includes(this.searchPhrase)) ||
                                // (funding.executed_at && funding.executed_at.toLowerCase().includes(this.searchPhrase)) ||
                                // (funding.created_by_system_member && funding.created_by_system_member.toLowerCase().includes(this.searchPhrase)) ||
                                // (funding.base_amount && funding.base_amount.toLowerCase().includes(this.searchPhrase)) ||
                                // (funding.metadata && funding.metadata.toLowerCase().includes(this.searchPhrase))

                                // (funding.id && funding.id.toLowerCase().includes(this.searchPhrase)) || 
                                // (funding.account_id && funding.account_id.toLowerCase().includes(this.searchPhrase)) || 
                                // (funding.external_memo_id && funding.external_memo_id.toLowerCase().includes(this.searchPhrase)) || 
                                // (funding.order_status && funding.order_status.toLowerCase().includes(this.searchPhrase)) || 
                                // (funding.order_funding_type && funding.order_funding_type.toLowerCase().includes(this.searchPhrase)) ||
                                // (funding.executed_at && funding.executed_at.toLowerCase().includes(this.searchPhrase)) || 
                                // (funding.base_amount && funding.base_amount.toLowerCase().includes(this.searchPhrase)) || 
                                // (funding.asset_short_name && funding.asset_short_name.toLowerCase().includes(this.searchPhrase))
                            ) {
                                return funding;
                            }
                        }
                    );
                }

                // if (this.selectedAccount) {
                //     fundings = fundings.filter(
                //         fundings => {
                //             return fundings.account_id == this.selectedAccount.id;
                //         }
                //     );
                // }

                if (this.dates.from) {
                    fundings = fundings.filter(
                        funding => {
                            if (new Date(funding.executed_at).toISOString().slice(0,10) >= this.dates.from) {
                                return funding;
                            }
                        }
                    );
                }
                if (this.dates.to) {
                    fundings = fundings.filter(
                        funding => {
                            if (new Date(funding.executed_at).toISOString().slice(0,10) <= this.dates.to) {
                                return funding;
                            }
                        }
                    );
                }
                
                // if (this.fundingsSortBy.column == "base_amount") {
                //     fundings = fundings.sort(
                //         (a, b) => {
                //             a = parseFloat(a.base_amount);
                //             b = parseFloat(b.base_amount);
                //             if (a < b) return -1;
                //             if (a > b) return 1;
                //             return 0;
                //         }
                //     );
                // }
                // else if (this.fundingsSortBy.column == "created_at") {
                //     fundings = fundings.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
                // }
                // else if (this.fundingsSortBy.column == "executed_at") {
                //     fundings = fundings.sort((a, b) => new Date(a.executed_at) - new Date(b.executed_at));
                // }

                // if (this.fundingsSortBy.direction == "desc") return fundings;
                // if (this.fundingsSortBy.direction == "asc") return fundings.reverse();

                return fundings;
            }
        }
    }
</script>
