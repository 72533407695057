import axios from "axios";

const connector = axios.create(
    {
        baseURL: process.env.VUE_APP_KEYCLOAK_URL
    }
);

connector.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem("access_token");

        config.headers["Content-Type"] = "application/x-www-form-urlencoded";
        config.headers["Authorization"] = token ? "Bearer " + token : null;

        return config;
    },
    (error) => {
        throw error;
    }
);
    
connector.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        throw error;
    }
);

export default connector;