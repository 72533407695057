<template>
    <div id="fees-subpage" class="subpage">

        <div class="subpage_header">
            <h2>Fees</h2>
        </div>

        <div class="filter-row">

            <div class="filter dropdown-selector" v-if="accounts.length > 0">
                <div class="name-holder">
                    <span class="name">Account:</span>
                </div>
                <select class="select" v-model="selectedAccount">
                    <option v-for="account in accounts" :key="account.id" :value="account">{{ account.description ? account.description + " (" + account.id + ")" : account.id }}</option>
                </select>
            </div>
            
        </div>

        <div class="row">
            <span>Platform fee:&nbsp;</span>
            <input type="number" max="99" v-model="platformFee">
            <span>% (+ 0.5% ADE FEE)</span>
            <input type="button" value="SAVE" v-if="initialPlatformFee != platformFee" @click="savePlatformFee">
        </div>

        <div class="row" v-if="selectedAccount">
            <span>Account fee:&nbsp;</span>
            <input type="number" max="99" v-model="accountFee">
            <span>% (+ 0.5% ADE FEE)</span>
            <input type="button" value="SAVE" v-if="initalAccountFee != accountFee" @click="saveAccountFee">
        </div>

    </div>
</template>

<script>

    export default {
        data() {
            return {
                platformFee: null,
                initialPlatformFee: null,

                accountFee: null,
                initalAccountFee: null,

                selectedAccount: null
            }
        },

        watch: {
            async selectedAccount() {
                await this.refreshFees();
            }
        },

        computed: {
            accounts() {
                return this.$store.state.accounts.availableAccounts;
            }
        },

        async beforeMount() {
            try {
                let fee = await this.$store.dispatch("feesPlatformFetch");
                fee = fee * 100;
                this.initialPlatformFee = fee;
                this.platformFee = fee;

                await this.$store.dispatch("accountsFetch");
                this.selectedAccount = this.accounts[0];
            }
            catch (error) {
                console.error(error);
            }
        },

        methods: {
            async refreshFees() {
                let fee = await this.$store.dispatch("feesAccountFetch", this.selectedAccount);
                fee = fee * 100;
                this.initalAccountFee = fee;
                this.accountFee = fee;
            },

            async savePlatformFee() {
                await this.$store.dispatch(
                    "feesPlatformSet",
                    this.platformFee / 100
                );
                this.initialPlatformFee = this.platformFee;
            },

            async saveAccountFee() {
                await this.$store.dispatch(
                    "feesAccountSet",
                    {
                        fee: this.accountFee / 100,
                        account: this.selectedAccount
                    }
                );
                this.initalAccountFee = this.accountFee;
            }
        }
    }
</script>

<style lang="scss" scoped>
    #fees-subpage {

        .row {
            display: flex;
            padding: 5px;
            align-items: baseline;

            input[type="number"] {
                width: 60px;
                // padding: 5px;
                text-align: right;
                font-weight: bold;
                background: none;
                border-radius: 0px;
                border-bottom: 1px solid var(--regular);
            }
        }
    }
</style>