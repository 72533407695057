<template>
    <Numerics :header="'Balance & Funding - ' +  findAsset(balance._asset_id)" class="balance-and-funding" :resize="false">
        <div class="inner">
            <div class="chart_holder">
                <Doughnut class="chart" :data="formatChartData(balance)" :options="doughNutOptions" />
            </div> 
            <div class="legend">
                <div class="segment">
                    <div>
                        <span class="important header">Balance</span>
                        <span class="important header value">{{ (Number(balance._available) + Number(balance._reserved)) + " " +  findAsset(balance._asset_id) }}</span>
                    </div>
                    <div>
                        <span class="special">Available</span>
                        <span class="special value">{{ balance._available + " " + findAsset(balance._asset_id) }}</span>
                    </div>
                    <hr>
                    <div>
                        <span class="pending">Reserved</span>
                        <span class="pending value">{{ balance._reserved + " " +  findAsset(balance._asset_id) }}</span>
                    </div>
                </div>
                <br/>
                <div class="segment" id="mojid">
                    <div>
                        <span class="important header">Funding - Pending</span>
                        <span class="important header value">{{ (Number(balance._pending_deposit) + Number(balance._pending_withdraw)) + " " +  findAsset(balance._asset_id) }}</span>
                    </div>
                    <div>
                        <span class="positive">Deposits</span>
                        <span class="positive" value>{{ balance._pending_deposit + " " +  findAsset(balance._asset_id) }}</span>
                    </div>
                    <hr>
                    <div>
                        <span class="negative">Withdrawals</span>
                        <span class="negative value">{{ balance._pending_withdraw + " " +  findAsset(balance._asset_id) }}</span>
                    </div>
                </div>
            </div>
        </div>
    </Numerics>
</template>

<script>
    import Numerics from "@/components/slots/numerics-slot.vue";
    import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
    import { Doughnut } from 'vue-chartjs';

    ChartJS.register(ArcElement, Tooltip, Legend)

    export default {
        props: {
            balance: {
                Type: Array
            }
        },

        data() {
            return {
                doughNutOptions: {
                    responsive: true,
                    plugins: { 
                        legend: { 
                            display: false 
                        },
                    },
                    elements: {
                        arc: {
                            borderWidth: 0
                        }
                    },
                    // maintainAspectRatio: false
                }
            }
        },

        components: {
            Numerics,
            Doughnut
        },

        methods: {
            findAsset(assetID) {
                let foundAsset = this.$store.state.market.assets.find(
                    asset => {
                        return asset.id == assetID;
                    }
                );

                if (foundAsset) return foundAsset.short_name;
            },

            formatChartData(data) {
                return {
                    labels: [
                        'Available', 
                        'Reserved', 
                        'Pending Deposits', 
                        'Pending Withdrawals'
                    ],
                    datasets: [
                        {
                            backgroundColor: [
                                '#9B7DF7', 
                                '#fae152', 
                                '#7BEEBE', 
                                '#F67570'
                            ],
                            data: [
                                data._available, 
                                data._reserved, 
                                data._pending_deposit, 
                                data._pending_withdraw
                            ]
                        }
                    ]
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .balance-and-funding {
        width: 550px;
        max-height: 280px;
        max-width: 550px;

        .inner {
            display: flex;
            flex-direction: row;
            align-content: center;

            .chart_holder {
                width: 40%;
                height: 100%;
                padding: 20px;

                .chart {
                    // margin: auto;
                    height: 100%;
                }
            }
    
            .legend {
                width: 60%;
    
                .segment {
                    height: 100%;
                    padding: 0px 24px;
    
                    div {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        padding: 6px 0px;
    
                        span {
                            font-weight: 500;

                            &.value {
                                padding-left: 10px;
                            }
    
                            &.header {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }

    }
</style>