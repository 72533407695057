import connector from "@/connectors/api";

export default {
    state: () => (
        {
            user: null
        }
    ),
    
    getters: {
        userRolesGet: (state) => (role) => {
            return state.user.acl_roles.find(
                acl_role => acl_role == "customer" || acl_role == role
            );
        }
    },

    actions: {
        async userFetch({ commit }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;
    
                let response = await connector.customer_member(params, payload, headers);
                await commit("userSet", response.data[0]);
                return response.data[0];
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to fetch user.");
                // await dispatch("authLogout");
            }
        }
    },

    mutations: {
        userSet(state, user) {
            state.user = user;
        }
    }
}