export default {
    state: () => (
        {
            active: false
        }
    ),

    mutations: {
        openLoadingOverlay(state) {
            state.active = true;
        },
        
        closeLoadingOverlay(state) {
            state.active = false;
        }
    }
}