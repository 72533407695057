<template>
    <Numerics :header="'ASK'" class="market_asks">
        <table cellspacing="0">
            <thead>
                <tr>
                    <th>Price</th>
                    <th>Amount</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in Object.keys(asks).sort((a, b) => {return parseFloat(a) - parseFloat(b)}).splice(0,40)" :key="item" :class="isOdd(index) ? 'even': ''" @click="$store.commit('prepareAskOrder', asks[item])">
                    <td class="negative">{{ parseFloat(asks[item].price).toFixed(2) }}</td>
                    <td class="important">{{ parseFloat(asks[item].amount).toFixed(8) }}</td>
                    <td>{{ (asks[item].price * asks[item].amount).toFixed(2) }}</td>
                </tr>
            </tbody>
        </table>
    </Numerics>
</template>

<script>
    import Numerics from "@/components/slots/numerics-slot.vue";

    export default {
        components: {
            Numerics
        },

        computed: {
            asks() {
                return this.$store.state.market.orders.asks;
            }
        },

        methods: {
            isOdd(num) {
                return num % 2 !== 0;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .market_asks {
        width: 340px;

        table {
            width: 100%;

            thead {
                width: 100%;
                
                th {
                    text-align: right;
                    font-size : 10px;
                }
            }

            tbody {
                width: 100%;

                tr {

                    &:hover {
                        background-color: #555555 !important;
                    }

                    &.even {
                        background-color: #2E2E2E;
                    }

                    td {
                        width: 33.33%;
                        text-align: right;
                        cursor: pointer;
                        font-size : 10px;
                    }
                }
            }
        }
    }
</style>