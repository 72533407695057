<template>
    <div id="overview-subpage" class="subpage">

        <div class="subpage_header">
            <h2>Overview</h2>
        </div>

        <div class="filter-row">

            <div class="filter dropdown-selector" v-if="market.markets.length > 0">
                <div class="name-holder">
                    <span class="name">Market:</span>
                </div>
                <select class="select" v-model="selectedMarket">
                    <option :value="null">All</option>
                    <option v-for="market in market.markets" :key="market.id" :value="market">{{ market.short_name }}</option>
                </select>
            </div>
        
            <div class="filter dropdown-selector" v-if="accounts.length > 0">
                <div class="name-holder">
                    <span class="name">Account:</span>
                </div>
                <select class="select" v-model="selectedAccount">
                    <option :value="null" v-if="accounts.length > 1">All</option>
                    <option v-for="account in accounts" :key="account.id" :value="account">{{ account.description ? account.description + " (" + account.id + ")" : account.id }}</option>
                </select>
            </div>
         
        </div>

        <div class="numerics_holder">
            <OpenOrders 
                :selectedAccount="selectedAccount"
                :orders="filterOrders(trades)"
                :resize="false" 
                :HPadding="false"
            />

            <!-- <OpenOrders 
                v-if="selectedAccount && selectedAccount.orders"
                :orders="filterOrders(selectedAccount.orders)"
                :resize="false" 
                :HPadding="false"
            /> -->
        </div>
        
        <br>
        <br>
       
        <div class="numerics_holder">

            <div class="barchartholder" v-if="selectedAccount && bar_chart_data_sets.length > 0">
                <BalancesBarChart 
                    v-for="(data, index) in bar_chart_data_sets" 
                    :key="index"
                    :options="data.options"
                    :chartData="data.data"
                />
                <br/>
            </div>

            <div v-if="selectedAccount && balances.length > 0">
                <BalancesDoughnut 
                    v-for="balance in filterBalancesByAccount(balances)"
                    :key="balance.id"
                    :balance="balance" 
                    :resize="false" 
                />
            </div>

        </div>

    </div>
</template>

<script>
    import BalancesDoughnut from "@/components/blocks/balances-doughnut-block.vue";
    import OpenOrders from "@/components/blocks/open-orders-block.vue";
    import BalancesBarChart from "@/components/blocks/balances-bar-chart.vue";

    export default {
        components: {
            BalancesDoughnut,
            OpenOrders,
            BalancesBarChart
        },

        data() {
            return {
                selectedMarket: null,
                selectedAccount: null,
                bar_chart_data_sets: [],
                pageNumber: 1,
                pageLimit: 1000
            }
        },

        async beforeMount() {
            try {
                await this.$store.dispatch("marketsFetch");
                await this.$store.dispatch("accountsFetch");
                if (this.accounts.length == 1) {
                    this.selectedAccount = this.accounts[0];
                }
                await this.$store.dispatch("accountsBalancesFetch");
                await this.refreshTradeOrders();
            }
            catch (error) {
                console.error(error);
            }
        },

        async unmounted() {
            await this.$store.commit("ordersTradesWSClose");
            await this.$store.commit("accountsBalancesWSClose");
        },

        computed: {
            market() {
                return this.$store.state.market;
            },
            accounts() {
                return this.$store.state.accounts.availableAccounts;
            },
            trades() {
                return this.$store.state.orders.trades;
            },
            tradesPageCount() {
                return this.$store.state.orders.tradesPageCount;
            },
            balances() {
                return this.$store.state.accounts.balances;
            }
        },

        watch: {
            async selectedAccount(selectedAccount) {
                this.pageNumber = 1;
                await this.refreshTradeOrders();
                await this.refreshBalances();
                if (selectedAccount) {
                    await this.$store.dispatch("ordersTradesConnect", selectedAccount);
                    await this.$store.dispatch("accountsBalancesConnect", selectedAccount);
                }
                else {
                    await this.$store.commit("ordersTradesWSClose");
                    await this.$store.commit("accountsBalancesWSClose");
                }
            },
            async pageNumber(newPageNumber, previousPageNumber) {
                if (newPageNumber != previousPageNumber) {
                    await this.refreshTradeOrders();
                }
            }
        },

        methods: {
            filterOrders(orders) {
                let filteredOrders = orders;
                if (this.selectedMarket) {
                    filteredOrders = filteredOrders.filter(
                        order => {
                            if (this.selectedMarket.id == order.market_id) { //MAY need change
                                return order;
                            }
                        }
                    );
                }
                filteredOrders = filteredOrders.sort(
                    (a,b) => {
                        return new Date(b.created_at) - new Date(a.created_at);
                    }
                );
                return filteredOrders;
            },

            filterBalancesByAccount(balances) {
                return balances.filter(
                    balance => {
                        // if (balance._available != 0 || balance._reserved != 0 || balance._pending_deposit != 0 || balance._pending_withdraw != 0) {
                            return this.selectedAccount.id == balance._account_id;
                        // }
                    }
                );
            },

            async refreshTradeOrders() {
                // if (this.$store.getters.userRolesGet('orders')) {
                    await this.$store.dispatch(
                        "ordersTradesFetch",
                        {
                            account: this.selectedAccount ? this.selectedAccount : null,
                            pageNumber: this.pageNumber,
                            pageLimit: this.pageLimit
                        }
                    );
                // }
            },

            async refreshBalances() {
                this.bar_chart_data_sets = [];

                if (this.selectedAccount) {
                    this.filterBalancesByAccount(this.balances).forEach(
                        balance => {
                            let data = {
                                asset: balance.short_name,
                                data: {
                                    labels: ["Total"],
                                    datasets : [
                                        { 
                                            label: "Available", 
                                            data: [
                                                balance._available
                                            ],
                                            backgroundColor: ["#9B7DF7"]  
                                        },
                                        { 
                                            label: "Reserved", 
                                            data: [
                                                balance._reserved
                                            ],
                                            backgroundColor: ["#fae152"]  
                                        },
                                        { 
                                            label: "Pending Deposit", 
                                            data: [
                                                balance._pending_deposit
                                            ],
                                            backgroundColor: ["#7BEEBE"]  
                                        },
                                        { 
                                            label: "Pending Withdrawal", 
                                            data: [
                                                balance._pending_withdraw
                                            ],
                                            backgroundColor: ["#F67570"]  
                                        }
                                    ] 
                                },
                                options : {
                                    plugins: {
                                        title: {
                                            display: true,
                                            text: balance.short_name + " Breakdown",
                                            font: {
                                                size: 20
                                            }
                                        },
                                        legend: {
                                            labels: {
                                                // This more specific font property overrides the global property
                                                font: {
                                                    size: 14
                                                }
                                            }
                                        }
                                    },
                                    responsive: true,
                                    maintainAspectRatio: true,
                                    scales: {
                                        x: {
                                            stacked: true,
                                            font: {
                                                size: 6
                                            }
                                        },
                                        y: {
                                            stacked: true,
                                            font: {
                                                size: 6
                                            }
                                        }
                                    }
                                }
                            }
                            this.bar_chart_data_sets.push(data);
                        }
                    );
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    #overview-subpage {
        
        .barchartholder {
            margin : 10px;
            display : flex;
            flex-wrap : wrap;
        }
        
        table {
            width: 100%;

            tr {
                border-radius: 5px;

                th {
                    width: 14.285714%;
                    height: 10px;
                    font-size: 10px;
                    font-weight: 100;
                    text-align: left;
                }

                td {
                    height: 10px;
                    font-size: 10px;
                    font-weight: 500;
                    text-align: left;
                }

                :first-child {
                    padding-left: 1px;
                }

                &.even {
                    background-color: #2E2E2E;
                }
            }
        }
    }
</style>
