import connector from "@/connectors/api";

export default {
    state: () => (
        {
            trades: [],
            tradesPageCount: 0,
            reallocations: [],
            reallocationsPageCount: 0,
            fundings: [],
            fundingsPageCount: 0
        }
    ),

    actions: {
        async executionsFetch({ dispatch }) {
            await dispatch("executionsTradesFetch");
            await dispatch("executionsReallocationsFetch");
            await dispatch("executionsFundingsFetch");
        },

        async executionsTradesFetch({ commit }, { account, pageNumber, pageLimit }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                params = {
                    page: pageNumber,
                    page_limit: pageLimit
                };
                if (account) params.account_id = account.id;
    
                let response = await connector.trade_execution(params, payload, headers);
                await commit("executionsTradesSet", response.data);
                return response.data.executions;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to fetch executed trades.");
            }
        },

        async executionsReallocationsFetch({ commit }, { account, pageNumber, pageLimit }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                params = {
                    page: pageNumber,
                    page_limit: pageLimit
                };
                if (account) params.account_id = account.id;

                let response = await connector.reallocate_execution(params, payload, headers);
                await commit("executionsReallocationsSet", response.data);
                return response.data.executions;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to fetch executed reallocations.");
            }
        },

        async executionsFundingsFetch({ commit }, { account, pageNumber, pageLimit }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                params = {
                    page: pageNumber,
                    page_limit: pageLimit
                };
                if (account) params.account_id = account.id;

                let response = await connector.funding_execution(params, payload, headers);
                await commit("executionsFundingsSet", response.data);
                return response.data.executions;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to fetch executed fundings.");
            }
        }
    },

    mutations: {
        executionsTradesSet(state, trades) {
            if (trades.executions) {
                trades.executions.map(
                    trade => {
                        let foundAsset = this.state.market.assets.find(
                            asset => {
                                return asset.id == trade.rebate_asset_id;
                            }
                        );
    
                        if (foundAsset) trade.asset_short_name = foundAsset.short_name;
                    }
                );
                state.trades = trades.executions;
            }
            if (trades.page_count) state.tradesPageCount = trades.page_count;
        },

        executionsReallocationsSet(state, reallocations) {
            if (reallocations.executions) {
                reallocations.executions.map(
                    reallocation => {
                        let foundAsset = this.state.market.assets.find(
                            asset => {
                                return asset.id == reallocation.asset_id;
                            }
                        );
                        if (foundAsset) reallocation.asset_short_name = foundAsset.short_name;
                    }
                );
                state.reallocations = reallocations.executions;
            }
            if (reallocations.page_count) state.reallocationsPageCount = reallocations.page_count;
        },

        executionsFundingsSet(state, fundings) {
            state.fundings = fundings.executions;
            state.fundingsPageCount = fundings.page_count;
        }
    }
}