<template>
  <LoadingOverlay v-if="loadingOverlayActive"/>
  <AdeLogger />
  <router-view />
</template>

<script>
  import LoadingOverlay from "@/components/popups/loading-overlay.vue";
  import AdeLogger from "@/components/popups/ade-logger.vue";

  export default {
    name: "App",

    components: {
      LoadingOverlay,
      AdeLogger
    },

    computed: {
      loadingOverlayActive() {
        return this.$store.state.loadingOverlay.active;
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import url("./style/main.scss");
</style>