<template>
    <div class="submenu_icon">
        <Transition name="submenu_icon_anim">
            <div class="submenu_icon_lineholder">
                <div class="submenu_icon_line first_line" :class="active ? 'active' : ''"></div>
                <div class="submenu_icon_line_spacer"></div>
                <div class="submenu_icon_line second_line" :class="active ? 'active' : ''"></div>
                <div class="submenu_icon_line_spacer"></div>
                <div class="submenu_icon_line third_line" :class="active ? 'active' : ''"></div>
            </div>
        </Transition>
    </div>
</template>

<script>
    export default {
        props: {
            active: {
                Type: Boolean,
                default: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .submenu_icon {
        height: 50px;
        width: 55px;
        padding: 10px;
        cursor: pointer;

        &:hover {
            background-color: #333333;
        }

        .submenu_icon_lineholder {
            width: 100%;
            height: 100%;

            .submenu_icon_line {
                width: 100%;
                height: 20%;
                // border-bottom-left-radius: 9999px;
                // border-bottom-right-radius: 9999px;
                // border-top-left-radius: 9999px;
                border-top-right-radius: 9999px;
                background-color: var(--regular);

                &.active {
                    background-color: #7240F5;
                }

                // &.first_line {
                //     width: calc(100% - 10px);
                // }

                // &.second_line {
                //     width: calc(100% - 5px);
                // }

                // &.third_line {
                //     width: calc(100% - 0px);
                // }
            }
    
            .submenu_icon_line_spacer {
                width: 100%;
                height: 20%;
            }
    
            .submenu_icon_anim-enter-active {
                animation: submenu_icon_anim-animation 0.15s forwards ease-out;
            }
    
            .submenu_icon_anim-leave-active {
                animation: submenu_icon_anim-animation 0.15s forwards ease-out reverse;
            }
    
            @keyframes submenu_icon_anim-animation {
                0% {
                    width: 5px;
                }
                100% {
                    width: 100%;
                }
            }
        }
    }
</style>