import connector from "@/connectors/api";

export default {
    state: () => (
        {
            trades: [],
            tradesPageCount: 0,
            cancelledTradeOrders: [],

            reallocations: [],
            reallocationsPageCount: 0,

            fundings: [],
            fundingsPageCount: 0,
            fundingsFilledPageCount: 0,
            
            depositDetails: null,

            tradesWS: {
                connection: null,
                reconnectionAttempts: 0
            },

            fundingsWS: {
                connection: null,
                reconnectionAttempts: 0
            }
        }
    ),

    actions: {
        async ordersTradesFetch({ commit }, { account, pageNumber, pageLimit }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                params = {
                    page: pageNumber,
                    page_limit: pageLimit
                }
                if (account) params.account_id = account.id;
    
                let response = await connector.trade_order(params, payload, headers);
                await commit("ordersTradesSet", response.data);
                return response.data;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to fetch trade orders.");
            }
        },

        async ordersTradesCreate({ commit }, order) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                payload = order;

                let response = await connector.trade_order_create(params, payload, headers);
                await commit("logsSuccessAdd", "Order placed.");
                return response;
            }
            catch (error) {
                console.error(error);
                if (error.response && error.response.data && error.response.data.payload == "Invalid order: insufficient funds") await commit("logsErrorAdd", "Insufficient funds.");
                else await commit("logsErrorAdd", "Failed to place order.");
            }
        },

        async ordersTradesConnect({ commit }, account) {
            if (this.state.orders.tradesWS.connection) {
                this.state.orders.tradesWS.connection.close();
            }

            this.state.orders.tradesWS.connection = new WebSocket(process.env.VUE_APP_PRIVATE_DATA_WS);

            this.state.orders.tradesWS.connection.addEventListener(
                "open", 
                async (event) => {
                    if (this.state.orders.tradesWS.reconnectionAttempts == 1) {
                        await commit("ordersTradesWSReconnectionAttemptsSet", 0);
                        if (process.env.NODE_ENV == "development") await commit("logsSuccessAdd", "Reconnection successful. Trade orders refreshed.");
                    }
                    if (process.env.NODE_ENV == "development") console.log("WebSocket connection opened:", event.currentTarget.url);
                }
            );

            this.state.orders.tradesWS.connection.addEventListener(
                "message", 
                (event) => {
                    if (event.data == "Please send your JWT token to authenticate") {
                        let message = {
                            account_id: account.id,
                            token: this.state.auth.auth.accessToken,
                            type: "orders"
                        };
    
                        this.state.orders.tradesWS.connection.send(JSON.stringify(message));
                    }
                    else {
                        try {
                            if (event.data) {
                                let data = JSON.parse(event.data);
                                commit("ordersTradesUpdate", data);
                            }
                        }
                        catch (error) {
                            console.error(error);
                        }
                    }
                }
            );

            this.state.orders.tradesWS.connection.addEventListener(
                "close", 
                async (event) => {
                    if (process.env.NODE_ENV == "development") console.warn("WebSocket connection closed:", event);
                    if (this.state.orders.tradesWS.reconnectionAttempts == 0 && event.code != 1005) {
                        await commit("ordersTradesWSReconnectionAttemptsSet", 1);
                        if (process.env.NODE_ENV == "development") await commit("logsErrorAdd", "Connection to trade orders failed. Refreshing your orders and reconnecting in 5 sec...");
                        setTimeout(
                            async () => {
                                await this.dispatch(
                                    "ordersTradesFetch", 
                                    {
                                        pageNumber: 1,
                                        pageLimit: 1000,
                                        account: account
                                    }
                                );
                                await this.dispatch("ordersTradesConnect", account);
                            },
                            5000
                        );
                    }
                }
            );

            this.state.orders.tradesWS.connection.addEventListener(
                "error", 
                (error) => {
                    if (process.env.NODE_ENV == "development") console.error("Message from server ", error);
                }
            );
        },

        async ordersTradesCancel({ dispatch, commit }, order) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                params = { 
                    order_id: order.id 
                };

                let response = await connector.cancel_trade_order(params, payload, headers);
                await commit("ordersTradesCancelSet", order);
                await commit("logsSuccessAdd", "Order was cancelled.");
                await dispatch("ordersTradesFetch");
                return response;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to cancel order.");
            }
        },

        async ordersReallocationsFetch({ commit }, { account, pageNumber, pageLimit }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                params = {
                    page: pageNumber,
                    page_limit: pageLimit
                }
                if (account) params.account_id = account.id;
    
                let response = await connector.reallocate_order(params, payload, headers);
                if (!account) await commit("ordersReallocationsSet", response.data);
                return response.data;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to fetch reallocation orders.");
            }
        },
        
        async ordersReallocationsCreate({ dispatch, commit }, order) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                payload = order;

                let response = await connector.reallocate_order_create(params, payload, headers);
                await commit("logsSuccessAdd", "Reallocation was successful.");
                await dispatch(
                    "ordersReallocationsFetch",
                    {
                        pageNumber: 1,
                        pageLimit: 1000
                    }
                );
                return response;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to reallocate funds.");
            }
        },

        async ordersFundingsFetch({ commit }, { account, pageNumber, pageLimit }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                params = {
                    page: pageNumber,
                    page_limit: pageLimit
                }
                if (account) params.account_id = account.id;
    
                let response = await connector.funding_order(params, payload, headers);
                await commit("ordersFundingsSet", response.data);
                return response.data.orders;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to fetch funding orders.");
            }
        },

        async ordersFundingsConnect({ commit }, account) {
            if (this.state.orders.fundingsWS.connection) {
                this.state.orders.fundingsWS.connection.close();
            }

            this.state.orders.fundingsWS.connection = new WebSocket(process.env.VUE_APP_PRIVATE_DATA_WS);

            this.state.orders.fundingsWS.connection.addEventListener(
                "open", 
                async (event) => {
                    if (this.state.orders.tradesWS.reconnectionAttempts == 1) {
                        await commit("ordersFundingsWSReconnectionAttemptsSet", 0);
                        if (process.env.NODE_ENV == "development") await commit("logsSuccessAdd", "Reconnection successful. Funding orders refreshed.");
                    }
                    if (process.env.NODE_ENV == "development") console.log("WebSocket connection opened:", event.currentTarget.url);
                }
            );

            this.state.orders.fundingsWS.connection.addEventListener(
                "message", 
                (event) => {
                    if (event.data == "Please send your JWT token to authenticate") {
                        let message = {
                            account_id: account.id,
                            token: this.state.auth.auth.accessToken,
                            type: "funding"
                        };
    
                        this.state.orders.fundingsWS.connection.send(JSON.stringify(message));
                    }
                    else {
                        try {
                            if (event.data) {
                                let data = JSON.parse(event.data);
                                commit("ordersFundingsUpdate", data);
                            }
                        }
                        catch (error) {
                            console.error(error);
                        }
                    }
                }
            );

            this.state.orders.fundingsWS.connection.addEventListener(
                "close", 
                async (event) => {
                    if (process.env.NODE_ENV == "development") console.warn("WebSocket connection closed:", event);
                    if (this.state.orders.fundingsWS.reconnectionAttempts == 0 && event.code != 1005) {
                        await commit("ordersFundingsWSReconnectionAttemptsSet", 1);
                        if (process.env.NODE_ENV == "development") await commit("logsErrorAdd", "Connection to funding orders failed. Reconnecting in 5 sec...");
                        setTimeout(
                            async () => {
                                await this.dispatch(
                                    "ordersFundingsFetch",
                                    {
                                        pageNumber: 1,
                                        pageLimit: 1000,
                                        account: account
                                    }
                                );
                                await this.dispatch("ordersFundingsConnect", account);
                            },
                            5000
                        );
                    }
                }
            );

            this.state.orders.fundingsWS.connection.addEventListener(
                "error", 
                (error) => {
                    if (process.env.NODE_ENV == "development") console.error("Message from server ", error);
                }
            );
        },

        async ordersFundingsDepositsDetialsFetch({ commit }, account) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                params = {
                    account_id: account.id
                };

                let response = await connector.funding_details(params, payload, headers);
                await commit("ordersFundingsDepositsDetialsSet", response.data);
                return response;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to depoist details funds.");
            }
        },

        async ordersFundingsWithdrawCreate({ commit }, order) {
            try {   
                let params = null;
                let payload = null;
                let headers = null;

                payload = order;

                let response = await connector.funding_order_withdraw_create(params, payload, headers);
                await commit("logsSuccessAdd", "Withdraw order placed successfully.");
                // await dispatch("ordersFundingsFetch");
                return response;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to place withdrawal request.");
            }
        }
    },

    mutations: {
        ordersTradesSet(state, trades) {
            if (trades.orders) {
                trades.orders.map(
                    trade => {
                        let isCancelled = false;
                        state.cancelledTradeOrders.forEach(
                            cancelledTrade => {
                                if (cancelledTrade == trade.id) {
                                    trade.cancelled = true;
                                }
                            }
                        );
    
                        if (isCancelled == true) {
                            return false;
                        }
                        else {
                            let market = this.state.market.markets.find(
                                market => {
                                    return market.id == trade.market_id;
                                }
                            );
    
                            if (market) trade.market_short_name = market.short_name;
    
                            return true;
                        }
                    }
                );
                state.trades = trades.orders;
            }
            if (trades.page_count) state.tradesPageCount = trades.page_count;
        },

        ordersTradesUpdate(state, data) {
            if (data.message_type == "order-created") {
                let newOrder = data.data;
                let market = this.state.market.markets.find(
                    market => {
                        return market.id == newOrder.market_id;
                    }
                );
                if (market) newOrder.market_short_name = market.short_name;

                state.trades.push(newOrder);
            }
            else if (data.message_type == "order-updated") {
                let orderUpdate = data.data.data;
                state.trades.map(
                    async (order) => {
                        if (order.id == orderUpdate.id) {

                            let editedOrder = order;

                            editedOrder.order_status = orderUpdate.order_status;

                            editedOrder.quote_amount = orderUpdate.quote_amount;
                            editedOrder.quote_amount_filled = orderUpdate.quote_amount_filled;
                            editedOrder.base_amount = orderUpdate.base_amount;
                            editedOrder.base_amount_filled = orderUpdate.base_amount_filled;
                            editedOrder.executed_at = orderUpdate.executed_at;

                            order = editedOrder;

                            if (order.order_status == "FILLED") {
                                await this.commit("logsSuccessAdd", "Order " + order.market_short_name + " | " + order.order_side + " | " + order.order_type + " - " + order.quote_amount + " was filled.");
                            }
                        }
                    }
                );
            }
            else if (data.message_type == "order-canceled") {
                let orderUpdate = data.data.data;
                state.trades.map(
                    async (order) => {
                        if (order.id == orderUpdate.id) {

                            let editedOrder = order;

                            editedOrder.order_status = orderUpdate.order_status;

                            editedOrder.quote_amount = orderUpdate.quote_amount;
                            editedOrder.quote_amount_filled = orderUpdate.quote_amount_filled;
                            editedOrder.base_amount = orderUpdate.base_amount;
                            editedOrder.base_amount_filled = orderUpdate.base_amount_filled;
                            editedOrder.executed_at = orderUpdate.executed_at;

                            order = editedOrder;

                            if (order.order_status == "CANCELLED") {
                                await this.commit("logsSuccessAdd", "Order " + order.market_short_name + " | " + order.order_side + " | " + order.order_type + " - " + order.quote_amount + " was cancelled.");
                            }
                        }
                    }
                );
            }
            // else if (data.message_type == "order-executed") {
            //     let orderUpdate = data.data.data;
            //     state.trades.map(
            //         async (order) => {
            //             if (order.id == orderUpdate.id) {
            //                 let editedOrder = order;

            //                 editedOrder.order_status = orderUpdate.order_status;

            //                 editedOrder.quote_amount = orderUpdate.quote_amount;
            //                 editedOrder.quote_amount_filled = orderUpdate.quote_amount_filled;
            //                 editedOrder.base_amount = orderUpdate.base_amount;
            //                 editedOrder.base_amount_filled = orderUpdate.base_amount_filled;
            //                 editedOrder.executed_at = orderUpdate.executed_at;

            //                 order = editedOrder;

            //                 if (order.order_status == "FILLED") {
            //                     await this.commit("logsSuccessAdd", "Order " + order.market_short_name + " | " + order.order_side + " | " + order.order_type + " - " + order.quote_amount + " was filled.");
            //                 }
            //             }
            //         }
            //     );
            // }
        },

        ordersTradesWSReconnectionAttemptsSet(state, attemptCount) {
            state.tradesWS.reconnectionAttempts = attemptCount;
        },

        ordersTradesWSClose(state) {
            if (state.tradesWS.connection) {
                state.tradesWS.connection.close();
                state.tradesWS.reconnectionAttempts = 0;
            }
        },

        ordersReallocationsSet(state, reallocations) {
            if (reallocations.orders) {
                state.reallocations = reallocations.orders;
            }
            if (reallocations.page_count) state.reallocationsPageCount = reallocations.page_count;
        },

        ordersFundingsSet(state, fundings) {
            if (fundings.orders) {
                fundings.orders.map(
                    funding => {
                        let foundAsset = this.state.market.assets.find(
                            asset => {
                                return asset.id == funding.asset_id;
                            }
                        );
    
                        if (foundAsset) funding.asset_short_name = foundAsset.short_name;
                    }
                );
                state.fundings = fundings.orders;
            }
            if (fundings.page_count) state.fundingsPageCount = fundings.page_count;
        },

        ordersFundingsUpdate(state, data) {
            if (data.message_type == "funding-created") {
                let newFunding = data.data.data;

                let foundAsset = this.state.market.assets.find(
                    asset => {
                        return asset.id == newFunding.asset_id;
                    }
                );

                if (foundAsset) newFunding.asset_short_name = foundAsset.short_name;

                state.fundings.push(newFunding);
            }
            else if (data.message_type == "funding-updated") {
                let fundingUpdate = data.data.data;
                state.fundings.map(
                    async (funding) => {
                        if (funding.id == fundingUpdate.id) {
                            let editedFunding = funding;

                            editedFunding.order_status = fundingUpdate.order_status;
                            editedFunding.executed_at = fundingUpdate.executed_at;
                            editedFunding.base_amount = fundingUpdate.base_amount;

                            funding = editedFunding;
                        }
                    }
                );
            }
            else if (data.message_type == "funding-executed" || data.message_type == "funding-exectued") {
                let fundingUpdate = data.data.data;
                state.fundings.map(
                    async (funding) => {
                        if (funding.id == fundingUpdate.id) {
                            let editedFunding = funding;

                            editedFunding.order_status = fundingUpdate.order_status;
                            editedFunding.executed_at = fundingUpdate.executed_at;
                            editedFunding.base_amount = fundingUpdate.base_amount;

                            funding = editedFunding;

                            if (funding.order_status == "FILLED") {
                                await this.commit("logsSuccessAdd", funding.order_funding_type + " for " + funding.base_amount + " " + funding.asset_short_name + " was filled.");
                            }
                        }
                    }
                );
            }
        },

        ordersFundingsWSReconnectionAttemptsSet(state, attemptCount) {
            state.fundingsWS.reconnectionAttempts = attemptCount;
        },

        ordersFundingsWSClose(state) {
            if (state.fundingsWS.connection) {
                state.fundingsWS.connection.close();
                state.fundingsWS.reconnectionAttempts = 0;
            }
        },

        ordersFundingsDepositsDetialsSet(state, depositDetails) {
            state.depositDetails = depositDetails;
        },

        ordersTradesCancelSet(state, order) {
            state.cancelledTradeOrders.push(order.id);
            state.trades.map(
                trade => {
                    if (order.id == trade.id) {
                        trade.cancelled = true;
                    }
                }
            );
        }
    }
}