<template>
    <Numerics class="executions_reallocations">
        <div class="transaction_table_holder">
            <table cellspacing="0">
                <thead>
                    <tr>
                        <th>#</th>
                        <!-- <th>Instrument</th> -->
                        <th>ID</th>
                        <th>Order ID</th>
                        <th class="selectable" @click="$emit('changeSortFilters', 'executed_at')">Executed At <span class="asc_desc_arrow" v-if="sortFilters.column == 'executed_at'">{{ sortFilters.direction == 'asc' ? '&#8638;' : '&#8642;' }}</span></th>
                        <th class="selectable" @click="$emit('changeSortFilters', 'account_id_from')">Sender Account <span class="asc_desc_arrow" v-if="sortFilters.column == 'account_id_from'">{{ sortFilters.direction == 'asc' ? '&#8638;' : '&#8642;' }}</span></th>
                        <th class="selectable" @click="$emit('changeSortFilters', 'account_id_to')">Receiver Account <span class="asc_desc_arrow" v-if="sortFilters.column == 'account_id_to'">{{ sortFilters.direction == 'asc' ? '&#8638;' : '&#8642;' }}</span></th>
                        <!-- <th>Execution Status</th> -->
                        <th class="selectable number" @click="$emit('changeSortFilters', 'base_amount')">Base Amount <span class="asc_desc_arrow" v-if="sortFilters.column == 'base_amount'">{{ sortFilters.direction == 'asc' ? '&#8638;' : '&#8642;' }}</span></th>
                        <th>Asset</th>
                        <!-- <th>Customer ID</th> -->
                        <!-- <th>Order ID</th> -->
                        <!-- <th>Created At</th> -->
                        <!-- <th>Account ID</th> -->
                        <!-- <th>Account ID To</th> -->
                        <!-- <th>System ID</th> -->
                        <!-- <th>Created By System Member</th> -->
                        <!-- <th>Created By Customer Member</th> -->
                        <!-- <th>Asset ID</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(execution, index) in orders" :key="index" :class="isOdd(index) ? 'even': ''" @click="selectedExecution = execution">
                        <td>{{ index + 1 + "." }}</td>
                        <!-- <td>{{ order.instrument }}</td> -->
                        <td>{{ execution.id }}</td>
                        <td>{{ execution.order_id }}</td>
                        <td>{{ execution.executed_at.slice(0,10) + " " + execution.executed_at.slice(11,19) }}</td>
                        <td>{{ execution.account_id_from }}</td>
                        <td>{{ execution.account_id_to }}</td>
                        <!-- <td :class="setStatusColor(execution.execution_status)">{{ execution.execution_status }}</td> -->
                        <td class="number">{{ execution.base_amount }}</td>
                        <td>{{ execution.asset_short_name }}</td>
                        <!-- <td>{{ execution.customer_id }}</td> -->
                        <!-- <td>{{ execution.order_id }}</td> -->
                        <!-- <td>{{ execution.created_at }}</td> -->
                        <!-- <td>{{ execution.account_id }}</td> -->
                        <!-- <td>{{ execution.account_id_to }}</td> -->
                        <!-- <td>{{ execution.system_id }}</td> -->
                        <!-- <td>{{ execution.created_by_system_member }}</td> -->
                        <!-- <td>{{ execution.created_by_customer_member }}</td> -->
                        <!-- <td>{{ execution.asset_id }}</td> -->
                    </tr>
                </tbody>
            </table>
        </div>
        <Transition name="item_explorer">
            <div class="item_explorer" v-if="selectedExecution">
                <div class="header">
                    <h3> {{ "Reallocation: " + selectedExecution.id }} </h3>
                </div>
                <div class="settings">
                    <div class="settings_content">
                        <div class="info_setting">
                            <span class="label">ID:</span>
                            <span class="info">{{ selectedExecution.id }}</span>
                        </div>
                        <div class="info_setting">
                            <span class="label">Order ID:</span>
                            <span class="info">{{ selectedExecution.order_id }}</span>
                        </div>
                        <div class="info_setting">
                            <span class="label">Date:</span>
                            <span class="info">{{ selectedExecution.executed_at.slice(0,10) }}</span>
                        </div>
                        <div class="info_setting">
                            <span class="label">Time:</span>
                            <span class="info">{{ selectedExecution.executed_at.slice(11,19) }}</span>
                        </div>
                        <div class="info_setting">
                            <span class="label">Sender Account:</span>
                            <span class="info">{{ selectedExecution.account_id_from }}</span>
                        </div>
                        <div class="info_setting">
                            <span class="label">Receiver Account:</span>
                            <span class="info">{{ selectedExecution.account_id_to }}</span>
                        </div>
                        <div class="info_setting">
                            <span class="label">Base Amount:</span>
                            <span class="info">{{ selectedExecution.base_amount + " " + selectedExecution.asset_short_name }}</span>
                        </div>
                    </div>
                </div>
                <div class="exit_options">
                    <input type="button" class="exit_option" value="Close" @click="closeExistingItemExplorer">
                </div>
            </div>
        </Transition>
    </Numerics>
</template>

<script>
    import Numerics from "@/components/slots/numerics-slot.vue";

    export default {
        props: {
            orders: {
                Type: Array
            },
            assets: {
                Type: Array
            },
            sortFilters: {
                Type: Object
            }
        },

        components: {
            Numerics
        },

        data() {
            return {
                selectedExecution: null
            }
        },

        methods: {
            closeExistingItemExplorer() {
                this.selectedExecution = null;
            },

            setSideColor(side) {
                if (side == "BUY") {
                    return "positive";
                }
                else if (side == "SELL") {
                    return "negative";
                }
            },

            setStatusColor(status) {
                // if (status == "Open") {
                //     return "positive";
                // }
                if (status == "FILLED") {
                    return "positive";
                }
                // else if (status == "Partially Filled") {
                //     return "pending";
                // }
                else if (status == "PENDING_SUBMISSION") {
                    return "pending";
                }
                else if (status == "REJECTED") {
                    return "negative";
                }
            },

            isOdd(num) {
                return num % 2 !== 0;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .executions_reallocations {
        width: 100%;
        // max-height: 800px;

        .transaction_table_holder {
            width: 100%;
            overflow: auto;

            table {
                width: 100%;
                overflow-x: auto;
    
                thead {
                    width: 100%;
    
                    tr {
                        position: sticky;
                        top: 0;
                        border-radius: 5px;
    
                        th {
                            // width: 14.285714%;
                            height: 10px;
                            font-size: 14px;
                            font-weight: 300;
                            text-align: left;

                            &.number {
                                text-align: right;
                            }

                            &.selectable {
                                &:hover {
                                    cursor: pointer;
                                    background-color: #444;
                                }
                            }
                        }
    
                        :first-child {
                            padding-left: 10px;
                        }
    
                        :last-child {
                            padding-right: 10px;
                        }
                    }
                }
    
                tbody {
                    width: 100%;
    
                    tr {
                        border-radius: 5px;
    
                        td {
                            height: 10px;
                            font-size: 12px;
                            font-weight: 500;
                            text-align: left;

                            &.number {
                                text-align: right;
                            }
    
                            input {
                                height: 30px;
                                padding: 4px 8px;
                                text-align: center;
                                font-size: 13px;
                            }
                        }
    
                        :first-child {
                            padding-left: 10px;
                        }
    
                        :last-child {
                            padding-right: 10px;
                        }
    
                        &.even {
                            background-color: #2E2E2E;
                        }
    
                        &:hover {
                            background-color: #444;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
</style>