<template>
    <div id="orders-subpage" class="subpage">
        
        <div class="subpage_header">
            <h2>Orders</h2>
        </div>

        <div class="filter-row">
            <input class="filter" type="search" placeholder="Search..." v-model="searchPhrase">
            <!-- <div class="filter time-span">
                <div class="name-holder">
                    <span class="name">Date Span:</span>
                </div>
                <input type="date" class="from-select" v-model="dates.from">
                <span class="to-span"> - </span>
                <input type="date" class="to-select" v-model="dates.to">
            </div> -->
            <div class="filter dropdown-selector">
                <div class="name-holder">
                    <span class="name">Market:</span>
                </div>
                <select class="select" v-model="selectedMarket">
                    <option :value="null">All</option>
                    <option v-for="market in market.markets" :key="market.id" :value="market">{{ market.short_name }}</option>
                </select>
            </div>
            <div class="filter dropdown-selector" v-if="accounts.length > 0">
                <div class="name-holder">
                    <span class="name">Account:</span>
                </div>
                <select class="select" v-model="selectedAccount">
                    <option :value="null" v-if="accounts.length > 1">All</option>
                    <option v-for="account in accounts" :key="account.id" :value="account">{{ account.description ? account.description + " (" + account.id + ")" : account.id }}</option>
                </select>
            </div>
        </div>

        <div class="numerics_holder"> 
            <OrdersTrades 
                :orders="filterOrders(trades)"
                :selectedAccount="selectedAccount"
                :pageNumber="pageNumber"
                :pageLimit="pageLimit"
                :pageCount="ordersPageCount"
                @decreasePageNumber="pageNumber--"
                @increasePageNumber="pageNumber++"
                @toFirstPageNumber="pageNumber = 1"
                @toLastPageNumber="pageNumber = ordersPageCount"
                :resize="false" 
                :HPadding="false"
                :VPadding="false"
            />
        </div>

    </div>
</template>

<script>
    import OrdersTrades from "@/components/blocks/orders-trades-block.vue";

    export default {
        components: {
            OrdersTrades
        },

        data() {
            return {
                selectedMarket: null,
                selectedAccount: null,
                searchPhrase: "",
                pageNumber: 1,
                pageLimit: 30,
                dates: {
                    from: null,
                    to: null
                }
            }
        },

        async beforeMount() {
            try {
                await this.$store.dispatch("marketsFetch");
                await this.$store.dispatch("accountsFetch");
                if (this.accounts.length == 1) {
                    this.selectedAccount = this.accounts[0];
                }
                await this.$store.dispatch(
                    "ordersTradesFetch",
                    {
                        pageNumber: this.pageNumber,
                        pageLimit: this.pageLimit
                    }
                );
            }
            catch (error) {
                console.error(error);
            }
        },

        computed: {
            market() {
                return this.$store.state.market;
            },
            accounts() {
                return this.$store.state.accounts.availableAccounts;
            },
            trades() {
                return this.$store.state.orders.trades;
            },
            ordersPageCount() {
                return this.$store.state.orders.tradesPageCount;
            }
        },

        watch: {
            async pageNumber() {
                await this.refreshTrades();
            }
        },

        methods: {
            async refreshTrades() {
                await this.$store.dispatch(
                    "ordersTradesFetch",
                    {
                        pageNumber: this.pageNumber,
                        pageLimit: this.pageLimit
                    }
                );
            },

            filterOrders(orders) {
                let filteredOrders = orders;
                if (this.searchPhrase) {
                    filteredOrders = filteredOrders.filter(
                        order => {
                            if (
                                order.id && order.id.toLowerCase().includes(this.searchPhrase) ||
                                // order.external_id && order.external_id.toLowerCase().includes(this.searchPhrase) ||
                                order.market_short_name && order.market_short_name.toLowerCase().includes(this.searchPhrase) ||
                                order.order_side && order.order_side.toLowerCase().includes(this.searchPhrase) ||
                                order.order_status && order.order_status.toLowerCase().includes(this.searchPhrase) ||
                                order.order_type && order.order_type.toLowerCase().includes(this.searchPhrase) ||
                                order.price && order.price.toLowerCase().includes(this.searchPhrase) ||
                                order.base_amount && order.base_amount.toLowerCase().includes(this.searchPhrase) ||
                                order.quote_amount && order.quote_amount.toLowerCase().includes(this.searchPhrase) ||
                                order.base_amount_filled && order.base_amount_filled.toLowerCase().includes(this.searchPhrase) ||
                                order.quote_amount_filled && order.quote_amount_filled.toLowerCase().includes(this.searchPhrase) ||
                                order.volume_weighted_average_price && order.volume_weighted_average_price.toLowerCase().includes(this.searchPhrase) ||
                                order.created_at && order.created_at.toLowerCase().includes(this.searchPhrase) ||
                                order.executed_at && order.executed_at.toLowerCase().includes(this.searchPhrase) ||
                                order.account_id && order.account_id.toLowerCase().includes(this.searchPhrase)
                            ) {
                                return order;
                            }
                        }
                    );
                }
                if (this.dates.from) {
                    filteredOrders = filteredOrders.filter(
                        order => {
                            if (new Date(order.created_at).toISOString().slice(0,10) >= this.dates.from) {
                                return order;
                            }
                        }
                    );
                }
                if (this.dates.to) {
                    filteredOrders = filteredOrders.filter(
                        order => {
                            if (new Date(order.created_at).toISOString().slice(0,10) <= this.dates.to) {
                                return order;
                            }
                        }
                    );
                }
                if (this.selectedMarket) {
                    filteredOrders = filteredOrders.filter(
                        order => {
                            if (this.selectedMarket.id == order.market_id) { //MAY need change
                                return order;
                            }
                        }
                    );
                }
                if (this.selectedAccount) {
                    filteredOrders = filteredOrders.filter(
                        order => {
                            if (this.selectedAccount.id == order.account_id) { //MAY need change
                                return order;
                            }
                        }
                    );
                }
                filteredOrders = filteredOrders.sort(
                    (a,b) => {
                        return new Date(b.created_at) - new Date(a.created_at);
                    }
                );
                return filteredOrders;
            }
        }
    }
</script>
