import {createRouter, createWebHistory} from "vue-router";
import Login from "@/pages/login/login-page.vue";
import Dashboard from "@/pages/dashboard/dashboard-page.vue";
import store from "@/store/index.js";

const routes = [
    {
        path: "/",
        name: "login",
        component: Login,
        beforeEnter: () => {
            if (!localStorage.getItem("access_token")) {
                return true;
            }
            else {
                return {
                    name: "dashboard"
                };
            }
        }
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        beforeEnter: async () => {
            try {
                if (localStorage.getItem("access_token")) {
                    store.commit("openLoadingOverlay");
                    await store.dispatch("fetchBasicData");
                    store.commit("closeLoadingOverlay");
                }
                else {
                    return {
                        name: "login"
                    };
                }
            }
            catch (error) {
                console.error(error);
                store.commit("closeLoadingOverlay");
            }
        }
    }
];

const router = createRouter(
    {
        history: createWebHistory(),
        routes
    }
);

export default router;