<template>
    <div class="chart-slot">
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
    .chart-slot {
        margin: 5px;
        border-radius: 5px;
        // max-width: calc(100% - 12px);
        width: calc(100% - 10px);
        // max-height: min-content;
        // max-height: 400%;
        background-color: #242424;
        // padding: 24px;
        float: left;
        flex-grow: 1;
    }
</style>