<template>
    <Numerics :header="'Last Trades'" class="market_trades">
        <table cellspacing="0">
            <tr>
                <th class="title">Price</th>
                <th class="title">Amount</th>
                <th class="title">Value</th>
            </tr>
            <tr v-for="(trade, index) in trades.slice().reverse()" :key="index" :class="isOdd(index) ? 'even': ''">
                <td :class="trade.growth">{{ parseFloat(trade.price).toFixed(2) }}</td>
                <td class="important">{{ parseFloat(trade.amount).toFixed(8) }}</td>
                <td>{{ (trade.price * trade.amount).toFixed(2) }}</td>
            </tr>
        </table>
    </Numerics>
</template>

<script>
    import Numerics from "@/components/slots/numerics-slot.vue";

    export default {
        components: {
            Numerics
        },

        computed: {
            trades() {
                return this.$store.state.market.trades;
            }
        },

        methods: {
            isOdd(num) {
                return num % 2 !== 0;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .market_trades {
        width: 200px;

        table {
            
            tr {
                // margin: 0px 10px;

                &:hover {
                    background-color: #555555 !important;
                }

                &.even {
                    background-color: #2E2E2E;
                }

                th {
                    text-align: right;
                    font-size : 10px;
                }

                td {
                    width: 33.33%;
                    text-align: right;
                    cursor: pointer;
                    font-size : 10px;
                }
            }
        }
    }
</style>