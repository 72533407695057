export default {
    state: () => (
        {
            display: true,
            logs: [],
            logHistory: []
        }
    ),

    mutations: {
        logsDisplayChange(state) {
            state.display = !state.display;
        },

        logsRegularAdd(state, log) {
            state.logs.push(
                {
                    type: "normal", 
                    message: log,
                    time: new Date(Date.now())
                }
            );
            state.logHistory.push(
                {
                    type: "normal",
                    message: log,
                    thime: new Date(Date.now())
                }
            );
        },

        logsPendingAdd(state, log) {
            state.logs.push(
                {
                    type: "pending", 
                    message: log,
                    time: new Date(Date.now())
                }
            );
            state.logHistory.push(
                {
                    type: "pending",
                    message: log,
                    thime: new Date(Date.now())
                }
            );
        },

        logsSuccessAdd(state, log) {
            state.logs.push(
                {
                    type: "success", 
                    message: log,
                    time: new Date(Date.now())
                }
            );
            state.logHistory.push(
                {
                    type: "success",
                    message: log,
                    thime: new Date(Date.now())
                }
            );
        },
        
        logsErrorAdd(state, log) {
            state.logs.push(
                {
                    type: "error", 
                    message: log,
                    time: new Date(Date.now())
                }
            );
            state.logHistory.push(
                {
                    type: "error",
                    message: log,
                    thime: new Date(Date.now())
                }
            );
        },

        logsRemove(state, index) {
            if (index > -1) {
                state.logs.splice(index, 1);
            }
        }
    }
}