<template>
    <div id="dashboard-page" class="page">
        <div class="dashboard_head">
            <SubmenuIcon :active="subpageMenu" @click="subpageMenu = !subpageMenu"/>
            <!-- <input class="logs_button" :class="logHistoryActive ? 'selected' : ''" type="button" value="LOGS" @click="activeLogsSwitch"> -->
            <input class="user_button" :class="activeHeadPanel == 'userPanel' ? 'selected' : ''" type="button" :value="(user.member_name ? user.member_name : '') + ' ' + (user.member_last_name ? user.member_last_name : '') + (user.member_name && user.member_last_name ? ' - ' : '') + customer.customer_name" @click="activeHeadPanel != 'userPanel' ? activeHeadPanel = 'userPanel' : activeHeadPanel = ''">
            <div class="user_panel" v-if="activeHeadPanel == 'userPanel'">
                <div class="info" v-if="user.member_type != 'default'">
                    <label class="name">First Name:</label>
                    <span class="value">{{ user.member_name }}</span>
                </div>
                <div class="info" v-if="user.member_type != 'default'">
                    <label class="name">Last Name:</label>
                    <span class="value">{{ user.member_last_name }}</span>
                </div>
                <div class="info" v-if="user.member_type != 'default'">
                    <label class="name">Email:</label>
                    <span class="value">{{ user.member_email }}</span>
                </div>
                <div class="info" v-if="user.member_type != 'default'">
                    <label class="name">Job:</label>
                    <span class="value">{{ user.member_job_title }}</span>
                </div>
                <div class="separator" v-if="user.member_type != 'default'"></div>
                <div class="info">
                    <label class="name">Company:</label>
                    <span class="value">{{ customer.customer_name }}</span>
                </div>
                <div class="separator"></div>
                <button class="logout" @click="openLogoutPopup()">
                    <span class="text">Log Out</span>
                    <img class="icon" src="@/assets/images/menu/log-out.svg" alt="">
                </button>
            </div>
        </div>
        <div class="dashboard_body">
            <Transition name="subpage-menu">
                <div class="subpage_menu_holder" v-if="subpageMenu">
                    <div class="subpage_menu">
                        <img src="@/assets/images/logos/adria-logo.svg" class="logo">
                        <div class="options">
        
                            <button class="icon_button" :class="setButtonClass('Overview')" @click="activeSubpage = 'Overview'" v-if="$store.getters.userRolesGet('overview')">
                                <img class="icon" src="@/assets/images/menu/overview.svg">
                                <span class="text">Overview</span>
                            </button>

                            <div class="options_seperator" v-if="$store.getters.userRolesGet('overview')"></div>
        
                            <button class="icon_button" :class="setButtonClass('Members')" @click="activeSubpage = 'Members'" v-if="$store.getters.userRolesGet('employees')">
                                <img class="icon" src="@/assets/images/menu/administration.svg" alt="">
                                <span class="text">Employees</span>
                            </button>
        
                            <button class="icon_button" :class="setButtonClass('Customers')" @click="activeSubpage = 'Customers'" v-if="$store.getters.userRolesGet('clients')">
                                <img class="icon" src="@/assets/images/menu/administration.svg" alt="">
                                <span class="text">Clients</span>
                            </button>

                            <div class="options_seperator" v-if="$store.getters.userRolesGet('employees') || $store.getters.userRolesGet('clients')"></div>
        
                            <button class="icon_button" :class="setButtonClass('Trading Station')" @click="activeSubpage = 'Trading Station'" v-if="$store.getters.userRolesGet('trading station')">
                                <img class="icon" src="@/assets/images/menu/trading-station.svg" alt="">
                                <span class="text">Trading Station</span>
                            </button>
        
                            <button class="icon_button" :class="setButtonClass('Fees')" @click="activeSubpage = 'Fees'" v-if="$store.getters.userRolesGet('fees')">
                                <img class="icon" src="@/assets/images/menu/trading-station.svg" alt="">
                                <span class="text">Fees</span>
                            </button>

                            <div class="options_seperator" v-if="$store.getters.userRolesGet('trading station') || $store.getters.userRolesGet('fees')"></div>
        
                            <button class="icon_button" :class="setButtonClass('Orders')" @click="activeSubpage = 'Orders'" v-if="$store.getters.userRolesGet('orders')">
                                <img class="icon" src="@/assets/images/menu/reporting.svg" alt="">
                                <span class="text">Orders</span>
                            </button>

                            <div class="options_seperator" v-if="$store.getters.userRolesGet('orders')"></div>
        
                            <button class="icon_button" :class="setButtonClass('Trades')" @click="activeSubpage = 'Trades'" v-if="$store.getters.userRolesGet('trades')">
                                <img class="icon" src="@/assets/images/menu/reporting.svg" alt="">
                                <span class="text">Trades</span>
                            </button>
        
                            <button class="icon_button" :class="setButtonClass('Fundings')" @click="activeSubpage = 'Fundings'" v-if="$store.getters.userRolesGet('fundings')">
                                <img class="icon" src="@/assets/images/menu/reporting.svg" alt="">
                                <span class="text">Fundings</span>
                            </button>
        
                            <button class="icon_button" :class="setButtonClass('Reallocations')" @click="activeSubpage = 'Reallocations'" v-if="$store.getters.userRolesGet('reallocations')">
                                <img class="icon" src="@/assets/images/menu/reporting.svg" alt="">
                                <span class="text">Reallocations</span>
                            </button>

                            <div class="options_seperator" v-if="$store.getters.userRolesGet('trades') || $store.getters.userRolesGet('fundings') || $store.getters.userRolesGet('reallocations')"></div>
        
                            <button class="icon_button" :class="setButtonClass('Notifications')" @click="activeSubpage = 'Notifications'">
                                <img class="icon" src="@/assets/images/menu/notifications.svg" alt="">
                                <span class="text">Notifications</span>
                            </button>
        
                            <p class="version_span">{{ "ver." + getVersion() }}</p>
                        </div>
                        <!-- <button class="logout" @click="openLogoutPopup()">
                            <span class="text">Log Out</span>
                            <img class="icon" src="@/assets/images/menu/log-out.svg" alt="">
                        </button> -->
                    </div>
                </div>
            </Transition>
            <div class="main_holder">
                <div class="general_info">
                    <div class="tickers" v-if="tickers">
                        <div class="ticker" v-for="(ticker, index) in tickers" :key="index" @click="selectMarket(ticker.market)">
                            <div class="currency">
                                <img class="icon" :class="ticker.crypto_name == 'BCH' ? 'BCH_icon' : ''" :src="setCurrencySymbol(ticker.crypto_name)" alt="">
                                <span class="amount">{{ parseFloat(ticker.price).toFixed(2) }} $</span>
                            </div>
                            <div class="stats">
                                <span class="abbrivation">{{ ticker.crypto_name }}</span>
                                <span class="rise_amount" :class="setTickerStyle(ticker.delta)">{{ parseFloat(ticker.delta).toFixed(2) }} $</span>
                                <span class="rise_procentage" :class="setTickerStyle(ticker.delta)">{{ parseFloat(ticker.delta_decimal).toFixed(3) }}%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="subpage_holder">
                    <OverviewSubpage v-if="activeSubpage == 'Overview'"/>
                    <MembersSubpage v-if="activeSubpage == 'Members'" />
                    <CustomersSubpage v-if="activeSubpage == 'Customers'" />
                    <TradingStationSubpage v-if="activeSubpage == 'Trading Station'" :preSelectedMarket="selectedMarket" />
                    <FeesSubpage v-if="activeSubpage == 'Fees'" />
                    <OrdersSubpage v-if="activeSubpage == 'Orders'" />
                    <TradesSubpage v-if="activeSubpage == 'Trades'" />
                    <FundingsSubpage v-if="activeSubpage == 'Fundings'" />
                    <ReallocationsSubpage v-if="activeSubpage == 'Reallocations'" />
                    <NotificationsSubpage v-if="activeSubpage == 'Notifications'" />
                </div>
                <Transition name="active-logs">
                    <div class="active-logs-holder" v-if="logHistoryActive">

                    </div>
                </Transition>
            </div>
            <!-- <Transition name="active-logs">
                <div class="active-logs-holder" v-if="logHistoryActive">

                </div>
            </Transition> -->
        </div>
    </div>
    <LogoutPopup 
        v-if="logoutPopup.active"
        :message="logoutPopup.message"
        @onConfirm="logoutPopup.onConfirm"
        @onCancel="logoutPopup.active = false"
    />
</template>

<script>
    import OverviewSubpage from "./subpages/overview-subpage.vue";
    import TradingStationSubpage from "./subpages/trading-station-subpage.vue";
    import FeesSubpage from "./subpages/fees-subpage.vue";

    import MembersSubpage from "./subpages/members-subpage.vue";
    import CustomersSubpage from "./subpages/customers-subpage.vue";

    import OrdersSubpage from "./subpages/orders-subpage.vue";
    import TradesSubpage from "./subpages/trades-subpage.vue";
    import FundingsSubpage from "./subpages/fundings-subpage.vue";
    import ReallocationsSubpage from "./subpages/reallocations-subpage.vue";

    import NotificationsSubpage from "./subpages/notifications-subpage.vue";
    import LogoutPopup from "@/components/popups/confirmation-popup.vue";

    import SubmenuIcon from "@/components/inputs/submenu-icon.vue";

    export default {

        components: {
            OverviewSubpage,
            MembersSubpage,
            CustomersSubpage,
            TradingStationSubpage,
            FeesSubpage,
            OrdersSubpage,
            TradesSubpage,
            FundingsSubpage,
            ReallocationsSubpage,
            NotificationsSubpage,
            LogoutPopup,
            SubmenuIcon
        },

        data() {
            return {
                activeSubpage: "",
                subpageMenu: true,
                logHistoryActive: false,
                activeHeadPanel: "",
                logoutPopup: {
                    active: false,
                    message: "",
                    onConfirm: null
                },
                selectedMarket: null
            }
        },

        computed: {
            user() {
                return this.$store.state.user.user;
            },
            customer() {
                return this.$store.state.customers.customer;
            },
            tickers() {
                return this.$store.state.market.tickers;
            }
        },

        mounted() {
            try {
                if (this.$store.getters.userRolesGet("overview")) {
                    this.activeSubpage = "Overview";
                }
                else if (this.$store.getters.userRolesGet("employees")) {
                    this.activeSubpage = "Members";
                }
                else if (this.$store.getters.userRolesGet("clients")) {
                    this.activeSubpage = "Customers";
                }
                else if (this.$store.getters.userRolesGet("trading station")) {
                    this.activeSubpage = "Trading Station";
                }
                else if (this.$store.getters.userRolesGet("fees")) {
                    this.activeSubpage = "Fees";
                }
                else if (this.$store.getters.userRolesGet("orders")) {
                    this.activeSubpage = "Orders";
                }
                else if (this.$store.getters.userRolesGet("trades")) {
                    this.activeSubpage = "Trades";
                }
                else if (this.$store.getters.userRolesGet("fundings")) {
                    this.activeSubpage = "Fundings";
                }
                else if (this.$store.getters.userRolesGet("reallocations")) {
                    this.activeSubpage = "Reallocations";
                }
                else {
                    this.activeSubpage = "Notifications";
                }
            }
            catch (error) {
                this.activeSubpage = "Notifications";
            }
        },

        methods: {
            getVersion() {
                return process.env.VUE_APP_VERSION;
            },
 
            async activeLogsSwitch() {
                await this.$store.commit("logsDisplayChange");
                this.logHistoryActive = !this.logHistoryActive;
            },

            setCurrencySymbol(currency) {
                try {
                    return require("@/assets/images/crypto/" + currency + ".svg");
                } 
                catch (error) {
                    return require("@/assets/images/crypto/BTC.svg");
                }
            },

            openLogoutPopup() {
                this.logoutPopup.active = true;
                this.logoutPopup.message = "Are you sure you want to logout?";
                this.logoutPopup.onConfirm = async () => {
                    try {
                        await this.$store.dispatch("authLogout");
                    }
                    catch (error) {
                        console.error(error);
                    }
                };
            },

            selectMarket(market) {
                if (this.$store.getters.userRolesGet("trading station")) {
                    this.selectedMarket = market;
                    this.activeSubpage = "Trading Station";
                }
            },

            setButtonClass(name) {
                if (name == this.activeSubpage) {
                    return "selected";
                }
            },

            setTickerStyle(delta) {
                if (delta.toString().slice(0,1) == "-") {
                    return "negative";
                }
                else {
                    return "positive";
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    #dashboard-page {
        width: 100%;
        height: 100%;
        overflow: hidden;

        .dashboard_head {
            // position: relative;
            display: flex;
            width: 100%;
            height: 50px;
            background-color: #2b2b2b;
            align-items: center;

            .menu_icon {
                // width: 36px;
                height: 100%;
                padding: 10px;
                cursor: pointer;
            }

            // .user_button {
            //     // height: 100%;
            //     margin-left: auto;
            //     padding: 0px 20px;
            //     color: var(--important);
            //     font-weight: 600;
            //     font-size: 14px;
            //     cursor: pointer;

            //     &:hover {
            //         background-color: #2b2b2b;
            //     }
            // }

            .logs_button {
                margin-left: auto;
                border-radius: 0;
                height: 100%;
                background: 0;

                &:hover {
                    color: var(--special);
                    background-color: #333333;
                }

                &.selected {
                    color: var(--special);
                    background-color: #333333;
                }
            }

            .user_button {
                margin-left: auto;
                border-radius: 0;
                height: 100%;
                background: 0;

                &:hover {
                    color: var(--special);
                    background-color: #333333;
                }

                &.selected {
                    color: var(--special);
                    background-color: #333333;
                }
            }

            .user_panel {
                z-index: 10001;
                position: absolute;
                right: 0px;
                top: 50px;
                width: 300px;
                padding: 10px 0px;
                background-color: #333333;

                .info {
                    display: flex;
                    margin: 10px 0px;
                    width: 100%;
                    // height: 30px;
                    padding: 0px 10px;

                    .name {
                        width: 30%;
                        font-weight: 700;
                    }

                    .value {
                        width: 70%;
                        text-align: right;
                    }
                }

                .separator {
                    margin: 10px 0px;
                    width: 100%;
                    height: 1px;
                    background-color: var(--regular);
                }

                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 30px;
                    border-radius: 0;
                    background: 0;

                    &:hover {
                        color: var(--special);
                        background-color: #444444;
                    }

                    &:focus {
                        color: var(--special);
                        background-color: #444444;
                    }

                    .text {
                        padding-right: 10px;
                    }

                    // .icon {

                    // }
                }
            }
        }

        .dashboard_body {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: calc(100% - 50px);
            flex-grow: 1;
            overflow: hidden;

            .subpage_menu_holder {
                position: relative;
                width: 235px;
                min-width: 235px;
                height: 100%;
    
                .subpage_menu {
                    position: relative;
                    // border-bottom-right-radius: 5px;
                    width: 100%;
                    height: 100%;
                    padding: 40px 24px;
                    background-color: #242424;
    
                    .logo {
                        display: block;
                        width: 100%;
                    }
                    
                    .options {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin-top: 50px;
                        border-radius: 5px;
                        width: 100%;

                        .icon_button {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            margin: 5px 0px;
                            padding: 12px;
                            text-align: left;
                            font-weight: 500;

                            .icon {
                                width: 20px;
                                height: 20px;
                            }

                            .text {
                                padding-left: 12px;
                                font-size: 14px;
                            }
                        }
                    }

                    .options_seperator {
                        display: block;
                        background-color: #363636;
                        width: 100%;
                        height: 1px;
                        margin: 5px 0px;
                    }
    
                    .logout {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        position: absolute;
                        right: 20px;
                        bottom: 20px;
                        cursor: pointer;
                        background: 0;
    
                        .text {
                            margin-right: 5px;
                            font-size: 12px;
                            font-weight: 200;
                        }
    
                        .icon {
                            width: 18px;
                            height: 18px;
                        }
    
                        &:hover {
                            background: 0;
                        }
    
                        &:focus {
                            background: 0;
                        }
                    }

                    .version_span {
                        position: absolute;
                        bottom: 24px;
                        left: 24px;
                        // left: 50%;
                        // transform: translateX(-50%);
                        font-weight: 500;
                        color: #696969;
                    }
                }
            }

            .subpage-menu-enter-active {
                animation: subpage-menu-animation 0.15s forwards ease-out;
            }

            .subpage-menu-leave-active {
                animation: subpage-menu-animation 0.15s forwards ease-out reverse;
            }

            @keyframes subpage-menu-animation {
                0% {
                    width: 0px;
                    min-width: 0px;
                }
                100% {
                    width: 235px;
                    min-width: 235px;
                }
            }
    
            .main_holder {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                height: 100%;
                // padding-top: 12px;
                // padding-left: 12px;
                // padding-right: 12px;
    
                .general_info {
                    display: flex;
                    width: 100%;
                    height: 96px;
                    min-height: 96px;
                    padding-left: 12px;
                    padding-right: 12px;
    
                    .tickers {
                        display: flex;
                        // flex-wrap: wrap;
                        flex-wrap: nowrap;
                        white-space: nowrap;
                        overflow-x: auto;
                        flex-grow: 1;
                        height: 100%;
                        padding: 15px 0px;
    
                        .ticker {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            margin: 0px 3px;
                            border: 1px solid #333;
                            border-radius: 5px;
                            // width: -webkit-fill-available;
                            min-width: 182px;
                            height: 100%;
                            padding: 10px;
                            cursor: pointer;
    
                            .currency {
                                display: flex;
                                width: 100%;
                                height: 20px;
                                overflow: hidden;
    
                                .amount {
                                    color: var(--important);
                                    flex-grow: 1;
                                    text-align: right;
                                    font-weight: 600;
                                    font-size: 16px;
                                }
                            }
    
                            .stats {
                                display: flex;
                                width: fit-content;
                                align-items: center;
                                width: 100%;
                                height: 20px;
                                font-size: 10px;
    
                                .abbrivation {
                                    width: 33.33%;
                                    // height: 100%;
                                    padding-right: 5px;
                                    font-size: 16px;
                                }
    
                                .rise_amount {
                                    width: 33.33%;
                                    // height: 100%;
                                    padding-right: 0px;
                                    text-align: right;
                                    font-size: 12px;
                                }
    
                                .rise_procentage {
                                    width: 33.33%;
                                    // height: 100%;
                                    padding-right: 0px;
                                    text-align: right;
                                    font-size: 12px;
                                }
                            }

                            &:hover {
                                background-color: #444;
                                cursor: pointer;
                            }
                        }
                    }
    
                    .notification_bell_holder {
                        min-width: 200px;
                        height: 100%;
                        
                        .notification_bell {
                            position: relative;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            cursor: pointer;
                            transform-origin: top center; /* Set the rotation axis at the top of the image */
    
                            &.jingling {
                                left: 40%;
                                top: 40%;
                                animation: bell-jingle 1s ease-out infinite; /* Use the 'rotateImage' animation for 4 seconds */
    
                                @keyframes bell-jingle {
                                    0% {
                                        transform: rotate(0deg);
                                    }
                                    25% {
                                        transform: rotate(50deg);
                                    }
                                    50% {
                                        transform: rotate(-30deg);
                                    }
                                    75% {
                                        transform: rotate(-10deg);
                                    }
                                    100% {
                                        transform: rotate(0deg);
                                    }
                                }
    
                            }
    
                        }
    
                    }
                }
    
                .subpage_holder {
                    // display: flex;
                    // flex-direction: column;
                    margin-top: 10px;
                    flex-grow: 1;
                    width: 100%;
                    padding-left: 12px;
                    padding-right: 12px;
                }

                .active-logs-holder {
                    border-top: 1px solid #fff;
                    width: 100%;
                    height: 200px;
                    min-height: 200px;
                    background-color: #161616;
                }

                .active-logs-enter-active {
                    animation: active-logs-animation 0.15s forwards ease-out;
                }

                .active-logs-leave-active {
                    animation: active-logs-animation 0.15s forwards ease-out reverse;
                }

                @keyframes active-logs-animation {
                    0% {
                        height: 0px;
                        min-height: 0px;
                    }
                    100% {
                        height: 400px;
                        min-height: 400px;
                    }
                }
            }

            // .active-logs-holder {
            //     border-left: 1px solid #fff;
            //     width: 500px;
            //     min-width: 500px;
            //     height: 100%;
            //     background-color: #161616;
            // }

            // .active-logs-enter-active {
            //     animation: active-logs-animation 0.15s forwards ease-out;
            // }

            // .active-logs-leave-active {
            //     animation: active-logs-animation 0.15s forwards ease-out reverse;
            // }

            // @keyframes active-logs-animation {
            //     0% {
            //         width: 0px;
            //         min-width: 0px;
            //     }
            //     100% {
            //         width: 500px;
            //         min-width: 500px;
            //     }
            // }
        }
    }
</style>