import connector from "@/connectors/api";

export default {
    state: () => (
        {
            customer: null,
            // customers: null
        }
    ),

    actions: {
        async customersFetch({ commit }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                let response = await connector.customer(params, payload, headers);
                // await commit("customersSet", response.data);
                await commit("customerSet", response.data[0]);
                return response;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to fetch customers.");
            }
        },

        // async customersCreate({ dispatch, commit }, customer) {
        //     try {
        //         let params = null;
        //         let payload = null;
        //         let headers = null;

        //         let response = await connector.customer_account_create(params, payload, headers);
        //         await commit("logsSuccessAdd", "Customer " + customer.customer_name + " was created.");
        //         dispatch("customersFetch");
        //         return response;
        //     }
        //     catch (error) {
        //         console.error(error);
        //         await commit("logsErrorAdd", "Failed to create customer.");
        //     }
        // },

        // async customersEdit({ dispatch, commit }, customer) {
        //     try {
        //         let params = null;
        //         let payload = null;
        //         let headers = null;

        //         params = customer.id;
        //         payload = {
        //             customer_name: customer.customer_name,
        //             customer_email: customer.customer_email,
        //             full_name: customer.full_name,
        //             // "accepted_policy": true,
        //             // "accepted_gdpr": true,
        //             // "accepted_tc": true,
        //             // "contracted": true,
        //             // "date_of_contract": customer.date_of_contract,
        //             // "is_active": true
        //         };

        //         let response = await connector.internal_patch_individual_customer(params, payload, headers);
        //         await commit("logsSuccessAdd", "Customer " + customer.customer_name + " was edited.");
        //         dispatch("customersFetch");
        //         return response;
        //     }
        //     catch (error) {
        //         console.error(error);
        //         await commit("logsErrorAdd", "Failed to edit customer.");
        //     }
        // },

        // async customersDelete({ dispatch, commit }, customer) {
        //     try {
        //         let params = null;
        //         let payload = null;
        //         let headers = null;

        //         params = customer.id;

        //         let response = await connector.customer_member_delete(params, payload, headers);
        //         await commit("logsSuccessAdd", "Customer " + customer.customer_name + " was deleted.");
        //         dispatch("customersFetch");
        //         return response;
        //     }
        //     catch (error) {
        //         console.error(error);
        //         await commit("logsErrorAdd", "Failed to delete customer.");
        //     }
        // }
    },

    mutations: {
        // customersSet(state, customers) {
        //     state.customers = customers;
        // },

        customerSet(state, customer) {
            state.customer = customer;
        }
    }
}