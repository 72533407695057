<template>
    <div id="trades-subpage" class="subpage">

        <div class="subpage_header">
            <h2>Trades</h2>
        </div>

        <div class="filter-row">
            <input class="filter" type="search" placeholder="Search..." v-model="searchPhrase">
            <!-- <div class="filter time-span">
                <div class="name-holder">
                    <span class="name">Date Span:</span>
                </div>
                <input type="date" class="from-select" v-model="dates.from">
                <span class="to-span"> - </span>
                <input type="date" class="to-select" v-model="dates.to">
            </div> -->
            <!--
            <div class="filter dropdown-selector">
                <div class="name-holder">
                    <span class="name">Sort by:</span>
                </div>
                <select class="select" v-model="tradesSortBy.column">
                    <option value="amount">Amount</option>
                    <option value="executed_at">Executed At</option>
                    <option value="gross_trade_amount">Gross Trade Amount</option>
                    <option value="price">Price</option>
                    <option value="asset">Asset</option>
                </select>
                <select class="select" v-model="tradesSortBy.direction">
                    <option value="desc">DESC</option>
                    <option value="asc">ASC</option>
                </select>
            </div>
            -->
            <div class="filter dropdown-selector" v-if="accounts.length > 0">
                <div class="name-holder">
                    <span class="name">Account:</span>
                </div>
                <select class="select" v-model="selectedAccount">
                    <option :value="null" v-if="accounts.length > 1">All</option>
                    <option v-for="account in accounts" :key="account.id" :value="account">{{ account.description ? account.description + " (" + account.id + ")" : account.id }}</option>
                </select>
            </div>
        </div>

        <div class="numerics_holder"> 
            <ExecutionsTrades 
                :trades="filteredTrades(trades)"
                :assets="assets"
                :sortFilters="tradesSortBy"
                @changeSortFilters="changeTradesSortBy"
                :pageNumber="pageNumber"
                :pageLimit="pageLimit"
                :pageCount="tradesPageCount"
                @decreasePageNumber="pageNumber--"
                @increasePageNumber="pageNumber++"
                @toFirstPageNumber="pageNumber = 1"
                @toLastPageNumber="pageNumber = tradesPageCount"
                :resize="false" 
                :HPadding="false"
                :VPadding="false"
                v-if="trades.length > 0 && assets.length > 0"
            />
            <div class="no_data_available" v-else>
                <span class="no_data_available_p" v-if="selectedAccount">The selected account has no executed trades.</span>
                <span class="no_data_available_p" v-else>You have no executed trades.</span>
            </div>
        </div>

    </div>
</template>

<script>
    import ExecutionsTrades from "@/components/blocks/executions-trades-block.vue";

    export default {
        components: {
            ExecutionsTrades
        },

        data() {
            return {
                selectedAccount: null,
                searchPhrase: "",
                tradesSortBy: {
                    column: "executed_at",
                    direction: "desc"
                },
                pageNumber: 1,
                pageLimit: 30,
                dates: {
                    from: null,
                    to: null
                }
            }
        },

        async beforeMount() {
            try {
                await this.$store.dispatch("marketsFetch");
                await this.$store.dispatch("accountsFetch");
                if (this.accounts.length == 1) {
                    this.selectedAccount = this.accounts[0];
                }
                else {
                    await this.$store.dispatch(
                        "executionsTradesFetch",
                        {
                            pageNumber: this.pageNumber,
                            pageLimit: this.pageLimit
                        }
                    );
                }
                await this.$store.dispatch("accountsBalancesFetch");
                this.selectedMarket = this.market.markets[0];
            }
            catch (error) {
                console.error(error);
            }
        },

        computed: {
            market() {
                return this.$store.state.market;
            },
            trades() {
                return this.$store.state.executions.trades;
            },
            tradesPageCount() {
                return this.$store.state.executions.tradesPageCount;
            },
            assets() {
                return this.$store.state.market.assets;
            },
            accounts() {
                return this.$store.state.accounts.availableAccounts;
            }
        },
        
        watch: {
            async selectedAccount() {
                if (this.pageNumber != 1) this.pageNumber = 1;
                else this.refreshTrades();
            },
            async pageNumber() {
                await this.refreshTrades();
            }
        },

        methods: {
            async refreshTrades() {
                try {
                    await this.$store.dispatch(
                        "executionsTradesFetch", 
                        {
                            account: this.selectedAccount,
                            pageNumber: this.pageNumber,
                            pageLimit: this.pageLimit
                        }
                    );
                }
                catch (error) {
                    console.error(error);
                }
            },

            changeTradesSortBy(column) {
                if (this.tradesSortBy.column != column) this.tradesSortBy.column = column;
                else if (this.tradesSortBy.direction == "asc") this.tradesSortBy.direction = "desc";
                else if (this.tradesSortBy.direction == "desc") this.tradesSortBy.direction = "asc";
            },

            filteredTrades(trades) {
                if (this.searchPhrase) {
                    trades = trades.filter(
                        trade => {
                            if (
                                (trade.id && trade.id.toLowerCase().includes(this.searchPhrase)) ||
                                (trade.order_id && trade.order_id.toLowerCase().includes(this.searchPhrase)) ||
                                (trade.executed_at && trade.executed_at.toLowerCase().includes(this.searchPhrase)) ||
                                (trade.short_name && trade.short_name.toLowerCase().includes(this.searchPhrase)) ||
                                (trade.amount && trade.amount.toLowerCase().includes(this.searchPhrase)) ||
                                (trade.gross_trade_amount && trade.gross_trade_amount.toLowerCase().includes(this.searchPhrase)) ||
                                (trade.price && trade.price.toLowerCase().includes(this.searchPhrase)) ||
                                (trade.asset_short_name && trade.asset_short_name.toLowerCase().includes(this.searchPhrase)) ||
                                (trade.external_id && trade.external_id.toLowerCase().includes(this.searchPhrase))
                            ) {
                                return trade;
                            }
                        }
                    );
                }
                if (this.dates.from) {
                    trades = trades.filter(
                        trade => {
                            if (new Date(trade.executed_at).toISOString().slice(0,10) >= this.dates.from) {
                                return trade;
                            }
                        }
                    );
                }
                if (this.dates.to) {
                    trades = trades.filter(
                        trade => {
                            if (new Date(trade.executed_at).toISOString().slice(0,10) <= this.dates.to) {
                                return trade;
                            }
                        }
                    );
                }

                if (this.tradesSortBy.column == "amount") {
                    trades = trades.sort(
                        (a, b) => {
                            // a = (a.base_amount || "").toUpperCase();
                            // b = (b.base_amount || "").toUpperCase();
                            a = parseFloat(a.base_amount);
                            b = parseFloat(b.base_amount);
                            if (a < b) return -1;
                            if (a > b) return 1;
                            return 0;
                        }
                    );
                }
                else if (this.tradesSortBy.column == "gross_trade_amount") {
                    trades = trades.sort(
                        (a, b) => {
                            a = parseFloat(a.gross_trade_amount);
                            b = parseFloat(b.gross_trade_amount);
                            if (a < b) return -1;
                            if (a > b) return 1;
                            return 0;
                        }
                    );
                }
                else if (this.tradesSortBy.column == "price") {
                    trades = trades.sort(
                        (a, b) => {
                            a = parseFloat(a.price);
                            b = parseFloat(b.price);
                            if (a < b) return -1;
                            if (a > b) return 1;
                            return 0;
                        }
                    );
                }
                else if (this.tradesSortBy.column == "asset") {
                    trades = trades.sort(
                        (a, b) => {
                            a = (a.rebate_asset_id || "").toUpperCase();
                            b = (b.rebate_asset_id || "").toUpperCase();
                            if (a < b) return -1;
                            if (a > b) return 1;
                            return 0;
                        }
                    );
                }
                else if (this.tradesSortBy.column == "executed_at") {
                    trades = trades.sort((a, b) => new Date(a.executed_at) - new Date(b.executed_at));
                }

                if (this.tradesSortBy.direction == "desc") return trades;
                else if (this.tradesSortBy.direction == "asc") return trades.reverse();
            }
        }
    }
</script>
