import connector from "@/connectors/api";

export default {
    state: () => (
        {
            accounts: [],
            availableAccounts: [],

            balances: [],

            balancesWS: {
                connection: null,
                reconnectionAttempts: 0
            }
        }
    ),

    actions: {
        async accountsFetch({ commit }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                let response = await connector.customer_account(params, payload, headers);
                await commit("accountsSet", response.data);
                // await dispatch("accountsBalancesFetch");
                return response.data;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to fetch accounts.");
            }
        },

        async accountsCreate({ commit, dispatch }, customer) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                payload = {
                    customer_id: customer.id
                }

                let response = await connector.customer_account_create(params, payload, headers);
                await dispatch("userFetch"); //Refresh allowed accounts in user so that the new account can be seen
                await dispatch("accountsFetch");
                return response;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to create account.");
            }
        },

        async accountsEdit({ commit, dispatch }, account) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                const descriptionRegex = /^[a-zA-Z0-9\\s.,\\-]+$/;
                const alphanumericRegex = /^[a-zA-Z0-9]+$/;

                if (!account.description) {
                    await commit("logsErrorAdd", "Please check the description.");
                    return;
                } 
                if (account.description && !account.description.match(descriptionRegex)) {
                    await commit("logsErrorAdd", "Please check the description.");
                    return;
                } 
                if (account.external_id && !account.external_id.match(alphanumericRegex)) {
                    await commit("logsErrorAdd", "Please check the External ID.");
                    return;
                } 

                params = {
                    account_id: account.id
                }

                payload = {
                    external_id : account.external_id,
                    description : account.description,
                    is_active : true
                }

                let response = await connector.customer_account_patch(params, payload, headers);
                await commit("logsSuccessAdd", "Account edited.");
                await dispatch("accountsFetch");
                return response;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to edit account.");
            }
        },

        async accountsDelete({ commit, dispatch }, account) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                params = {
                    account_id: account.id
                }

                let response = await connector.customer_account_delete(params, payload, headers);
                if (account.is_active == true) {
                    await commit("logsSuccessAdd", "Account disabled.");
                } 
                if (account.is_active == false) {
                    await commit("logsSuccessAdd", "Account enabled.");
                }
                await dispatch("accountsFetch");
                return response;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to delete account.");
            }
        },

        async accountsBalancesFetch({ commit }) {
            try {
                let params = null;
                let payload = null;
                let headers = null;

                let response =  await connector.customer_account_balance(params, payload, headers);
                await commit("accountsBalancesSet", response.data);

                return response.data;
            }
            catch (error) {
                console.error(error);
                await commit("logsErrorAdd", "Failed to fetch accounts balances.");
            }
        },

        async accountsBalancesConnect({ commit }, account) {
            if (this.state.accounts.balancesWS.connection) {
                this.state.accounts.balancesWS.connection.close();
            }

            this.state.accounts.balancesWS.connection = new WebSocket(process.env.VUE_APP_PRIVATE_DATA_WS);

            this.state.accounts.balancesWS.connection.addEventListener(
                "open", 
                async (event) => {
                    if (this.state.accounts.balancesWS.reconnectionAttempts == 1) {
                        await commit("accountsBalancesWSReconnectionAttemptsSet", 0);
                        if (process.env.NODE_ENV == "development") await commit("logsSuccessAdd", "Reconnection successful. Account balances refreshed.");
                    }
                    if (process.env.NODE_ENV == "development") console.log("WebSocket connection opened:", event.currentTarget.url);
                }
            );

            this.state.accounts.balancesWS.connection.addEventListener(
                "message", 
                (event) => {
                    if (event.data == "Please send your JWT token to authenticate") {
                        let message = {
                            account_id: account.id,
                            token: this.state.auth.auth.accessToken,
                            type: "balance"
                        };
    
                        this.state.accounts.balancesWS.connection.send(JSON.stringify(message));
                    }
                    else {
                        try {
                            if (event.data) {
                                let data = JSON.parse(event.data);
                                commit("accountsBalancesUpdate", data);
                            }
                        }
                        catch (error) {
                            console.error(error);
                        }
                    }
                }
            );

            this.state.accounts.balancesWS.connection.addEventListener(
                "close", 
                async (event) => {
                    console.warn("WebSocket connection closed:", event);
                    if (this.state.accounts.balancesWS.reconnectionAttempts == 0 && event.code != 1005) {
                        await commit("accountsBalancesWSReconnectionAttemptsSet", 1);
                        if (process.env.NODE_ENV == "development") await commit("logsErrorAdd", "Connection to account balances failed. Reconnecting in 5 sec...");
                        setTimeout(
                            async () => {
                                await this.dispatch(
                                    "accountsBalancesFetch",
                                    {
                                        pageNumber: 1,
                                        pageLimit: 1000,
                                        account: account
                                    }
                                );
                                await this.dispatch("accountsBalancesConnect", account);
                            },
                            5000
                        );
                    }
                }
            );

            this.state.accounts.balancesWS.connection.addEventListener(
                "error", 
                (error) => {
                    console.error("Message from server ", error);
                }
            );
        }
    },

    mutations: {
        async accountsSet(state, accounts) {
            state.accounts = accounts;

            let availableAccounts = accounts.filter(
                account => {
                    return account.is_active == true && account.account_type != "fee";
                }
            );

            state.availableAccounts = availableAccounts;
        },

        async accountsBalancesSet(state, balances) {
            state.balances = [];
            balances.forEach(
                balance => {
                    let matchingAsset = this.state.market.assets.find(
                        asset => asset.id == balance._asset_id
                    );

                    balance.short_name = matchingAsset.short_name;
                    balance.long_name = matchingAsset.long_name;
                    balance.description = matchingAsset.description;

                    state.balances.push(balance);
                }
            );
        },

        accountsBalancesUpdate(state, data) {
            if (data.message_type == "balance-update") {
                state.balances.map(
                    balance => {
                        if (balance._account_id == data.data.account_id) {
                            data.data.data.forEach(
                                updatedBalance => {
                                    if (balance._asset_id == updatedBalance.asset_id) {
                                        balance._reserved = updatedBalance.reserved;
                                        balance._available = updatedBalance.available;
                                        balance._pending_withdraw = updatedBalance.pending_withdraw;
                                        balance._pending_deposit = updatedBalance.pending_deposit;
                                    }
                                }
                            );
                        }
                    }
                );
            }
        },

        accountsBalancesWSReconnectionAttemptsSet(state, attemptCount) {
            state.balancesWS.reconnectionAttempts = attemptCount;
        },

        accountsBalancesWSClose(state) {
            if (state.balancesWS.connection) {
                state.balancesWS.connection.close();
                state.balancesWS.reconnectionAttempts = 0;
            }
        }
    }
}